<template>
  <v-container>
    <v-snackbar
      v-model="hasShowNotification"
      :timeout="5000"
      position="absolute"
      location="top"
      top
      width="100vh"
      min-width="100vh"
      height="65px"
      class="inples__snackbar">{{notificationContent}}</v-snackbar>
      <v-row
        justify="center"
        align="center">
        <v-col class="d-flex flex-column align-center">
          <v-card
            max-width="400"
            color="rgba(255, 255, 255, 0.5)"
            elevation="0"
            class="inples__card d-flex flex-column align-center">
            <v-card-title class="headline inples__card__title">
              <v-img
                src="../assets/logo.png"
                contain
                width="140"
                height="22"
                class="inples__logo" />
              <span class="text-center text-h6 font-weight-regular">Protecting creativity in the digital world</span>
            </v-card-title>
            <v-card-text class="px-0">
              <div>
                <div v-if="!file">
                  <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
                    <div class="dropZone-info" @drag="onChange">
                      <span class="fa fa-cloud-upload dropZone-title"></span>
                      <v-img
                        class="nuxt-img-tag"
                        src="../assets/dragndrop.svg"
                        contain
                        width="52"
                        height="52" />
                      <span class="text-center dropZone-title text-h6 font-weight-regular pt-6">Drop files here or click to select</span>
                    </div>
                    <input
                      multiple
                      type="file"
                      ref="inputRef"
                      @change="onChange" />
                  </div>
                </div>
                <div v-else class="dropZone-uploaded">
                  <div class="dropZone-uploaded-info">
                    <span class="dropZone-title">Загружено</span>
                    <button type="button" class="btn btn-primary removeFile" @click="removeFile">Удалить файлы</button>
                  </div>
                </div>
              </div>
            </v-card-text>
            <v-card-actions class="ma-0">
              <v-col>
                <v-btn
                  width="284"
                  height="60"
                  :color="isHovering ? '#4087ED' : '#171776'"
                  class="white--text inples__btn inples__btn__upload text-h6 font-weight-regular"
                  style="text-transform: unset !important;"
                  elevation="0"
                  :disabled="false"
                  @mouseover="setHover"
                  @mouseout="setHout"
                  @click.prevent="chooseFiles">Choose files</v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
          <v-row class="my-5">
            <v-spacer />
            <v-col
              class="ma-0"
              id="upload_captcha" />
            <v-spacer />
          </v-row>
        </v-col>
      </v-row>
    <v-dialog
      fullscreen
      v-model="isShowPopup"
      radius="14px"
      class="inples__dialog elevation-0">
      <input class="d-none"
        multiple
        type="file"
        ref="inputRef2"
        @change="onChange" />
      <v-card
        elevation="0"
        class="inples__dialog px-6 py-9">
        <v-card-text class="px-6 py-9">
          <v-row>
            <v-col v-if="isLoading"
              cols="12"
              md="6"
              lg="6">
              <v-row justify="center"
                class="text-center">
                <v-progress-circular size="128"
                  class="text-center my-15"
                  indeterminate />
                </v-row>
            </v-col>
            <v-col
              v-else
              class="d-flex flex-column justify-space-between">
              <div>
                <v-card
                  v-for="(file, idx) in files"
                  :key="idx"
                  color="rgba(255, 255, 255, 0.5)"
                  class="inples__card my-2"
                  elevation="0">
                  <v-card-title>
                    <v-row justify="end">
                        <v-icon
                          class="ma-1 clickable"
                          @click="removeSomeFile(idx)">mdi-close</v-icon>
                    </v-row>
                  </v-card-title>
                  <v-card-subtitle class="font-weight-black">{{file.name}} {{file.size | formatBytes}}</v-card-subtitle>
                  <v-card-text><span class="font-weight-black">SHA256: </span>{{file.hash}}</v-card-text>
                </v-card>
              </div>
              <v-btn
                width="284"
                height="60"
                color="#171776"
                class="white--text text-capitalize inples__btn my-5"
                variant="text"
                @click="chooseMore">Choose more</v-btn>
            </v-col>
            <v-spacer />
            <v-col>
              <v-card
                max-width="427"
                color="rgba(255, 255, 255, 0.5)"
                elevation="0"
                class="inples__card d-flex flex-column align-center ma-2 pa-0">
                <v-card-title class="headline inples__card__title">
                  <v-img src="logo.svg"
                    contain
                    width="140"
                    height="22"
                    class="inples__logo" />
                  <p class="d-block text-center">Your files will be uploaded<br/>to the server once you register</p>
                </v-card-title>
                <v-card-text class="px-0">
                  <v-form ref="form">
                    <v-row justify="center">
                      <v-col
                        cols="7"
                        sm="7"
                        md="8"
                        lg="8"
                        xl="8">
                        <v-text-field
                          background-color="#ffffffAA"
                          placeholder="Enter your email"
                          :rules="emailRules"
                          outlined
                          filled
                          rounded
                          class="inples__field"
                          v-model="email">
                          <template #default>
                            <div class="inples__field__slot"></div>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row
                      justify="center"
                      class="my-4">
                      <v-btn
                        color="#171776"
                        width="284"
                        height="60"
                        class="white--text inples__btn"
                        :disabled="isDisabled"
                        @click="register">Register</v-btn>
                    </v-row>
                    <v-row justify="center"
                      class="my-4">
                      <v-btn width="284"
                        height="60"
                        elevation="0"
                        class="inples__btn"
                        :disabled="isLoading"
                        @click="loginForService()">
                        <v-img src="../assets/google.svg"
                          width="22.88"
                          height="23.37"
                          contain
                          class="ma-1 clickable"
                          @click="removeSomeFile(idx)" />
                        <span class="ml-5">Continue with Google</span></v-btn>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import Vue from 'vue'
import axios from 'axios'
import { API_DOMAIN, INPLES_FILE_UUID_KEY, BYTES_IN_GB } from '../utils/consts'
import { mapState } from 'vuex'
Vue.filter('formatBytes', function(value) {
  if (value === 0) return '0 Bytes'
  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const i = Math.floor(Math.log(value) / Math.log(k))
  return parseFloat((value / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
})
let captcha = null
export default {
  data () {
    return {
      hasShowNotification: false,
      file: '',
      dragging: false,
      hash: '',
      files: [],
      moreFiles: [],
      notificationContent: '',
      isHovering: false,
      isShowPopup: false,
      email: '',
      emailRules: [
        v => !!v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) || 'Email is required'
      ],
      isLoading: false,
      mode: 'choose'
    }
  },
  computed: {
    ...mapState([
      'token',
    ]),
    isDisabled () {
      return this.isLoading || !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(this.email)
    }
  },
  mounted () {
    if (this.token) {
      this.$router.push({ name: 'home' })
    }
    this.deleteAllCookies()
    localStorage.removeItem(INPLES_FILE_UUID_KEY)
    this.renderReCaptcha()
  },
  methods: {
    deleteAllCookies() {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },
    isNone (val) {
      return val === null || val === undefined
    },
    renderReCaptcha () {
      const isWait = this.isNone(window.grecaptcha) || this.isNone(window.grecaptcha.render)
      console.log(`captcha is loaded: ${!isWait}`)
      if (isWait) {
        setTimeout(() => {
          this.renderReCaptcha()
        }, 500)
      } else {
        captcha = window.grecaptcha.render('upload_captcha', {
          'sitekey' : '6LfCu5gmAAAAAFXTcYebu6wpUX253tUgVxva57lj'
        })
      }
    },
    isCaptchaChecked () {
      return window.grecaptcha && window.grecaptcha.getResponse().length !== 0
    },
    oauth2SignIn () {
      var oauth2Endpoint = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${API_DOMAIN}&prompt=consent&response_type=code&client_id=782566320101-ip2nm1tctct6b9uusbcl89hvii64aq52.apps.googleusercontent.com&scope=openid%20email%20profile&access_type=offline`
      window.location = oauth2Endpoint
    },
    calculateSha2 (file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsArrayBuffer(file)
        fileReader.onload = () => {
          const data = fileReader.result
          window.crypto.subtle.digest('SHA-256', data)
            .then(buffer => {
              const array = Array.from(new Uint8Array(buffer))
              const hash = array.map(b => b.toString(16).padStart(2, '0')).join('')
              resolve(hash)
            })
            .catch(reject)
        }
        fileReader.onerror = reject
      })
    },
    async createFiles (files) {
      for (let i = 0; i < files.length; i++)
        await this.createFile(files[i])
    },
    loginForService () {
      this.oauth2SignIn()
    },
    removeSomeFile (idx) {
      this.files = this.files.filter((file, index) => index !== idx)
    },
    chooseFiles () {
      this.mode = 'choose'
      this.$refs.inputRef.click()
    },
    chooseMore () {
      this.mode = 'more'
      this.$refs.inputRef2.click()
    },
    async showPopup () {
      this.isShowPopup = true
    },
    setHover () {
      this.isHovering = true
    },
    setHout () {
      this.isHovering = false
    },
    register () {
      try {
        const recaptcha = window.grecaptcha.getResponse(captcha)
        const isFilesUploaded = this.files.length
        const isFormValid = this.$refs.form.validate()
        const isValid = isFormValid
        if (isValid) {
          const formData = new FormData()
          const uuid = localStorage.getItem(INPLES_FILE_UUID_KEY)
          formData.append('email', this.email)
          formData.append('uuid', uuid)
          formData.append('recaptcha', recaptcha)
          axios({
            method: 'post',
            url: `${API_DOMAIN}/api/users/`,
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            data: formData,
          })
            .then((response) => {
              // handle success
              console.log(response)
              this.$router.push({ path: '/thanks'})
            })
            .catch((error) => {
              // handle error
              const response = error.response
              const data = response.data
              const keys = Object.keys(data)
              const key = keys[0]
              const dataItem = data[key]
              const msg = dataItem[0]
              this.notificationContent = msg
              this.hasShowNotification = true
              const isRecaptchaError = key === 'recaptcha'
              if (isRecaptchaError) {
                this.isShowPopup = false
              }
            })
        } else {
          if (!isFilesUploaded) {
            this.notificationContent = 'You must upload the file(s)'
          }
          return
        }
      } catch (e) {
        this.notificationContent = 'Error'
        this.hasShowNotification = true
      }
    },
    async onChange (e) {
      this.notificationContent = ''
      this.hasShowNotification = !this.isCaptchaChecked()
      if (this.hasShowNotification) {
        this.notificationContent = 'You didn\'t complete the captcha'
        return
      }
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        this.dragging = false
        return
      }
      let totalSize = 0
      for (let i = 0; i < files.length; i++)
        totalSize += files[i].size
      this.notificationContent = ''
      this.hasShowNotification = totalSize > BYTES_IN_GB
      if (this.hasShowNotification) {
        this.notificationContent = 'You cannot upload files larger than 1 GB'
        this.dragging = false
        return
      }
      const isChoose = this.mode === 'choose'
      if (isChoose)
        this.files = []
      this.moreFiles = []
      await this.createFiles(files)
      if (!this.isShowPopup) {
        this.isShowPopup = true
      }
      const isFilesUploaded = await files.length
      const isValid = isFilesUploaded
      this.notificationContent = ''
      this.hasShowNotification = !isFilesUploaded
      if (this.hasShowNotification) {
        this.notificationContent = 'Files not uploaded'
      }
      if (isValid) {
        this.isLoading = true
        let index = -1
        var bodyFormData = new FormData()
        let userFiles = this.files
        const isMore = this.mode === 'more'
        if (isMore)
          userFiles = this.moreFiles
        for (const file of userFiles) {
          index++
          const hash = file.hash
          bodyFormData.append(`files[${index}]file`, file.data)
          bodyFormData.append(`files[${index}]hash`, hash)
        }
        if (index > -1) {
          try {
            const recaptcha = window.grecaptcha.getResponse(captcha)
            bodyFormData.append('recaptcha', recaptcha)
            axios({
              method: 'post',
              url: `${API_DOMAIN}/api/files/`,
              data: bodyFormData,
              headers: { 'Content-Type': 'multipart/form-data' }
            })
              .then((response) => {
                // handle success
                const { data } = response
                const uuid = data.file_token
                const rawTokens = localStorage.getItem(INPLES_FILE_UUID_KEY)
                let tokens = []
                if (rawTokens) {
                  tokens = JSON.parse(rawTokens)
                }
                tokens.push(uuid)
                localStorage.setItem(INPLES_FILE_UUID_KEY, JSON.stringify(tokens))
                this.isLoading = false
              })
              .catch((response) => {
                // handle error
                console.log(response)
              })
          } catch (e) {
            console.error(e)
          }
        }
      }
    },
    async createFile (file) {
      this.dragging = false
      const hash = await this.calculateSha2(file)
      const rawHash = (hash.toString())
      const fileData = {
        name: file.name,
        size: file.size,
        hash: rawHash,
        data: file
      }
      this.files.push(fileData)
      const isMore = this.mode === 'more'
      if (isMore)
        this.moreFiles.push(fileData)
    },
    removeFile () {
      this.file = ''
      this.files = []
    }
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap');
.dropZone {
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  margin-top: 47px;
}

.dropZone:hover .dropZone-title {
  color: #1975A0;
}

.dropZone-info {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 35px 0px;
}

.dropZone-title {
  color: #171776;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5C5C5C;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 80%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #A8A8A8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}

.inples {
  &__card {
    overflow: hidden;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 14px !important;
    &__actions {
      width: 100%;
    }
    &__title {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  &__btn {
    border-radius: 14px;
    &__upload {
      margin-bottom: 59px;
    }
  }
  &__logo {
    margin-top: 61px;
    margin-bottom: 22px;
  }
  &__dialog {
    background-image: url('../assets/background.png');
    background-size: cover;
    border-radius: 14px !important;
  }
  &__field {
    border-radius: 14px;
  }
  &__field__slot {
      background: blue;
  }
  &__popup {
    border-radius: 14px !important;
  }
}
.clickable {
  cursor: pointer;
}
.v-dialog.v-dialog--active {
  border-radius: 14px;
}
.inples__snackbar .v-snack__content {
  font-size: 24px;

}

</style>