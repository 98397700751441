<template>
    <v-row
      style="height: 100%;"
      class="h-100 d-flex justify-content-center align-content-center ma-5"
      justify="center"
      align="center">
      <v-snackbar
        v-model="hasShowNotification"
        :timeout="5000"
        position="absolute"
        location="top"
        top
        width="100vh"
        min-width="100vh"
        height="65px"
        class="inples__snackbar">{{notificationContent}}</v-snackbar>
      <v-card
          color="#FFFFFFAA"
          class="px-15 py-5 ma-5 col-12 col-md-3"
          style="border-radius: 14px;"
          elevation="0">
          <v-card-title class="flex-column">
              <v-row class="my-5">
                <v-img src="@/assets/logo.png"
                    contain
                    width="140px"
                    height="22" />
              </v-row>
              <span class="text-center">Your email is confirmed!<br />Please set up a password</span>
          </v-card-title>
          <v-card-text>
              <v-form ref="form">
                  <v-text-field
                    class="inples__field"
                    rounded
                    filled
                    background-color="#FFFFFF"
                    v-model="password"
                    placeholder="Create password"
                    :rules="passwordRules"
                    :type="show1 ? 'text' : 'password'"
                    :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                    @keydown.enter="register"
                    @click:append="show1 = !show1">
                    <template #append>
                      <v-img
                        class="clickable"
                        contain
                        :src="require(!show1 ? '@/assets/pass_eye_hidden.svg' : '@/assets/pass_eye.svg')"
                        @click="show1 = !show1" />
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-model="confirmPassword"
                    class="inples__field"
                    rounded
                    filled
                    background-color="#FFFFFF"
                    placeholder="Confirm password"
                    :rules="[
                      (v) => (!!v && /^........+$/.test(v)) || 'Password is required',
                      (v) => (v === password) || 'Passwords not match'
                    ]"
                    :type="show2 ? 'text' : 'password'"
                    @keydown.enter="register">
                    <template #append>
                      <v-img
                        class="clickable"
                        contain
                        :src="require(!show2 ? '@/assets/pass_eye_hidden.svg' : '@/assets/pass_eye.svg')"
                        @click="show2 = !show2" />
                    </template>
                  </v-text-field>
                  <v-row
                    justify="center"
                    align="center">
                    <v-col
                      cols="12">
                      <v-btn
                        height="60"
                        class="white--text inples__btn text-capitalize"
                        width="100%"
                        color="#171776AA"
                        rounded
                        :disabled="isDisableNext"
                        @click="register">Save</v-btn>
                    </v-col>
                  </v-row>
              </v-form>
          </v-card-text>
      </v-card>
    </v-row>
</template>

<script>
import Vue, { ref } from 'vue'
import axios from 'axios'
import { mapMutations, mapActions, } from 'vuex'
import { INPLES_AUTH_TOKENS_KEY, API_DOMAIN, INPLES_FILE_UUID_KEY } from '../utils/consts'

let form = null

export default Vue.extend({
  name: 'HomeView',
  setup () {
    const password = ref('')
    const passwordRules = ref([
      (v) => !!v && /^........+$/.test(v) || 'Password is required'
    ])
    const confirmPassword = ref('')
    const token = ref('')
    const hasShowNotification = ref(false)
    const notificationContent = ref('')
    const show1 = ref(false)
    const show2 = ref(false)
    return {
      password,
      passwordRules,
      confirmPassword,
      token,
      hasShowNotification,
      notificationContent,
      show1,
      show2,
    }
  },
  computed: {
    isDisableNext () {
      return false
    }
  },
  mounted () {
    form = this.$refs.form
    const query = this.$route.query
    const token = query.token
    this.token = token
    console.log(`token: ${token}`)
    const json = localStorage.getItem(INPLES_AUTH_TOKENS_KEY)
    if (json) {
      const tokens = JSON.parse(json)
      const accessToken = tokens.accessToken
      console.log(`accessToken check: ${accessToken}`)
      const data = {
        token: accessToken
      }
      axios({
        method: 'post',
        url: `${API_DOMAIN}/api/auth/token/verify/`,
        headers: {
          'Content-Type': 'application/json'
        },
        data
      })
        .then((response) => {
          this.$router.push({ name: 'home' })
        })
        .catch((response) => {
          console.log(`error: ${response}`)
        })
    }
  },
  methods: {
    ...mapMutations([
      'SET_TOKEN'
    ]),
    ...mapActions([
      'setToken'
    ]),
    register () {
      const isValid = form.validate()
      this.isFormValid = isValid
      if (!isValid) {
        return
      }
      const token = this.token
      let data = {
        token
      }
      axios({
        method: 'post',
        url: `${API_DOMAIN}/api/users/activate-account/`,
        headers: {
          'Content-Type': 'application/json'
        },
        data
      })
        .then((response) => {
          const responseData = response.data
          const accessToken = responseData.access
          const refreshToken = responseData.refresh
          const tokens = {
            accessToken,
            refreshToken
          }
          const json = JSON.stringify(tokens)
          localStorage.setItem(INPLES_AUTH_TOKENS_KEY, json)
          const fileTokens = localStorage.getItem(INPLES_FILE_UUID_KEY)
          const data = {
            file_tokens: JSON.parse(fileTokens),
          }
          axios({
            method: 'post',
            url: `${API_DOMAIN}/api/files/bind-files/`,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            data
          })
            .then((response) => {
              const data = {
                new_password1: this.password,
                new_password2: this.confirmPassword
              }
              axios({
                method: 'post',
                url: `${API_DOMAIN}/api/auth/password/change/`,
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                },
                data
              })
                .then(async (response) => {
                  await this.setToken({token: token})
                  this.$router.push({ name: 'home' })
                })
                .catch((error) => {
                  console.log(`error: ${error}`)
                  const response = error.response
                  const data = response.data
                  const keys = Object.keys(data)
                  const key = keys[0]
                  const dataItem = data[key]
                  let msg = dataItem[0]
                  if (!msg) {
                    msg = 'Error'
                  }
                  this.notificationContent = msg
                  this.hasShowNotification = true
                })
            })
            .catch((error) => {
              console.log(`error: ${response}`)
              const msg = 'Error'
              this.notificationContent = msg
              this.hasShowNotification = true
            })
        })
        .catch((response) => {
          console.log(`error: ${response}`)
          const msg = 'Error'
          this.notificationContent = msg
          this.hasShowNotification = true
        })
      // TODO send request
    },
    check () {
      const isValid = form.validate()
      this.isFormValid = isValid
      if (isValid) {
        return
      }
    }
  }
})
</script>
<style lang="scss" scoped>
  .inples {
    &__field {
      &__mobile {
        width: 100%;
      }
      &__desktop {
        width: 75%;
      }
    }
  }
  .clickable {
    cursor: pointer;
  }
</style>