<template>
  <v-card
    style="height: 100% !important;"
    elevation="0">
    <v-layout
      class="pa-15"
      :style="{ backgroundImage: `url(${require('@/assets/background.png')})`, backgroundSize: 'cover', height: '100%', '-webkit-filter': $vuetify.theme.dark ? 'saturate(0)' : 'none' }">
      <v-row justify="center">
        <v-col>
          <v-toolbar
            color="transparent"
            flat>
            <v-toolbar-title>
                <v-img
                    src="../assets/logo.svg"
                    contain
                    min-width="98"
                    min-height="15"
                    width="140"
                    height="22"
                    max-width="140"
                    max-height="22"  />
            </v-toolbar-title>
            <v-spacer />
            <v-btn
                border="1"
                elevation="0"
                width="139"
                height="48"
                outlined
                color="#171776"
                class="text-capitalize inples__btn my-5 inples__outlined-btn"
                variant="text"
                @click="signIn">
                <v-row justify="center" align="center">
                    <v-col>
                        <span class="inples__label">Sign in</span>
                    </v-col>
                    <v-col>
                        <v-icon color="#171776">mdi-login</v-icon>  
                    </v-col>
                </v-row>
              </v-btn>
          </v-toolbar>
          <v-container class="bg-surface-variant">
            <v-row justify="center">
              <v-col cols="10">
                <v-toolbar
                  color="transparent"
                  flat>
                  <v-toolbar-title class="font-weight-bold">
                    <v-row
                      justify="start"
                      class="clickable"
                      @click="back">
                      <v-col cols="1">
                        <v-icon style="background-color: rgba(255, 255, 255, 0.5); border-radius: 100%;" class="align-self-center">mdi-chevron-left</v-icon>
                      </v-col>
                      <v-col>
                        <p class="align-self-center ml-3 inples__label">Back</p>
                      </v-col>
                      <v-col>
                        <p class="ml-5">{{getTimestampTitle()}}</p>
                      </v-col>
                    </v-row>
                  </v-toolbar-title>
                </v-toolbar>
              </v-col>
            </v-row>
            <v-row
              justify="center"
              class="mb-6"
              no-gutters>
              <v-col
                cols="10"
                sm="10"
                md="10"
                lg="6"
                xl="5">
                <v-card
                  elevation="0"
                  color="#FFFFFF66"
                  class="ma-2 overflow-hidden"
                  :style="{ background: 'linear-gradient(#19A4FB33, #02C6A233)', backgroundSize: 'cover', backgroundPosition: '-35px', borderRadius: '14px', padding: '30px' }"
                  height="310">
                  <p class="inples__label text-uppercase">Work information</p>
                  <v-row class="ma-0">
                    <p>Title:</p>
                    <v-spacer />
                    <p class="font-weight-medium">{{getTimestampTitle()}}</p>
                  </v-row>
                  <v-row class="ma-0">
                    <p>Year of creation:</p>
                    <v-spacer></v-spacer>
                    <p class="font-weight-medium">2023</p>
                  </v-row>
                  <v-row class="ma-0">
                    <p>Type if work:</p>
                    <v-spacer />
                    <p class="font-weight-medium">{{getTimestampType()}}</p>
                  </v-row>
                  <v-row class="ma-0">
                    <p>Entry date:</p>
                    <v-spacer></v-spacer>
                    <p class="font-weight-medium">{{getTimestampDate(timestamp)}}</p>
                  </v-row>
                  <v-row class="ma-0">
                    <p>Identifier:</p>
                    <v-spacer></v-spacer>
                    <p class="font-weight-medium">{{getTimestampId()}}</p>
                  </v-row>
                  <v-row class="ma-0">
                    <p>Licence:</p>
                    <v-spacer></v-spacer>
                    <p class="font-weight-medium">{{getTimestampLicense()}}</p>
                  </v-row>
                </v-card>
              </v-col>
              <v-col
                cols="10"
                sm="10"
                md="10"
                lg="6"
                xl="5">
                <v-card
                  elevation="0"
                  color="#FFFFFF66"
                  class="ma-2 overflow-hidden"
                  :style="{ background: 'linear-gradient(#19A4FB33, #02C6A233)', backgroundSize: 'cover', backgroundPosition: '-35px', borderRadius: '14px', padding: '30px' }"
                  height="310">
                  <p class="inples__label text-uppercase">Registered declaration</p>
                  <v-row class="ma-0">
                    <p>Author(s):</p>
                    <v-spacer></v-spacer>
                    <p class="font-weight-medium">{{getTimestampName()}}</p>
                  </v-row>
                  <v-row class="ma-0">
                    <p>Name:</p>
                    <v-spacer></v-spacer>
                    <p class="font-weight-medium">{{getTimestampName()}}</p>
                  </v-row>
                  <v-row class="ma-0">
                    <p>Files Name:</p>
                    <v-spacer></v-spacer>
                    <p class="font-weight-medium">{{files.join(', ')}}</p>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row
              justify="center"
              no-gutters>
              <v-col
                cols="10"
                sm="10"
                md="10"
                lg="10"
                xl="10">
                <v-card
                  elevation="0"
                  color="#FFFFFF66"
                  :rounded="14"
                  :style="{ borderRadius: '14px', padding: '30px', background: 'linear-gradient(#19A4FB33, #02C6A233)' }"
                  class="ma-2 overflow-hidden"
                  height="329">
                  <v-row class="ma-0">
                    <p class="font-weight-bold">Hash</p>
                  </v-row>
                  <v-row class="ma-0">
                    <p class="">{{getTimestampHash()}}</p>
                  </v-row>
                  <v-row class="ma-0">
                    <p class="font-weight-bold">Transaction Hash</p>
                  </v-row>
                  <v-row class="ma-0">
                    <a :href="`https://etherscan.io/tx/${getTimestampTransactionHash()}`">{{getTimestampTransactionHash()}}</a>
                  </v-row>
                  <v-row class="ma-0">
                    <p class="font-weight-bold">Root Hash</p>
                  </v-row>
                  <v-row class="ma-0">
                    <p class="">{{getTimestampRootHash()}}</p>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-footer color="transparent">
              <v-col cols="3">
                  <p
                    class="inples__label clickable"
                    @click="openLink('https://www.inples.io/')">
                      What is Inples
                  </p>
                  <p class="inples__label clickable">
                      Reviews
                  </p>
                  <p class="inples__label clickable">
                      Media Centre
                  </p>
                  <p class="inples__label clickable">
                      General Questions
                  </p>
              </v-col>
              <v-col cols="3">
                  <p class="inples__label clickable">
                      Timestamp
                  </p>
                  <p class="inples__label clickable">
                      Checking the certificate
                  </p>
                  <p class="inples__label clickable">
                      Search for copyright
                  </p>
                  <p class="inples__label clickable">
                      infringement
                  </p>
              </v-col>
              <v-col cols="3">
                  <p class="inples__label clickable">
                      Terms & Conditions
                  </p>
                  <p class="inples__label clickable">
                      Privacy Policy
                  </p>
                  <p class="inples__label clickable">
                      Cookies Policy
                  </p>
                  <p class="inples__label clickable">
                      Legal evidence
                  </p>
              </v-col>
              <v-col cols="3">
                  <p class="inples__label clickable">
                      Contacts
                  </p>
                  <p class="inples__label clickable">
                      Twitter
                  </p>
                  <p class="inples__label clickable">
                      Instagram
                  </p>
                  <p
                    class="inples__label clickable"
                    @click="openLink('https://www.linkedin.com/company/inples/')">
                      LinkedIn
                  </p>
              </v-col>
              <p class="black--text">
                  © {{getCurrentYear}} Inples Co., ltd. All Rights Reserved.
              </p>
          </v-footer>
        </v-col>
      </v-row>
    </v-layout>
  </v-card>
</template>

<script>
  import Vue, { ref } from 'vue'
  import { mapState } from 'vuex'
  import { API_DOMAIN } from '../utils/consts'
  import axios from 'axios'
  import moment from 'moment'
  
  export default Vue.extend({
    name: 'SettingsPageView',
    setup () {
      const selectedItem = ref('Timestamp')
      const rail = ref(false)
      const files = ref([])
      const dialog = ref(false)
      const project = ref('')
      const focuses = ref([])
      const isHovering = ref([])
      const deleteTimestampStatus = ref(null)
      const certUrl = ref('https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf')
      const isShowDetails = ref([])
      const uploadedAt = ref(false)
      const fileTokens = ref([])
      const timestamp = ref({})
      const examines = ref([
        {
          hash: null
        }
      ])
      const uploadedExamine = ref(-1)
      return {
        selectedItem,
        rail,
        files,
        dialog,
        project,
        focuses,
        isHovering,
        deleteTimestampStatus,
        certUrl,
        isShowDetails,
        uploadedAt,
        fileTokens,
        timestamp,
        examines,
        uploadedExamine,
      }
    },
    computed: {
      ...mapState([
        'token'
      ]),
      getCurrentYear () {
        return moment().format('YYYY')
      },
    },
    mounted () {
      if (!this.$store.state.timestamp) {
        this.$router.push({ name: 'verify' })
      }
      axios({
        url: `${API_DOMAIN}/api/stamps/${this.$route.query.id}/`,
        headers: {
          'Authorization': `Bearer ${this.$store.state.token}`
        }
      })
        .then((response) => {
          console.log(response)
          this.timestamp = response.data
          const rid = this.$store.state.timestamp.rid
          if (rid) {
            axios({
              url: `${API_DOMAIN}/api/files/`,
              headers: {
                'Authorization': `Bearer ${this.$store.state.token}`
              }
            })
              .then((response) => {
                console.log(response)
                let fileToken = ''
                const rid = this.$store.state.timestamp.rid
                if (rid) {
                  fileToken = rid.file_token
                }
                for (let i of response.data) {
                  const itemFileToken = i.file_token
                  const result = itemFileToken === fileToken
                  if (result) {
                    this.files = i.filenames
                    this.isShowDetails = this.files.map((details, idx) => false)
                    this.uploadedAt = i.uploaded_at
                    this.fileTokens = [
                      i.file_token
                    ]
                    this.examines = this.files.map((details, idx) => ({
                      hash: null,
                      progress: 0,
                      name: '',
                    }))
                    break
                  }
                }
                const certificate = this.timestamp.certificate
                if (certificate) {
                  this.certUrl = certificate
                }
              })
              .catch((response) => {
                console.log(response)
              })
          }
        })
        .catch((response) => {
          console.log(response)
        })
      const query = this.$route.query
      const action = query.action
      if (action) {
        this.select('Delete')
      }
    },
    methods: {
      openLink (url) {
        window.open(url)
      },
      signIn () {
        window.location = 'https://app.inples.io/'
      },
      getFileDate () {
        let date = ''
        const uploadedAt = this.uploadedAt
        if (uploadedAt) {
          date = `${moment(uploadedAt).format('DD.MM.YYYY hh:mm a')} UTM`
        }
        return date
      },
      toggleFile (index) {
        this.isShowDetails[index] = !this.isShowDetails[index]
        this.$forceUpdate()
      },
      getTimestampCert () {
        let cert = this.certUrl
        const certificate = this.$store.state.timestamp.certificate
        if (certificate) {
          cert = certificate
        }
        return cert
      },
      getTimestampId () {
        let id = ''
        const verifyCode = this.$store.state.timestamp.verify_code
        if (verifyCode) {
          id = verifyCode
        }
        return id
      },
      getTimestampTransactionHash () {
        let hash = '-'
        const details = this.$store.state.timestamp.details
        if (details) {
          const data = details
          if (data) {
            const timestamps = data.timestamps
            if (timestamps) {
              const transactionTimestamps = timestamps.filter((ts) => {
                const currencyId = ts.currency_id
                return currencyId === 1
              })
              if (transactionTimestamps.length) {
                const transactionTimestamp = transactionTimestamps[0]
                const transactionHash = transactionTimestamp.transaction
                if (transactionHash) {
                  hash = transactionHash
                }
              }
            }
          }
        }
        return hash
      },
      getTimestampRootHash () {
        let hash = '-'
        const details = this.$store.state.timestamp.details
        if (details) {
          const data = details
          if (data) {
            const timestamps = data.timestamps
            if (timestamps) {
              const transactionTimestamps = timestamps.filter((ts) => {
                const currencyId = ts.currency_id
                return currencyId === 1
              })
              if (transactionTimestamps.length) {
                const transactionTimestamp = transactionTimestamps[0]
                const rootHash = transactionTimestamp.private_key
                if (rootHash) {
                  hash = rootHash
                }
              }
            }
          }
        }
        return hash
      },
      getTimestampLicense () {
        let license = '-'
        const rid = this.$store.state.timestamp.rid
        if (rid) {
          license = rid.license
        }
        return license
      },
      getTimestampTransaction () {
        let hash = '-'
        const details = this.$store.state.timestamp.details
        if (details) {
          const data = details
          if (data) {
            hash = data.hash_string
          }
        }
        return hash
      },
      getTimestampHash () {
        let hash = '-'
        const details = this.$store.state.timestamp.details
        if (details) {
          const data = details
          if (data) {
            hash = data.hash_string
          }
        }
        return hash
      },
      getTimestampType () {
        let type = ''
        const rid = this.$store.state.timestamp.rid
        if (rid) {
          type = rid.type
        }
        return type
      },
      getTimestampTitle () {
        let title = ''
        const rid = this.$store.state.timestamp.rid
        if (rid) {
          title = rid.title
        }
        return title
      },
      getTimestampName () {
        let name = ''
        const rid = this.$store.state.timestamp.rid
        if (rid) {
          name = rid.name
        }
        return name
      },
      getTimestampDate () {
        let date = ''
        const rid = this.$store.state.timestamp.rid
        if (rid) {
          const data = rid.created_at
          if (data) {
            date = `${moment(data).format('DD.MM.YYYY hh:mm a')} UTM`
          }
        }
        return date
      },
      hideDrawer () {
        if ((this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm')) {
          this.rail = true
        }
      },
      isSelected (item) {
        const result = item === this.selectedItem
        return result
      },
      select (val) {
        this.selectedItem = val
      },
      back () {
        this.$router.push({name: 'verify'})
      },
      setFocus(i, val) {
        this.focuses[i] = val
        this.$forceUpdate()
      },
      setHover (i) {
        this.isHovering[i] = true
        this.$forceUpdate()
      },
      setHout (i) {
        this.isHovering[i] = false
        this.$forceUpdate()
      },
    },
  })
</script>

<style scoped>
  .inples__label {
    color: #171776;
  }
  .inples__settings__selected-item {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 14px;
  }
  .inples__settings__payment-method {
    border-radius: 14px;
  }
  .clickable {
    cursor: pointer;
  }
</style>