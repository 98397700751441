<template>
  <v-row
    style="height: 100%;"
    class="h-100 d-flex justify-content-center align-content-center ma-5"
    justify="center"
    align="center"
    align-content="center">
    <v-snackbar
      v-model="hasShowNotification"
      :timeout="5000"
      position="absolute"
      location="top"
      top
      width="100vh"
      min-width="100vh"
      height="65px"
      class="inples__snackbar">{{notificationContent}}</v-snackbar>
    <v-card
        color="#FFFFFFAA"
        class="px-15 pt-5 pb-0 ma-5 overflow-hidden"
        style="border-radius: 14px;"
        elevation="0">
        <v-card-title>
            <v-row class="my-5">
            <v-img src="@/assets/logo.png"
                contain
                width="140px"
                height="22" />
            </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="my-5" justify="center">
            <v-form ref="form" class="px-0 mx-0">
              <v-text-field
                class="inples__field"
                rounded
                filled
                background-color="#FFFFFF"
                v-model="email"
                type="email"
                placeholder="Enter your email"
                :rules="emailRules"
                @keydown.enter="login" />
              <v-text-field
                class="inples__field"
                rounded
                filled
                background-color="#FFFFFF"
                v-model="password"
                placeholder="Your password"
                :rules="passwordRules"
                :type="show ? 'text' : 'password'"
                @keydown.enter="login">
                <template #append>
                  <v-img
                    class="clickable"
                    contain
                    :src="require(!show ? '@/assets/pass_eye_hidden.svg' : '@/assets/pass_eye.svg')"
                    @click="show = !show" />
                </template>
              </v-text-field>
                <v-row
                  justify="center"
                  class="mx-0 my-4">
                  <v-btn
                    height="60"
                    class="ma-5 white--text inples__btn text-capitalize"
                    width="100%"
                    elevation="0"
                    color="#171776AA"
                    rounded="14"
                    :disabled="isDisableNext"
                    @click="login">Sign in</v-btn>
                  <v-btn
                    style="text-transform: unset !important;"
                    :width="'100%'"
                    height="60"
                    color="#FFFFFF"
                    elevation="0"
                    class="inples__btn ma-5 overflow-hidden my-0 py-0"
                    :disabled="isLoading"
                    @click="loginForService()">
                    <v-row
                      class="my-0 py-0"
                      justify="center"
                      align="center">
                      <v-spacer />
                      <v-col cols="1" class="my-0 mx-0 px-0">
                        <v-img
                          src="../assets/google.svg"
                          width="22.88"
                          height="23.37"
                          contain
                          class="my-0 mx-0 px-0 clickable" />
                      </v-col>
                      <v-col class="ml-1 my-0" cols="1">
                        <span>Continue with Google</span>
                      </v-col>
                      <v-spacer />
                      <v-spacer />
                    </v-row>
                  </v-btn>
                </v-row>
              </v-form>
          </v-row>
        </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
import { API_DOMAIN, INPLES_AUTH_TOKENS_KEY } from '@/utils/consts'
import axios from 'axios'
import Vue, { ref, computed } from 'vue'
import { mapMutations, mapActions } from 'vuex'
// import { useMeta } from 'vue-meta'
// import Globalize from 'globalize'
import i18next from 'i18next'

let form = null
export default Vue.extend({
  name: 'HomeView',
  setup () {
    const email = ref('')
    const emailRules = ref([
      (v) => !!v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) || 'Email is required'
    ])
    const password = ref('')
    const passwordRules = ref([
      (v) => !!v && /^........+$/.test(v) || 'Password is required'
    ])
    const hasShowNotification = ref(false)
    const notificationContent = ref('')
    const show = ref(false)
    // useMeta(
    //   computed(() => ({
    //     title: this.event?.name ?? 'Event not found',
    //     og: {
    //       image: this.event?.bannerUrl ?? 'http://yourwebsite.com/images/default-banner.png'
    //     }
    //   }))
    // )
    return {
      email,
      emailRules,
      password,
      passwordRules,
      hasShowNotification,
      notificationContent,
      show,
    }
  },
  metaInfo() {
    // return {
    //   title: "inples",
    //   meta: [
    //     { name: 'release-version', content: 'release-version' }
    //   ],
    // };
  },
  computed: {
    isDisableNext () {
      return false
    }
  },
  mounted () {
    form = this.$refs.form
    const query = this.$route.query
    const redirect = query.route
    console.log(`redirect: ${redirect}`)
    const token = query.token
    console.log(`token: ${token}`)
    const code = query.code
    console.log(`code: ${code}`)
    if (code) {
      const data = {
        code
      }
      axios({
        method: 'post',
        url: `${API_DOMAIN}/api/auth/google/login/`,
        headers: {
          'Content-Type': 'application/json'
        },
        data
      })
        .then((response) => {
          const data = response.data
          const accessToken = data.access
          const refreshToken = data.refresh
          const tokens = {
            accessToken,
            refreshToken
          }
          const json = JSON.stringify(tokens)
          localStorage.setItem(INPLES_AUTH_TOKENS_KEY, json)
          this.SET_TOKEN({token: accessToken})
          this.$router.push({ name: 'home' })
        })
        .catch((response) => {
          console.log(`error: ${response}`)
        })
    }
    const json = localStorage.getItem(INPLES_AUTH_TOKENS_KEY)
    if (window.location.host === 'verify.inples.io') {
      const isHaveCert = window.location.href.includes('certificate')
      if (isHaveCert) {
        this.$router.push({ name: 'verify', query: { cert: window.location.href.substring(49) } })
      } else {
        this.$router.push({ name: 'verify' })
      }
    } else if (redirect) {
      if (json) {
        const tokens = JSON.parse(json)
        const accessToken = tokens.accessToken
        console.log(`accessToken check: ${accessToken}`)
        const data = {
          token: accessToken
        }
        axios({
          method: 'post',
          url: `${API_DOMAIN}/api/auth/token/verify/`,
          headers: {
            'Content-Type': 'application/json'
          },
          data
        })
          .then((response) => {
            console.log(`login success response: ${response}`)
            this.SET_TOKEN({token: accessToken})
            let query = {} 
            const isConfirm = redirect === '/signup'
            if (isConfirm) {
              query = { token }
            }
            this.$router.push({ path: redirect, query })
          })
          .catch((response) => {
            console.log(`error: ${response}`)
          })
      } else {
        this.$router.push({ path: redirect, query: { token } })
      }
    } else if (json) {
      const tokens = JSON.parse(json)
      const accessToken = tokens.accessToken
      console.log(`accessToken check: ${accessToken}`)
      const data = {
        token: accessToken
      }
      axios({
        method: 'post',
        url: `${API_DOMAIN}/api/auth/token/verify/`,
        headers: {
          'Content-Type': 'application/json'
        },
        data
      })
        .then((response) => {
          console.log(`login success response: ${response}`)
          this.SET_TOKEN({token: accessToken})
          this.$router.push({ name: 'home' })
        })
        .catch((response) => {
          console.log(`error: ${response}`)
        })
    }
    // Globalize.loadMessages({
    //   ru: {
    //     dashboard: {
    //       dashboard: "dashboard",
    //     }
    //   }
    // })
    // alert(Globalize("ru").formatMessage("dashboard/dashboard"))
  },
  methods: {
    ...mapMutations([
      'SET_TOKEN'
    ]),
    ...mapActions([
      'setToken'
    ]),
    login () {
      const isValid = form.validate()
      this.isFormValid = isValid
      if (!isValid) {
        return
      }
      const data = {
        username: this.email,
        email: this.email,
        password: this.password
      }
      axios({
        url: 'https://app.inples.io/api/auth/login/',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          console.log(response)
          const data = response.data
          const accessToken = data.access
          const refreshToken = data.refresh
          const tokens = {
            accessToken,
            refreshToken
          }
          const json = JSON.stringify(tokens)
          localStorage.setItem(INPLES_AUTH_TOKENS_KEY, json)
          // this.SET_TOKEN({token: accessToken})
          await this.setToken({token: accessToken})
          this.$router.push({ name: 'home' })
        })
        .catch((error) => {
          const response = error.response
          const data = response.data
          const keys = Object.keys(data)
          const key = keys[0]
          const dataItem = data[key]
          let msg = dataItem[0]
          if (!msg) {
            msg = 'Error'
          }
          this.notificationContent = msg
          this.hasShowNotification = true
        })
    },
    check () {
      const isValid = form.validate()
      this.isFormValid = isValid
      if (isValid) {
        return
      }
    },
    oauth2SignIn () {
      var oauth2Endpoint = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${API_DOMAIN}&prompt=consent&response_type=code&client_id=782566320101-ip2nm1tctct6b9uusbcl89hvii64aq52.apps.googleusercontent.com&scope=openid%20email%20profile&access_type=offline`
      window.location = oauth2Endpoint
    },
    loginForService () {
      this.oauth2SignIn()
    },
    removeSomeFile (idx) {
      this.files = this.files.filter((file, index) => index !== idx)
    },
  }
})
</script>
<style lang="scss" scoped>
  .inples {
    &__field {
      &__mobile {
        width: 100%;
      }
      &__desktop {
        width: 75%;
      }
    }
  }
  .clickable {
    cursor: pointer;
  }
  .inples__field > div > div > fieldset {
    display: none !important;
  }
</style>