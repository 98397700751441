<template>
  <v-card style="max-height: 100% !important; height: 100% !important; overflow: hidden;">
    <v-snackbar
      v-model="hasShowNotification"
      :timeout="5000"
      position="absolute"
      location="top"
      top
      width="100vw"
      min-width="100vw"
      height="65px"
      class="inples__snackbar">{{notificationContent}}</v-snackbar>
    <v-layout :style="{ backgroundImage: `url(${require('@/assets/background.png')})`, backgroundSize: 'cover', height: '100%', maxHeight: '100%', '-webkit-filter': $vuetify.theme.dark ? 'saturate(0)' : 'none', overflow: 'hidden' }">
      <v-col
        v-if="isShowDrawer"
        class="ma-0 pa-0"
        cols="12"
        md="3"
        sm="12">
        <v-navigation-drawer
          width="100%"
          theme="dark"
          :class="{'pa-5 ma-0 d-flex align-center overflow-hidden': true, 'inples__drawer': isShowDrawer && $vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'xs', 'blured_content': isShowNotificationsDrawer}"
          permanent
          :elevation="1"
          style="height: 100% !important; display: flex; justify-content: center; background-color: rgba(255, 255, 255, 0.5); border: 0px solid transparent !important; outline: 0px !important;">
          <v-list nav>
            <v-row
              justify="center"
              align="center">
              <v-spacer v-if="!($vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'xs')" />
              <v-col>
                <v-img
                  src="../assets/logo.svg"
                  contain
                  min-width="98"
                  min-height="15"
                  :width="$vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'xs' ? 98 : 140"
                  :height="$vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'xs' ? 15 : 22"
                  max-width="140"
                  max-height="22"  />
              </v-col>
              <v-spacer />
              <v-col v-if="$vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'xs'">
                <v-btn
                  color="#fff"
                  width="42"
                  height="42"
                  icon
                  @click="toggleDrawer">
                  <v-img
                    max-width="16"
                    src="@/assets/close.svg"
                    class="clickable align-self-center" />
                </v-btn>
              </v-col>
            </v-row>
            <v-list-item class="justify-center mt-6 mb-0">
              <v-avatar
                variant="outlined"
                size="105"
                class="d-flex justify-center text-center"
                style="align-self: center; border: 2px solid #fff;">
                <v-img
                  :src="isHavePhoto ? photo : require('@/assets/default_avatar.png')"
                  class="text-center ma-0"
                  min-width="105"
                  height="105" />
              </v-avatar>
            </v-list-item>
            <v-list-item class="justify-center ma-0">
              <p class="font-weight-medium ma-0">{{userName}}</p>
            </v-list-item>
            <v-list-item
              class="justify-center ma-0"
              title="Settings"
              value="Settings">
              <v-btn
                class="text-center ma-0"
                style="margin: auto; justify-content: center; align-self: center;"
                icon
                @click="openScreen('settings')">
                <v-img
                  class="ma-0"
                  height="24"
                  contain
                  src="../assets/settings.svg" />
              </v-btn>
            </v-list-item>
            <v-list-item
              title="Dashboard"
              value="Dashboard"
              :class="{'inples__drawer__selected-item': selectedItem === 'Dashboard', 'justify-center': true, 'clickable': true, 'mt-6': true}"
              @click="select('Dashboard')">
                <v-card
                  color="transparent"
                  elevation="0"
                  width="100%">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-img
                          height="24"
                          contain
                          src="../assets/dashboard.svg" />
                      </v-col>
                      <v-col>
                        <span
                          class="inples__label"
                          :style="selectedItem === 'Dashboard' ? 'color: #000;' : ''">
                          {{ dashboardLabel }}
                        </span>
                      </v-col>
                      <v-col />
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-list-item>
            <v-list-item
              prepend-icon="mdi-account-supervisor-circle"
              title="Timestamp"
              value="Timestamp"
              :class="{'inples__drawer__selected-item': selectedItem === 'Timestamp', 'inples__drawer__item': true, 'justify-center': true, 'clickable': true}">
              <v-card
                width="100%"
                color="transparent"
                elevation="0">
                <v-card-subtitle>
                  <v-row style="flex-wrap: no-wrap !important;">
                    <v-col cols="3">
                      <v-img
                        height="24"
                        contain
                        src="../assets/timestamps.svg" />
                    </v-col>
                    <v-col cols="6">
                      <span class="inples__label" :style="selectedItem === 'Timestamp' ? 'color: #000;' : ''" @click="select('Timestamp'); isExpandTimestamp = true">
                        Timestamp
                      </span>
                    </v-col>
                    <v-spacer />
                    <v-col @click="isExpandTimestamp = !isExpandTimestamp">
                      <v-icon
                        color="#171776"
                        class="align-self-center inples__drawer__item-icon">{{
                        isExpandTimestamp ?
                          'mdi-chevron-up'
                        :
                          'mdi-chevron-down'
                        }}</v-icon>
                    </v-col>
                  </v-row>
                </v-card-subtitle>
                <v-expand-transition>
                  <v-card-text v-if="isExpandTimestamp">
                    <v-row>
                      <v-col cols="3" />
                      <v-col @click="addNew">
                        <span class="inples__label">Add new</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" />
                      <v-col @click="openDrafts">
                        <span class="inples__label">Drafts</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" />
                      <v-col @click="checkUp">
                        <span class="inples__label">Check up</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" />
                      <v-col>
                        <span class="inples__label">Usage stats</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-expand-transition>
              </v-card>
            </v-list-item>
            <v-list-item
              prepend-icon="mdi-clock-start"
              title="anti piracy"
              value="anti piracy"
              :class="{'inples__drawer__selected-item': selectedItem === 'Anti-piracy', 'inples__drawer__item': true, 'justify-center': true, 'clickable': true}">
              <v-card
                width="100%"
                color="transparent"
                elevation="0">
                <v-card-subtitle>
                  <v-row>
                    <v-col
                      cols="3"
                      class="mx-0">
                      <v-img
                        height="24"
                        contain
                        src="../assets/anti_piracy.svg" />
                    </v-col>
                    <v-col
                      cols="6">
                      <span
                        :style="selectedItem === 'Anti-piracy' ? 'color: #000;' : ''"
                        class="inples__label"
                        @click="select('Anti-piracy')">
                        Anti-piracy
                      </span>
                    </v-col>
                    <v-col
                      class="mx-0 px-0"
                      cols="3">
                      <v-btn
                        elevation="0"
                        color="#171776"
                        class="text-caption pa-4 white--text text-start inples__btn"
                        style="text-transform: unset !important;"
                        max-width="39"
                        max-height="22">coming<br />soon</v-btn>
                    </v-col>
                  </v-row>
                </v-card-subtitle>
                <v-card-text v-if="isExpandAntiPiracy">
                  <v-col>
                    <span class="inples__label">Add new</span>
                  </v-col>
                  <v-col>
                    <span class="inples__label">Manage</span>
                  </v-col>
                  <v-col>
                    <span class="inples__label">Reports</span>
                  </v-col>
                  <v-col>
                    <span class="inples__label">Usage stats</span>
                  </v-col>
                </v-card-text>
              </v-card>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-col>
      <v-col
        v-if="selectedItem === 'Dashboard'"
        :cols="(isShowDrawer && isShowNotificationsDrawer) ? 6 : (isShowDrawer || isShowNotificationsDrawer) ? 9 : 12"
        style="overflow-y: hidden;"
        :class="{'blured_content': isShowNotificationsDrawer, 'ma-0 pa-0': true}">
        <v-container class="inples__tab__content" style="overflow-y: hidden;">
          <v-expand-transition>
            <v-toolbar
              color="transparent"
              flat>
              <v-toolbar-title :class="{'font-weight-bold': true, 'd-none': isShowMobileBtn()}">
                Stats
              </v-toolbar-title>
              <v-spacer />
              <v-col
                class="overflow-hidden"
                :cols="isShowMobileBtn() ? 12 : 3">
                <v-text-field
                  :background-color="($vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm') && !isSearchFocus ? 'transparent' : '#ffffffAA'"
                  class="mt-8 search_field"
                  ref="searchRef"
                  v-model="search"
                  filled
                  height="48px"
                  :placeholder="($vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm') && !isSearchFocus ? '' : 'Start typing...'"
                  rounded
                  @keydown.enter="searchRids"
                  @focus="isSearchFocus = true"
                  @blur="isSearchFocus = false">
                  <template #append>
                    <v-img
                      src="@/assets/search.svg"
                      class="clickable"
                      @click="searchRids" />
                  </template>
                  <template v-slot:v-input="{ attrs }">
                    <input
                      :style="{
                        'color': '#ffffff',
                        'background-color': '#ff0000'
                      }"
                      v-bind="attrs" />
                  </template>
                </v-text-field>
              </v-col>
              <v-btn
                :color="$vuetify.breakpoint.name === 'md' || $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl' ? '#fff' : 'transparent'"
                width="52"
                height="52"
                icon
                elevation="0"
                :class="{'inples__drawer__item-icon': $vuetify.breakpoint.name === 'md' || $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl', 'd-none': isShowMobileBtn()}">
                <v-badge
                  dot
                  :color="isHaveNotifications ? 'green' : 'transparent'">
                  <v-img
                    contain
                    width="20"
                    height="24"
                    src="@/assets/notifications.svg"
                    @click="viewNotifications"  />
                </v-badge>
              </v-btn>
              <v-btn
                icon
                :class="{'d-none': isShowMobileBtn(), 'd-block': !isShowMobileBtn(), 'd-md-none': true}"
                @click="toggleDrawer">
                <v-img
                  max-width="24"
                  max-height="18.05"
                  contain
                  src="@/assets/burger.svg"  /> 
              </v-btn>
            </v-toolbar>
          </v-expand-transition>
          <v-container :class="{'blured_content': isSearchFocus}">
            <v-row
              class="mb-6"
              no-gutters
            >
              <v-col
                cols="12"
                lg="6"
                sm="12"
                md="12">
                <v-card
                  elevation="0"
                  color="#FFFFFF66"
                  class="ma-2 overflow-hidden"
                  :style="{background: 'linear-gradient(0deg, #02C6A233, #19A4FB33)', backgroundSize: 'cover', backgroundPosition: '0px', borderRadius: '14px', padding: '30px', maxHeight: '310px' }">
                  <p class="inples__label">TIMESTAMPS</p>
                  <v-row class="ma-0">
                    <p class="font-weight-bold text-h4">{{timestampsCount}}/{{maxTimestampsCount}}</p>
                    <v-spacer />
                    <p class="title font-weight-regular">Timestamps created ({{getPercent(0, maxTimestampsCount, timestampsCount)}}%)</p>
                  </v-row>
                  <v-slider
                    height="8"
                    track-color="rgba(255, 255, 255, 0.4)"
                    track-fill-color="rgba(255, 255, 255, 0.4)"
                    color="#171776"
                    readonly
                    class="my-0"
                    :value="getPercent(0, maxTimestampsCount, timestampsCount)" />
                  <v-row class="ma-0">
                    <p class="font-weight-bold text-h4">{{memoryUsed|formatBytesInt}}/{{maxMemoryUsed|formatBytes}}</p>
                    <v-spacer />
                    <p class="title font-weight-regular">Memory used ({{getPercent(0, maxMemoryUsed, memoryUsed)}}%)</p>
                  </v-row>
                  <v-slider
                    height="8"
                    track-color="rgba(255, 255, 255, 0.4)"
                    track-fill-color="rgba(255, 255, 255, 0.4)"
                    readonly
                    color="#171776"
                    bg-color="red"
                    class="my-0"
                    :value="getPercent(0, maxMemoryUsed, memoryUsed)" />
                  <v-row class="ma-0">
                    <p
                      class="inples__label clickable"
                      @click="select('Timestamp')">All timestamps</p>
                    <v-spacer />
                    <p
                      class="inples__label clickable"
                      @click="$router.push({ name: 'settings', query: { action: 'plans' } })">View plans</p>
                  </v-row>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                lg="6"
                sm="12"
                md="12">
                <v-card
                  elevation="0"
                  color="#FFFFFF66"
                  class="ma-2 overflow-hidden"
                  :style="{background: 'linear-gradient(0deg, #02C6A233, #19A4FB33)', borderRadius: '14px', padding: '30px', maxHeight: '310px' }"
                  height="95%">
                  <p class="title font-weight-regular inples__label text-uppercase">anti-piracy</p>
                  <p class="text-h5">We’re working hard to bring you <br />Anti-piracy as soon as possible.</p>
                  <p class="text-subtitle-1 font-weight-regular">Meanwhile you can join the waitlist.<br />We’ll send you a notification once it’s up & running.</p>
                  <v-btn
                    elevation="0"
                    height="40"
                    width="160"
                    style="text-transform: unset !important;"
                    rounded
                    class="white--text"
                    color="#171776"
                    :disabled="!!antiPiracyStatus"
                    @click="joinWaitList">Join waitlist</v-btn>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                class="my-2"
                cols="12"
                sm="12"
                md="12"
                lg="6">
                <v-card
                  elevation="0"
                  color="#FFFFFF66"
                  class="ma-2"
                  :style="{ backgroundImage: `url(${require('@/assets/discover.png')})`, backgroundPosition: 'calc(100%) 100%', borderRadius: '14px', padding: '37.5px' , overflow: 'hidden'}"
                  min-height="100%">
                  <p class="text-uppercase font-weight-bold text-h5">Discover our plan options</p>
                  <p>Contrary to popular belief, Lorem Ipsum is not simply<br />random. It has roots in a piece.</p>
                  <v-row
                    class="ma-0">
                    <v-btn
                      elevation="0"
                      height="40"
                      width="160"
                      style="text-transform: unset !important;"
                      rounded
                      class="white--text"
                      color="#171776"
                      @click="$router.push({ name: 'settings', query: { action: 'plans' } })">Discover plans</v-btn>
                    <v-spacer />
                  </v-row>
                </v-card>
              </v-col>
              <v-col
                class="my-2"
                cols="12"
                sm="12"
                md="12"
                lg="6">
                <v-card
                  elevation="0"
                  color="#FFFFFF66"
                  :style="{ borderRadius: '14px', padding: '30px', overflow: 'hidden' }"
                  class="ma-2"
                  min-height="100%">
                  <v-row class="ma-0">
                    <p class="text-h5 text-uppercase font-weight-bold">FAQ</p>
                    <v-spacer />
                    <p
                      class="title font-weight-regular inples__label inples__label__btn clickable"
                      @click="openLink('https://www.inples.io/general-questions/')">View all</p>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col
                      cols="10"
                      class="ma-0">
                      <p class="ma-0 clickable inples__label__faq">Best way to timestamp a file in the system</p>
                    </v-col>
                    <v-spacer />
                      <v-btn
                        class="pa-0 ma-0 text-end"
                        style="margin: auto; justify-content: center; align-self: center;"
                        icon>
                        <v-icon color="#171776">mdi-plus</v-icon>
                      </v-btn>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col
                      cols="10"
                      class="ma-0">
                      <p class="ma-0 clickable inples__label__faq">Things to consider with out anti-piracy algorythm</p>
                    </v-col>
                    <v-spacer />
                      <v-btn
                        class="text-center"
                        style="margin: auto; justify-content: center; align-self: center;"
                        icon>
                        <v-icon color="#171776">mdi-plus</v-icon>
                      </v-btn>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col
                      cols="10"
                      class="ma-0">
                      <p class="ma-0 clickable inples__label__faq">How many timestamps I can have on one project?</p>
                    </v-col>
                    <v-spacer />
                      <v-btn class="text-center" style="margin: auto; justify-content: center; align-self: center;"
                        icon>
                        <v-icon color="#171776">mdi-plus</v-icon>
                      </v-btn>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
        <v-footer
          :class="{'mt-5': true, ' mt-sm-5': true, 'mt-md-5': true, 'mt-lg-5': true, 'mt-xl-0': true, 'blured_content': isSearchFocus}"
          color="transparent">
          <span
            v-if="false"
            class="mx-5 inples__label clickable">
            Legal
          </span>
          <span
            v-if="false"
            class="mx-5 inples__label clickable">
            FAQ
          </span>
          <span class="mx-5 inples__label clickable">
            Plans
          </span>
          <v-spacer />
          <span class="mx-5">
            © {{getCurrentYear}} Inples co. Ltd.
          </span>
        </v-footer>
      </v-col>
      <v-col
        v-else-if="selectedItem === 'Timestamp'"
        style="height: calc(100vh);"
        :cols="(isShowDrawer && isShowNotificationsDrawer) ? 6 : (isShowDrawer || isShowNotificationsDrawer) ? 9 : 12"
        :class="{'blured_content': isShowNotificationsDrawer}">
        <v-toolbar
          color="transparent"
          flat>
          <v-toolbar-title class="font-weight-bold mt-8">
            <v-row class="ma-0 pa-0">
              <v-col cols="7">
                <v-select
                  class="ma-0 pa-0 inples__field inples__field__rounded"
                  filled
                  shaped
                  rounded
                  background-color="rgba(255, 255, 255, 0.7)"
                  :items="filters"
                  v-model="filter">
                  <template #append>
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="2">
                <v-btn icon>
                  <v-img :src="timestampViewMode === 'tiles' ?
                      require('../assets/tiles.svg')
                    :
                      require('../assets/tiles_disabled.svg')"
                    height="24"
                    contain
                    @click="setViewMode('tiles')" />
                </v-btn>
              </v-col>
              <v-col cols="2">
                <v-btn icon>
                  <v-img :src="timestampViewMode === 'list' ?
                    require('../assets/list.svg')
                  :
                    require('../assets/list_disabled.svg')"
                    height="24"
                    contain
                    @click="setViewMode('list')" />
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar-title>
          <v-spacer />
          <v-col
            class="overflow-hidden"
            :cols="isShowMobileBtn2() ? 12 : 3">
            <v-text-field
              :background-color="($vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm') && !isSearchFocus ? 'transparent' : '#ffffffAA'"
              filled
              ref="searchRef2"
              class="mt-8"
              v-model="search"
              height="52px"
              :placeholder="($vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm') && !isSearchFocus ? '' : 'Start typing...'"
              rounded
              @keydown.enter="searchRids"
              @focus="isSearchFocus = true"
              @blur="isSearchFocus = false">
              <template #append>
                <v-img
                  src="@/assets/search.svg"
                  class="clickable"
                  @click="searchRids" />
              </template>
              <template v-slot:v-input="{ attrs }">
                <input
                  :style="{
                    'color': '#ffffff',
                    'background-color': '#ff0000'
                  }"
                  v-bind="attrs" />
              </template>
            </v-text-field>
          </v-col>
          <v-btn
            :color="$vuetify.breakpoint.name === 'md' || $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl' ? '#fff' : 'transparent'"
            width="52"
            height="52"
            icon
            elevation="0"
            :class="{'inples__drawer__item-icon': $vuetify.breakpoint.name === 'md' || $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl', 'd-none': isShowMobileBtn2()}">
            <v-badge
              dot
              :color="isHaveNotifications ? 'green' : 'transparent'">
              <v-img
                contain
                width="20"
                height="24"
                src="@/assets/notifications.svg"
                @click="viewNotifications"  />
            </v-badge>
          </v-btn>
          <v-btn
            icon
            :class="{'d-none': isShowMobileBtn2(), 'd-block': !isShowMobileBtn2(), 'd-md-none': true}"
            @click="toggleDrawer">
            <v-img
              max-width="24"
              max-height="18.05"
              contain
              src="@/assets/burger.svg"  /> 
          </v-btn>
        </v-toolbar>
        <v-container
          style="height: 100vh !important; overflow-y: scroll;"
          :class="{'inples__tab__content': true, 'blured_content': isSearchFocus}"
          ref="timestampsContainer"
          @scroll="detectScroll">
          <v-container
            v-if="timestampViewMode === 'list'"
            style="height: vh80;">
            <v-col
              v-if="timestamps.length"
              class="mx-0 my-1 px-0"
              sm="12"
              md="12"
              lg="12">
              <v-toolbar
                color="#FFFFFF00"
                height="80"
                elevation="0"
                class="inples__settings__payment-method">
                <v-col class="mx-0 px-0"
                  cols="2">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">Name</v-btn>
                </v-col>
                <v-col class="mx-0 px-0"
                  cols="2">
                  <v-btn elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">Date</v-btn>
                </v-col>
                <v-col class="mx-0 px-0"
                  cols="2">
                  <v-btn elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">Type</v-btn>
                </v-col>
              </v-toolbar>
            </v-col>
            <v-col
              v-for="(timestamp, index) in timestamps"
              :key="index"
              class="mx-0 my-1 px-0 h-100"
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12">
              <v-toolbar
                color="#FFFFFF44"
                height="80"
                elevation="0"
                class="inples__settings__payment-method inples__timestamp clickable"
                @click="openTimestamp(timestamp)">
                <v-col
                  class="mx-0 px-0 overflow-hidden"
                  cols="2">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">{{getTimestampName(timestamp)}}</v-btn>
                </v-col>
                <v-col
                  class="mx-0 px-0 overflow-hidden"
                  cols="2">
                  <v-btn elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">{{getTimestampDate(timestamp)}}</v-btn>
                </v-col>
                <v-col class="mx-0 px-0"
                  cols="2">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">{{getTimestampType(timestamp)}}</v-btn>
                </v-col>
                <v-spacer />
                <v-menu>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click.native="null">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list width="300">
                    <v-list-item>
                      <v-list-item-title>Examine</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Fine tune</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="false">
                      <v-list-item-title>Add content</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Export content</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Certificate</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="false">
                      <v-list-item-title>Transfer rights</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteTimestamp(index)">
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
            </v-col>
          </v-container>
          <v-container
            v-else
            style="height: vh80;"
            class="bg-surface-variant">
            <v-col
              v-for="(timestamp, index) in timestamps"
              :key="index"
              sm="6"
              md="5"
              lg="6"
              class="inples__timestamp__tile">
              <v-card
                elevation="0"
                color="#FFFFFF66"
                :style="{ borderRadius: '14px', padding: '30px' }"
                class="ma-2 inples__timestamp clickable overflow-hidden"
                height="235"
                @click="openTimestamp(timestamp)">
                <v-row class="ma-0">
                  <p class="text-uppercase font-weight-bold">{{getTimestampName(timestamp)}}</p>
                  <v-spacer />
                  <v-menu>
                    <template v-slot:activator="{on, attrs}">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click.native="null">
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-list width="300">
                      <v-list-item>
                        <v-list-item-title>Examine</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Fine tune</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Add content</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Export content</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Certificate</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Transfer rights</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-row>
                <v-row class="ma-0">
                  <p>{{getTimestampDate(timestamp)}}</p>
                </v-row>
                <v-row class="ma-0">
                  <p>{{getTimestampType(timestamp)}}</p>
                </v-row>
              </v-card>
            </v-col>
          </v-container>
          <div style="clear: both;" />
          <p
            v-if="!timestamps.length"
            class="title text-center my-5">Timestamps is empty</p>
          <v-footer
          color="transparent"
          :class="{'my-5': true, 'blured_content': isSearchFocus}">
          <span class="mx-5 inples__label clickable">
            Legal
          </span>
          <span
            v-if="false"
            class="mx-5 inples__label clickable">
            FAQ
          </span>
          <span
            v-if="false"
            class="mx-5 inples__label clickable">
            Plans
          </span>
          <v-spacer />
          <span class="mx-5">
            © {{getCurrentYear}} Inples co. Ltd.
          </span>
          </v-footer>
        </v-container>
      </v-col>
      <v-col
        v-else-if="selectedItem === 'Drafts'"
        style="height: calc(100vh);"
        :cols="(isShowDrawer && isShowNotificationsDrawer) ? 6 : (isShowDrawer || isShowNotificationsDrawer) ? 9 : 12"
        :class="{'blured_content': isShowNotificationsDrawer}">
        <v-container
          ref="draftsContainer"
          style="height: calc(100vh - 175px) !important; overflow-y: scroll;"
          class="inples__tab__content"
          @scroll="detectScroll">
          <v-expand-transition>
            <v-toolbar
              color="transparent"
              flat>
              <v-toolbar-title :class="{'font-weight-bold': true, 'd-none': isShowMobileBtn()}">
                Drafts
              </v-toolbar-title>
              <v-spacer />
              <v-btn
                icon
                :class="{'d-none': isShowMobileBtn(), 'd-block': !isShowMobileBtn(), 'd-md-none': true}"
                @click="toggleDrawer">
                <v-img
                  max-width="24"
                  max-height="18.05"
                  contain
                  src="@/assets/burger.svg"  /> 
              </v-btn>
            </v-toolbar>
          </v-expand-transition>
          <v-container v-if="archiveNames.length">
            <v-expand-transition>
                <div>
                  <v-toolbar
                    color="transparent"
                    height="20"
                    elevation="0"
                    class="inples__settings__payment-method my-5">
                    <v-col
                      class="overflow-hidden mx-0 px-0"
                      cols="3">
                      <v-btn
                        elevation="0"
                        color="transparent"
                        :ripple="false"
                        class="font-weight-bold inples__table__item">Draft name</v-btn>
                    </v-col>
                    <v-col
                      class="overflow-hidden mx-0 px-0"
                      cols="3">
                      <v-btn
                        elevation="0"
                        color="transparent"
                        :ripple="false"
                        class="font-weight-bold inples__table__item">Date</v-btn>
                    </v-col>
                  </v-toolbar>
                  <v-toolbar
                    prominent
                    :extended="isShowDetails[index]"
                    color="#FFFFFF44"
                    height="80"
                    elevation="0"
                    class="overflow-hidden inples__settings__payment-method my-5 clickable"
                    v-for="(archiveName, index) in archiveNames"
                    :key="index"
                    @click="toggleDraft(index)">
                    <template
                      #extension
                      v-if="isShowDetails[index]">
                      <v-row
                        v-for="draft in drafts[index]"
                        :key="`${archiveName}_${draft}`"
                        class="py-2">
                        <v-col
                          cols="3"
                          class="overflow-hidden align-self-center">
                          <v-btn
                            elevation="0"
                            color="transparent"
                            :ripple="false"
                            class="title ml-5 text-subtitle-1 overflow-hidden inples__table__item">{{draft}}</v-btn>
                        </v-col>
                        <v-col
                          cols="3"
                          class="overflow-hidden align-self-center">
                          <v-btn
                            elevation="0"
                            color="transparent"
                            :ripple="false"
                            class="overflow-hidden font-weight-bold inples__table__item">{{rawDrafts[index].file_size|formatBytes}}</v-btn>
                        </v-col>
                        <v-spacer />
                      </v-row>
                    </template>
                    <v-col
                      cols="3"
                      class="overflow-hidden align-self-center">
                      <v-btn
                        elevation="0"
                        color="transparent"
                        :ripple="false"
                        class="overflow-hidden font-weight-bold inples__table__item">{{archiveName}}</v-btn>
                    </v-col>
                    <v-col
                      cols="3"
                      class="overflow-hidden align-self-center">
                      <v-btn
                        elevation="0"
                        color="transparent"
                        :ripple="false"
                        class="overflow-hidden font-weight-bold inples__table__item">{{getDraftDate(rawDrafts[index])}}</v-btn>
                    </v-col>
                    <v-btn
                      style="text-transform: unset;"
                      elevation="0"
                      color="transparent"
                      class="overflow-hidden font-weight-bold indigo--text align-self-center inples__table__item"
                      :disabled="isDisableNext"
                      @click="$router.push({ name: 'rid', query: { file: archiveName } })">Continue</v-btn>
                    <v-spacer />
                    <v-menu>
                      <template v-slot:activator="{on, attrs}">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          class="overflow-hidden align-self-center">
                          <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                      </template>
                      <v-list width="300">
                        <v-list-item @click="deleteDraft(index)">
                          <v-list-item-title>Delete</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-toolbar>
                </div>
            </v-expand-transition>
          </v-container>
          <p
            v-else
            class="title text-center my-5">Drafts is empty</p>
        </v-container>
      </v-col>
      <v-col
        v-else-if="selectedItem === 'Add new'"
        :cols="(isShowDrawer && isShowNotificationsDrawer) ? 6 : (isShowDrawer || isShowNotificationsDrawer) ? 9 : 12"
        :class="{'blured_content': isShowNotificationsDrawer}">
        <v-container
          style="overflow-y: scroll;">
          <v-expand-transition>
            <v-toolbar
              color="transparent"
              flat>
              <v-spacer />
              <v-btn
                icon
                :class="{'d-none': isShowMobileBtn(), 'd-block': !isShowMobileBtn(), 'd-md-none': true}"
                @click="toggleDrawer">
                <v-img
                  max-width="24"
                  max-height="18.05"
                  contain
                  src="@/assets/burger.svg"  /> 
              </v-btn>
            </v-toolbar>
          </v-expand-transition>
          <v-expand-transition>
            <v-toolbar
              color="transparent"
              flat>
              <v-row v-if="addNewCursor === 0">
                <v-col class="px-0 mx-0" cols="3">
                  <span class="text-h5 font-weight-medium">Adding new timestamp</span>
                </v-col>
                <v-col>
                  <v-row
                    class="ma-0 pa-0"
                    no-gutters>
                    <v-col class="px-0 mx-0" cols="1">
                      <p class="text-end mx-0 inples__label font-weight-medium">{{addNewProgress}}%</p>
                    </v-col>
                    <v-col class="ml-5">
                      <v-progress-linear
                        style="pointer-events: none; border-radius: 26px;" 
                        color="#171776"
                        height="16"
                        rounded
                        rounded-bar
                        background-color="#fff"
                        v-model="addNewProgress" />
                    </v-col>
                    <v-col
                      v-if="isShowDrawer"
                      cols="2" />
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                v-else
                justify="center">
                <p class="inples__label font-weight-medium">{{addNewProgress}}%</p>
                <v-col class="ml-2 my-0 pa-0">
                  <v-progress-linear
                    style="pointer-events: none; border-radius: 26px;"
                    color="#171776"
                    height="16"
                    rounded="26"
                    background-color="#fff"
                    rounded-bar
                    v-model="addNewProgress" />
                </v-col>
                <v-col
                  v-if="isShowDrawer"
                  cols="2" />
              </v-row>
            </v-toolbar>
          </v-expand-transition>
          <v-container
            v-if="addNewCursor === 0"
            class="ma-0 pa-0">
            <v-expand-transition>
              <div>
                <p
                  v-if="addNewCursor !== 0"
                  class="display-1">Adding new timestamp(s)</p>
                <v-container v-if="!files.length">
                  <v-card
                    color="rgba(255, 255, 255, 0.5)"
                    class="inples__card clickable overflow-hidden"
                    elevation="0">
                    <v-card-text>
                      <div v-if="!file">
                        <div
                          :class="['dropZone', dragging ? 'dropZone-over' : '']"
                          @dragenter="dragging = true" @dragleave="dragging = false">
                          <div class="dropZone-info d-flex flex-row flex-wrap justify-center align-center" @drag="onChange">
                            <v-col
                              cols="12"
                              sm="12"
                              md="3"
                              lg="3"
                              class="d-flex justify-center align-center">
                              <v-img
                                class="nuxt-img-tag"
                                src="../assets/dragndrop.svg"
                                contain
                                width="52"
                                height="52" />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="3"
                              lg="3"
                              class="d-flex justify-center align-center">
                              <span class="text-center dropZone-title">Drop files here or click to select</span>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="3"
                              lg="3"
                              class="d-flex justify-center align-center">
                              <v-btn
                                class="white--text inples__btn text-capitalize"
                                max-width="284"
                                height="60"
                                :color="isHovering[0] ? '#4087ED' : '#171776'"
                                :disabled="false"
                                @mouseover="setHover(0)"
                                @mouseout="setHout(0)"
                                @click.prevent="chooseFiles">Choose files</v-btn>
                            </v-col>
                          </div>
                          <input
                            multiple
                            type="file"
                            ref="inputRef"
                            @change="onChange" />
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-container>
                <v-container
                  v-else
                  class="mx-0 px-0">
                  <v-col
                    cols="10"
                    class="mx-0 px-0">
                    <v-progress-linear
                      style="border-radius: 26px;" 
                      :clickable="false"
                      readonly
                      v-for="(file, i) in files"
                      :key="i"
                      :value="file.progress"
                      rounded
                      color="#fff"
                      class="my-5 mx-0 px-0"
                      :height="$vuetify.breakpoint.name === 'md' || $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl' ? 80 : windowHeight / 3"
                      @click.prevent>
                      <template v-slot:default="{ value }">
                        <v-row
                          justify="center"
                          align="center"
                          align-content="center">
                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            lg="3"
                            xl="3"
                            class="mx-2">
                            <p class="text-center inples__label">{{file.name}}</p>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            lg="3"
                            xl="3"
                            class="mx-2">
                            <p class="text-center inples__label">{{file.size | formatBytes}}</p>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            lg="3"
                            xl="3"
                            class="ma-2 d-flex justify-center align-center h-100">
                            <p class="h-100 text-center inples__label">{{value}}%</p>
                          </v-col>
                          <v-col
                            sm="12"
                            md="2"
                            lg="2"
                            xl="2"
                            cols="12"
                            class="text-center">
                            <v-btn
                              class="text-center"
                              icon
                              @click="removeFile(i)">
                              <v-img
                                class="text-center"
                                height="24"
                                contain
                                src="../assets/delete.svg" />
                            </v-btn>
                          </v-col>
                        </v-row>
                      </template>
                    </v-progress-linear>
                  </v-col>
                  <input
                    class="d-none"
                    multiple
                    type="file"
                    ref="inputRef2"
                    @change="onChange" />
                  <v-row class="px-2">
                    <v-btn
                      border="1"
                      elevation="0"
                      width="160"
                      height="60"
                      color="#171776"
                      class="text-capitalize inples__btn my-5 inples__outlined-btn"
                      variant="outlined"
                      outlined
                      @click="chooseMore">
                      <v-row justify="center" align="center">
                        <v-col>
                          <span class="inples__label">Add more</span>
                        </v-col>
                        <v-col>
                          <v-icon color="#171776">mdi-plus</v-icon>  
                        </v-col>
                      </v-row>  
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      width="160"
                      height="60"
                      color="#171776"
                      class="white--text text-capitalize inples__btn my-5"
                      variant="text"
                      :disabled="isDisableSendFiles"
                      @click="sendFiles()">
                        <v-row
                          align="center"
                          no-gutters>
                          <v-col class="ma-0 pa-0">
                            <p class="ma-0 pa-0">Next step</p>
                          </v-col>
                          <v-col>
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-col>
                        </v-row>
                      </v-btn>
                    <v-col cols="2" />
                  </v-row>
                </v-container>
              </div>
            </v-expand-transition>
          </v-container>
          <v-container v-if="addNewCursor === 1">
            <v-expand-transition>
              <v-form ref="detailsOfTheApplicantForm">
                <p class="display-1">Details of the applicant</p>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5">
                    <p>First name</p>
                    <v-text-field
                      :rules="nameRules"
                      v-model="firstName"
                      placeholder="First name"
                      rounded
                      filled
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field" />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5"
                    class="ml-lg-5">
                      <p>Last name</p>
                      <v-text-field
                        :rules="nameRules"
                        v-model="lastName"
                        placeholder="Last name"
                        rounded
                        filled
                        background-color="rgba(255, 255, 255, 0.7)"
                        class="inples__field" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5">
                    <p>National id</p>
                    <v-text-field
                      :rules="nationalIdRules"
                      v-model="nationalId"
                      placeholder="National id"
                      rounded
                      filled
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field" />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5"
                    class="ml-lg-5">
                    <p>Country</p>
                    <v-text-field
                      :rules="countryRules"
                      v-model="country"
                      placeholder="Country"
                      rounded
                      filled
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5">
                    <p>Address</p>
                    <v-text-field
                      :rules="addressRules"
                      v-model="address"
                      placeholder="Address"
                      rounded
                      filled
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field" />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5"
                    class="ml-lg-5">
                    <p>Email</p>
                    <v-text-field
                      :rules="emailRules"
                      v-model="email"
                      placeholder="Email"
                      rounded
                      filled
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="text-capitalize inples__btn my-5"
                    variant="text"
                    @click="back(25)">
                    <v-row
                      justify="center"
                      align="center">
                      <v-col>
                        <v-icon style="background-color: rgba(255, 255, 255, 0.5); border-radius: 100%;" class="align-self-center">mdi-chevron-left</v-icon>
                      </v-col>
                      <v-col>
                        <span class="inples__label">Back</span>
                      </v-col>
                    </v-row>
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    width="284"
                    height="60"
                    color="#171776"
                    class="white--text text-capitalize inples__btn my-5"
                    variant="text"
                    @click="checkBrief(50)">
                    <v-row
                      justify="center"
                      align="center">
                      <v-col>
                        <span>Next step</span>
                      </v-col>
                      <v-col>
                        <v-icon class="align-self-center">mdi-chevron-right</v-icon>
                      </v-col>
                    </v-row>
                  </v-btn>
                <v-col cols="2" />
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
          <v-container v-if="addNewCursor === 2">
            <v-expand-transition>
              <v-form ref="theWorkForm">
                <p class="display-1">The work</p>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5">
                    <p>Title of the work</p>
                    <v-text-field
                      :rules="workTitleRules"
                      v-model="workTitle"
                      placeholder="Title of the work"
                      rounded
                      filled
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field" />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5"
                    class="ml-lg-5">
                      <p>Year of creation</p>
                      <v-text-field
                        :rules="[
                          (v) => Number.isInteger(Number(v)) || 'Year is required',
                          (v) => Number(v) > getCurrentYear - 100 || 'Year is required',
                          (v) => Number(v) <= getCurrentYear || 'Year is required',
                        ]"
                        v-model="year"
                        placeholder="Year of creation"
                        rounded
                        filled
                        background-color="rgba(255, 255, 255, 0.7)"
                        class="inples__field" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5">
                    <p>Has the work been published yet?</p>
                    <v-select
                      :rules="workPublishedRules"
                      v-model="workPublished"
                      :items="workPublishedItems"
                      placeholder="Select an option"
                      filled
                      shaped
                      rounded
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field"
                      @keydown.tab="switchCursor"  />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5"
                    class="ml-lg-5">
                      <p>Type of work</p>
                      <v-text-field
                        :rules="workTypeRules"
                        readonly
                        v-model="workType"
                        placeholder="Select an option"
                        rounded
                        filled
                        append-icon="mdi-plus"
                        background-color="rgba(255, 255, 255, 0.7)"
                        class="inples__field"
                        @click:append="isShowTypes = true"  />
                  </v-col>
                </v-row>
                <v-col cols="10">
                  <v-expand-transition>
                    <v-chip-group
                      v-if="isShowTypes"
                      column
                      variant="outlined"
                      outlined
                      selected-class="text-deep-purple-darken-4"
                      color="#171776">
                      <v-chip
                        :class="'selectedChip'"
                        active-class="selectedChip"
                        :text-color="!isHovering[i] ? '#171776' : '#fff'"
                        :outlined="!isHovering[i]"
                        v-for="(type, i) in workTypeItems"
                        :key="i"
                        :color="!isHovering[i] ? '#171776' : '#171776'"
                        @mouseover="setHover(i)"
                        @mouseout="setHout(i)"
                        @click="selectWorkType(type)">{{type}}</v-chip>
                    </v-chip-group>
                  </v-expand-transition>
                </v-col>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="10"
                    xl="10"
                    no-resize>
                    <p>Description of the work (if applicable)</p>
                    <v-textarea
                      v-model="workDesc"
                      no-resize
                      rounded
                      filled
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field" />
                  </v-col>
                  <v-col
                    sm="12"
                    md="12"
                    lg="5">
                  </v-col>
                </v-row>
                <v-row>
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="text-capitalize inples__btn my-5 inples__table__item"
                    variant="text"
                    @click="back(38)">
                    <v-row justify="center" align="center">
                      <v-col>
                        <v-icon style="background-color: rgba(255, 255, 255, 0.5); border-radius: 100%;" class="align-self-center">mdi-chevron-left</v-icon>
                      </v-col>
                      <v-col>
                        <span class="inples__label">Back</span>
                      </v-col>
                    </v-row>  
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    width="284"
                    height="60"
                    color="#171776"
                    class="white--text text-capitalize inples__btn my-5"
                    variant="text"
                    @click="checkBrief(60)">
                    <v-row
                      justify="center"
                      align="center">
                      <v-col>
                        <span>Next step</span>
                      </v-col>
                      <v-col>
                        <v-icon class="align-self-center">mdi-chevron-right</v-icon>
                      </v-col>
                    </v-row>
                  </v-btn>
                  <v-col cols="2" />
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
          <v-container
            v-if="addNewCursor === 3"
            @click="hideNotificationsBar">
            <v-expand-transition>
              <v-form
                ref="aboutTheAuthorForm"
                class="overflow-scroll">
                <p class="display-1">About the author(s)</p>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5">
                    <p class="inples__label">Authored by</p>
                    <v-select
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field"
                      filled
                      shaped
                      rounded
                      v-model="author"
                      :items="authorItems"
                      placeholder="Authored by"
                      :rules="[
                        (v) => author.length || 'Author is required'
                      ]"
                      @keydown.tab="switchCursor" />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="5"
                    xl="5">
                  </v-col>
                </v-row>
                <v-container
                  v-if="author && isGroupAuthors"
                  class="ma-0 pa-0">
                  <div
                    v-for="(author, i) in authors"
                    :key="i"
                    class="ma-0 pa-0">
                    <v-divider
                      v-if="i > 0"
                      class="my-5" />
                    <v-row>
                      <p class="display-1">{{ `Author ${i + 1}` }}</p>
                      <v-spacer />
                      <v-btn
                        v-if="i > 0"
                        elevation="0"
                        class="text-capitalize"
                        color="transparent"
                        @click="deleteAuthor(i)">
                        <v-row justify="center" align="center">
                          <v-col>
                            <span>Delete author</span>
                          </v-col>
                          <v-col>
                            <v-img
                              class="text-center"
                              height="24"
                              contain
                              src="../assets/delete.svg" />
                          </v-col>
                        </v-row>  
                      </v-btn>
                      <v-col cols="2" />
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        sm="10"
                        md="10"
                        lg="5"
                        xl="5">
                        <p class="inples__label">Full name</p>
                        <v-text-field
                          :rules="nameRules"
                          v-model="authors[i].fullName"
                          placeholder="Full name"
                          rounded
                          filled
                          background-color="rgba(255, 255, 255, 0.7)"
                          class="inples__field" />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="10"
                        md="10"
                        lg="5"
                        xl="5"
                        class="ml-lg-5">
                          <p class="inples__label">Date of birth</p>
                          <v-text-field
                            readonly
                            :rules="bornRules"
                            v-model="authors[i].born"
                            placeholder="Date of birth"
                            rounded
                            filled
                            background-color="rgba(255, 255, 255, 0.7)"
                            class="inples__field">
                            <template #append>
                              <v-menu
                                content-class="elevation-0"
                                class="elevation-0"
                                :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on">mdi-calendar-month</v-icon>
                                </template>
                                <v-date-picker
                                  v-model="authors[i].born"
                                  class="my-2"
                                  elevation="0" />
                              </v-menu>
                            </template>
                          </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        sm="10"
                        md="10"
                        lg="5"
                        xl="5">
                        <p class="inples__label">Nationality</p>
                        <v-autocomplete
                          filled
                          shaped
                          rounded
                          background-color="rgba(255, 255, 255, 0.7)"
                          v-model="authors[i].nationality"
                          :items="getNationalities()"
                          placeholder="Nationality"
                          :rules="nationalityRules"
                          class="inples__field"
                          @keydown.tab="switchCursor"
                          @click="switchCursor" />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="10"
                        md="10"
                        lg="5"
                        xl="5"
                        class="ml-lg-5">
                          <p class="inples__label">Residential address</p>
                          <v-text-field
                            :rules="addressRules"
                            v-model="authors[i].address"
                            placeholder="Residential address"
                            rounded
                            filled
                            background-color="rgba(255, 255, 255, 0.7)"
                            class="inples__field" />
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        sm="10"
                        md="10"
                        lg="5"
                        xl="5">
                        <p class="inples__label">Phone</p>
                        <v-text-field
                          :rules="phoneRules"
                          v-model="authors[i].phone"
                          placeholder="Phone"
                          rounded
                          filled
                          background-color="rgba(255, 255, 255, 0.7)"
                          class="inples__field" />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="10"
                        md="10"
                        lg="5"
                        xl="5"
                        class="ml-lg-5">
                          <p class="inples__label">Email</p>
                          <v-text-field
                            :rules="emailRules"
                            v-model="authors[i].email"
                            placeholder="Email"
                            rounded
                            filled
                            background-color="rgba(255, 255, 255, 0.7)"
                            class="inples__field" />
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        sm="10"
                        md="10"
                        lg="5"
                        xl="5">
                        <v-row no-gutters>
                          <p class="inples__label">Creative pseudonym</p>
                          <v-spacer />
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click.prevent="openHint('Creative pseudonym')">
                                <v-icon
                                  color="#171776"
                                  size="24">mdi-information</v-icon>
                              </v-btn>
                            </template>
                            <span>Creative pseudonym</span>
                          </v-tooltip>
                        </v-row>
                        <!-- <p class="inples__label">Creative pseudonym</p> -->
                        <v-text-field
                          :rules="pseudonymRules"
                          v-model="authors[i].pseudonym"
                          placeholder="Creative pseudonym (to be filled in if only one author)"
                          rounded
                          filled
                          background-color="rgba(255, 255, 255, 0.7)"
                          class="inples__field" />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="10"
                        md="10"
                        lg="5"
                        xl="5"
                        class="ml-lg-5">
                          <p class="inples__label">Publish only with a creative pseudonym</p>
                          <v-select
                            :rules="pseudonymPublishRules"
                            placeholder="Select an option"
                            filled
                            shaped
                            rounded
                            background-color="rgba(255, 255, 255, 0.7)"
                            class="inples__field"
                            v-model="authors[i].pseudonymPublish"
                            :items="pseudonymPublishItems"
                            @keydown.tab="switchCursor" />
                      </v-col>
                    </v-row>
                  </div>
                  <v-container
                    class="ma-0 pa-0">
                    <v-row class=" mx-0">
                      <v-btn
                        border="1"
                        elevation="0"
                        width="160"
                        height="60"
                        color="#171776"
                        class="text-capitalize inples__btn my-5 inples__outlined-btn mx-0"
                        variant="outlined"
                        outlined
                        @click="addAuthor">
                        <v-row
                          justify="center"
                          align="center"
                          class="mx-0">
                          <v-col>
                            <span class="inples__label">Add author</span>
                          </v-col>
                          <v-col>
                            <v-icon color="#171776">mdi-plus</v-icon>  
                          </v-col>
                        </v-row>  
                      </v-btn>
                    </v-row>
                    <v-col class="my-4 mx-0">
                      <p>Distribution of copyright between co-authors</p>
                    </v-col>
                    <v-row>
                      <v-col
                        v-for="(author, i) in authors"
                        :key="i"
                        cols="12"
                        sm="12"
                        md="12"
                        lg="5">
                        <p class="inples__label">{{`Author ${i + 1}/${author.fullName}`}}</p>
                        <v-text-field
                          :rules="splitRules"
                          v-model="authors[i].split"
                          placeholder="Enter value (%)"
                          rounded
                          filled
                          background-color="rgba(255, 255, 255, 0.7)"
                          class="inples__field" />
                      </v-col>
                    </v-row>
                    <v-row v-if="authors.length">
                      <v-spacer />
                      <v-btn
                        border="1"
                        elevation="0"
                        width="160"
                        height="60"
                        outlined
                        color="#171776"
                        class="text-capitalize inples__btn my-5 inples__outlined-btn"
                        variant="text"
                        @click="evenSplit">
                        <v-row justify="center" align="center">
                          <v-col>
                            <span class="inples__label">Even split</span>
                          </v-col>
                          <v-col>
                            <v-icon color="#171776">mdi-plus</v-icon>  
                          </v-col>
                        </v-row>
                      </v-btn>
                      <v-col cols="2" />
                    </v-row>
                  </v-container>
                </v-container>
                <v-container
                  v-else-if="author"
                  class="ma-0 pa-0">
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      sm="10"
                      md="10"
                      lg="5"
                      xl="5">
                      <p class="inples__label">Full name</p>
                      <v-text-field
                        :rules="nameRules"
                        v-model="fullName"
                        placeholder="Full name"
                        rounded
                        filled
                        background-color="rgba(255, 255, 255, 0.7)"
                        class="inples__field" />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="10"
                      md="10"
                      lg="5"
                      xl="5"
                      class="ml-lg-5">
                        <p class="inples__label">Date of birth</p>
                        <v-text-field
                          readonly
                          :rules="bornRules"
                          v-model="born"
                          placeholder="Date of birth"
                          rounded
                          filled
                          background-color="rgba(255, 255, 255, 0.7)"
                          class="inples__field">
                          <template #append>
                            <v-menu
                              content-class="elevation-0"
                              class="elevation-0"
                              :close-on-content-click="false">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on">mdi-calendar-month</v-icon>
                              </template>
                              <v-date-picker
                                v-model="born"
                                class="my-2"
                                elevation="0" />
                            </v-menu>
                          </template>
                        </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      sm="10"
                      md="10"
                      lg="5"
                      xl="5">
                      <p class="inples__label">Nationality</p>
                      <v-autocomplete
                        filled
                        shaped
                        rounded
                        background-color="rgba(255, 255, 255, 0.7)"
                        v-model="nationality"
                        :items="getNationalities()"
                        placeholder="Nationality"
                        :rules="nationalityRules"
                        class="inples__field"
                        @keydown.tab="switchCursor"
                        @click="switchCursor" />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="10"
                      md="10"
                      lg="5"
                      xl="5"
                      class="ml-lg-5">
                        <p class="inples__label">Residential address</p>
                        <v-text-field
                          :rules="addressRules"
                          v-model="residentialAddress"
                          placeholder="Residential address"
                          rounded
                          filled
                          background-color="rgba(255, 255, 255, 0.7)"
                          class="inples__field" />
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      sm="10"
                      md="10"
                      lg="5"
                      xl="5">
                      <p class="inples__label">Phone</p>
                      <v-text-field
                        :rules="phoneRules"
                        v-model="phone"
                        placeholder="Phone"
                        rounded
                        filled
                        background-color="rgba(255, 255, 255, 0.7)"
                        class="inples__field" />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="10"
                      md="10"
                      lg="5"
                      xl="5"
                      class="ml-lg-5">
                        <p class="inples__label">Email</p>
                        <v-text-field
                          :rules="emailRules"
                          v-model="authorEmail"
                          placeholder="Email"
                          rounded
                          filled
                          background-color="rgba(255, 255, 255, 0.7)"
                          class="inples__field" />
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      sm="10"
                      md="10"
                      lg="5"
                      xl="5">
                      <v-row no-gutters>
                        <p class="inples__label">Creative pseudonym</p>
                        <v-spacer />
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click.prevent="openHint('Creative pseudonym')">
                              <v-icon
                                color="#171776"
                                size="24">mdi-information</v-icon>
                            </v-btn>
                          </template>
                          <span>Creative pseudonym</span>
                        </v-tooltip>
                      </v-row>
                      <v-text-field
                        :rules="pseudonymRules"
                        v-model="pseudonym"
                        placeholder="Creative pseudonym (to be filled in if only one author)"
                        rounded
                        filled
                        background-color="rgba(255, 255, 255, 0.7)"
                        class="inples__field" />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="10"
                      md="10"
                      lg="5"
                      xl="5"
                      class="ml-lg-5">
                        <p class="inples__label">Publish only with a creative pseudonym</p>
                        <v-select
                          :rules="pseudonymPublishRules"
                          placeholder="Select an option"
                          filled
                          shaped
                          rounded
                          background-color="rgba(255, 255, 255, 0.7)"
                          class="inples__field"
                          v-model="pseudonymPublish"
                          :items="pseudonymPublishItems"
                          @keydown.tab="switchCursor" />
                        <!-- <Multiselect
                          :searchable="false"
                          :prevent-autofocus="true"
                          v-model="pseudonymPublish"
                          :options="pseudonymPublishItems"
                          :showLabels="false"
                          :allowEmpty="false"
                          placeholder="Select an option"
                          class="inples__field" /> -->
                    </v-col>
                  </v-row>
                </v-container>
                <v-row
                  v-if="author"
                  no-gutters>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5">
                    <p class="inples__label">Juristic person full name</p>
                    <v-text-field
                      :rules="holderRules"
                      v-model="holder"
                      placeholder="Juristic person full name"
                      rounded
                      filled
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field" />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5"
                    class="ml-lg-5">
                    <v-row no-gutters>
                      <p class="inples__label">Attitude of the applicant/owner towards the author</p>
                      <v-spacer />
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click.prevent="openHint('Attitude of the applicant/owner towards the author')">
                            <v-icon
                              color="#171776"
                              size="24">mdi-information</v-icon>
                          </v-btn>
                        </template>
                        <span>Attitude of the applicant/owner towards the author</span>
                      </v-tooltip>
                    </v-row>
                    <v-text-field
                      v-model="attitude"
                      placeholder="Attitude of the applicant/owner towards the author"
                      rounded
                      filled
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="text-capitalize inples__btn my-5"
                    variant="text"
                    @click="back(50)">
                    <v-row
                      justify="center"
                      align="center">
                      <v-col>
                        <v-icon style="background-color: rgba(255, 255, 255, 0.5); border-radius: 100%;" class="align-self-center">mdi-chevron-left</v-icon>
                      </v-col>
                      <v-col>
                        <span class="inples__label">Back</span>
                      </v-col>
                    </v-row>  
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    v-if="author"
                    width="284"
                    height="60"
                    color="#171776"
                    class="white--text text-capitalize inples__btn my-5"
                    variant="text"
                    :disabled="!isHaveAuthors"
                    @click="checkBrief(100)">
                    <v-row
                      justify="center"
                      align="center">
                      <v-col>
                        <span>Next step</span>
                      </v-col>
                      <v-col>
                        <v-icon class="align-self-center">mdi-chevron-right</v-icon>
                      </v-col>
                    </v-row>
                  </v-btn>
                  <v-col cols="2" />
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
          <v-container v-if="addNewCursor === 4">
            <v-expand-transition>
              <v-form ref="termsOfUseAndDistributionForm">
                <p class="text-h5 font-weight-medium">Terms of use and distribution</p>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="3"
                    xl="5">
                    <p class="inples__label">Permitted use for third parties</p>
                    <v-select
                      placeholder="Select an option"
                      filled
                      shaped
                      rounded
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field"
                      v-model="permitted"
                      :items="permitDisplay"
                      @keydown.tab="switchCursor" />
                  </v-col>
                  <v-row v-if="permitted !== 'Permission is granted to use the work free of charge under the conditions described in the licence:'">
                    <v-spacer />
                    <v-col
                      cols="5"
                      sm="5"
                      md="5"
                      lg="4"
                      xl="4">
                      <v-checkbox
                        color="#171776"
                        v-model="termsOfUse">
                        <template v-slot:label>
                          <p class="my-0 py-0">I have read and accept<br />the <a
                                                                                target="_blank"
                                                                                href="https://www.inples.io/terms-conditions/"
                                                                                @click="openLink('https://www.inples.io/terms-conditions/')">Terms & Conditions</a></p>
                        </template>  
                      </v-checkbox>
                    </v-col>
                    <v-col
                      cols="5"
                      sm="5"
                      md="5"
                      lg="3"
                      xl="3">
                      <v-checkbox
                        color="#171776"
                        v-model="proofOfOriginality">
                        <template v-slot:label>
                          <p class="my-0 py-0">Proof of<br />originality</p>
                        </template>  
                      </v-checkbox>
                    </v-col>
                    <v-col cols="2" />
                  </v-row>
                  <v-col
                    v-if="permitted === 'Permission is granted to use the work free of charge under the conditions described in the licence:'"
                    cols="12"
                    sm="12"
                    md="12"
                    lg="5"
                    class="ml-lg-5">
                      <p class="inples__label">Do you want to be credited for your work?</p>
                      <v-select
                        placeholder="Select an option"
                        filled
                        shaped
                        rounded
                        background-color="rgba(255, 255, 255, 0.7)"
                        class="inples__field"
                        v-model="credited"
                        :items="creditedItems"
                        :rules="creditedRules"
                        @keydown.tab="switchCursor" />
                  </v-col>
                </v-row>
                <v-row
                  v-if="permitted === 'Permission is granted to use the work free of charge under the conditions described in the licence:'"
                  no-gutters>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="5">
                      <p class="inples__label">Do you allow adaptations of your work to be published?</p>
                      <v-select
                        placeholder="Select an option"
                        filled
                        shaped
                        rounded
                        background-color="rgba(255, 255, 255, 0.7)"
                        class="inples__field"
                        v-model="adaptationsPublished"
                        :items="adaptationsPublishedItems"
                        @keydown.tab="switchCursor" />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="5"
                    class="ml-lg-5">
                      <p class="inples__label">Do you allow commercial use of your work?</p>
                      <v-select
                        placeholder="Select an option"
                        filled
                        shaped
                        rounded
                        background-color="rgba(255, 255, 255, 0.7)"
                        class="inples__field"
                        v-model="commercial"
                        :items="commercialItems"
                        @keydown.tab="switchCursor" />
                  </v-col>
                </v-row>
                <v-row
                  v-if="permitted === 'Permission is granted to use the work free of charge under the conditions described in the licence:'"
                  no-gutters>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="5">
                    <v-card
                      elevation="0"
                      class="inples__card my-2 pa-3">
                      <v-card-title>
                        <p class="ma-0 pa-0 text-subtitle-1">{{licenses.length ? licenses[0] : ''}}</p>
                        <v-spacer />
                        <v-btn 
                          icon
                          @click="toggleLicenses">
                          <v-icon>{{ isShowLicenses ? 'mdi-close' : 'mdi-chevron-right' }}</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text
                        v-if="isShowLicenses"
                        class="px-8">
                        <v-row
                          v-for="(licenseItem, i) in licenseItems"
                          :key="i">
                          <p
                            class="inples__label clickable"
                            @click="toggleLicense(licenseItem)">{{licenseItem}}</p>
                          <v-spacer />
                          <div
                            class="pa-2 d-flex justify-center align-center"
                            :style="`border-radius: 100%; background-color: ${licenses.includes(licenseItem) ? '#171776' : 'transparent'}; width: 30px; height: 30px;`">
                            <v-img
                              width="16"
                              height="16"
                              :src="licenses.includes(licenseItem) ? require('../assets/attached.svg') : require('../assets/attach.svg')"
                              contain
                              class="clickable"
                              @click="openLicense(licenseLinks[i])" />
                          </div>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-spacer />
                  <v-col
                    sm="5"
                    md="5"
                    lg="2">
                    <v-checkbox
                      color="#171776"
                      v-model="termsOfUse">
                      <template v-slot:label>
                        <p class="my-0 py-0">I have read and accept<br />the <a
                                                                                target="_blank"
                                                                                href="https://www.inples.io/terms-conditions/"
                                                                                @click="openLink('https://www.inples.io/terms-conditions/')">Terms & Conditions</a></p>
                      </template>  
                    </v-checkbox>
                  </v-col>
                  <v-col
                    sm="5"
                    md="5"
                    lg="2">
                      <v-checkbox
                        color="#171776"
                        v-model="proofOfOriginality">
                        <template v-slot:label>
                          <p class="my-0 py-0">Proof of<br />originality</p>
                        </template>  
                      </v-checkbox>
                  </v-col>
                  <v-col cols="2" />
                </v-row>
                <v-col cols="10">
                  <v-divider class="my-5" />
                </v-col>
                <p class="text-h5 font-weight-medium">Congrats, you are 1 step away from defending your work!</p>
                <v-row>
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="text-capitalize inples__btn my-5"
                    variant="text"
                    @click="back(60)">
                    <v-row justify="center" align="center">
                      <v-col>
                        <v-icon style="background-color: rgba(255, 255, 255, 0.5); border-radius: 100%;" class="align-self-center">mdi-chevron-left</v-icon>
                      </v-col>
                      <v-col>
                        <span class="inples__label">Back</span>
                      </v-col>
                    </v-row>  
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    width="284"
                    height="60"
                    color="#171776"
                    class="white--text text-capitalize inples__btn my-5"
                    variant="text"
                    :disabled="!isCreateTimestampEnabled"
                    @click="createTimestamp()">
                    <v-row
                      class="px-2"
                      justify="space-between"
                      align="center">
                        <span class="white--text">Create timestamp</span>
                        <v-icon color="#fff">mdi-chevron-right</v-icon>
                    </v-row>
                  </v-btn>
                  <v-col cols="2" />
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
          <v-dialog
            v-model="dialog"
            class="inples__card"
            :close-on-back="false"
            persistent>
            <v-card class="pa-5">
                <v-card-text>
                <p class="inples__label">Your copyright work data has been sent to the blockchain for recording. Once the recording is done, we will send a message and certificate of registration of the copyright work by email.</p>
                </v-card-text>
                <v-card-actions>
                <v-spacer />
                <v-btn
                    color="#171776"
                    class="inples__btn white--text"
                    @click="goToApp">Go to the app</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-col>
      <v-col
        v-else-if="selectedItem === 'Check up'"
        style="height: calc(100vh);"
        :cols="(isShowDrawer && isShowNotificationsDrawer) ? 6 : (isShowDrawer || isShowNotificationsDrawer) ? 9 : 12"
        :class="{'blured_content': isShowNotificationsDrawer}">
        <v-container
          ref="draftsContainer"
          style="height: calc(100vh - 175px) !important; overflow-y: scroll;"
          class="inples__tab__content">
          <v-container>
            <v-expand-transition>
              <v-row class="px-10">
                <v-text-field
                  height="60"
                  v-model="cert"
                  placeholder="Enter certificate number"
                  rounded
                  filled
                  background-color="rgba(255, 255, 255, 0.7)"
                  class="inples__field inples__field__rounded" />
                <v-btn
                  width="252"
                  height="60"
                  variant="text"
                  color="#171776"
                  class="text-capitalize inples__btn white--text ml-9"
                  :disabled="!cert.length"
                  @click="checkUpNow">Check up now</v-btn>
              </v-row>
            </v-expand-transition>
            <v-expand-transition>
              <v-row
                v-if="checkUpError"
                justify="center"
                align="center">
                <v-img
                  width="64"
                  height="64"
                  max-width="64"
                  max-height="64"
                  src="../assets/check_up_fail.svg"
                  contain
                  class="clickable"  />
                <p class="font-weight-bold ml-5 clickable text-h5">Oops, the document with such certificate does not seem to exist</p>
              </v-row>
              <v-container
                v-else-if="!checkUpError && certs.length"
                style="height: vh80;">
                <v-col
                  class="mx-0 my-1 px-0"
                  sm="12"
                  md="12"
                  lg="12">
                  <v-toolbar
                    color="#FFFFFF00"
                    height="80"
                    elevation="0"
                    class="inples__settings__payment-method">
                    <v-col class="mx-0 px-0"
                      cols="2">
                      <v-btn
                        elevation="0"
                        color="transparent"
                        class="font-weight-bold inples__table__item">Name</v-btn>
                    </v-col>
                    <v-col class="mx-0 px-0"
                      cols="2">
                      <v-btn elevation="0"
                        color="transparent"
                        class="font-weight-bold inples__table__item">Date</v-btn>
                    </v-col>
                    <v-col class="mx-0 px-0"
                      cols="2">
                      <v-btn elevation="0"
                        color="transparent"
                        class="font-weight-bold inples__table__item">Type</v-btn>
                    </v-col>
                  </v-toolbar>
                </v-col>
                <v-col
                  v-for="(timestamp, index) in certs"
                  :key="index"
                  class="mx-0 my-1 px-0 h-100"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12">
                  <v-toolbar
                    color="#FFFFFF44"
                    height="80"
                    elevation="0"
                    class="inples__settings__payment-method inples__timestamp clickable"
                    @click="openTimestamp(timestamp)">
                    <v-col
                      class="mx-0 px-0 overflow-hidden"
                      cols="2">
                      <v-btn
                        elevation="0"
                        color="transparent"
                        class="font-weight-bold inples__table__item">{{getTimestampName(timestamp)}}</v-btn>
                    </v-col>
                    <v-col
                      class="mx-0 px-0 overflow-hidden"
                      cols="2">
                      <v-btn elevation="0"
                        color="transparent"
                        class="font-weight-bold inples__table__item">{{getTimestampDate(timestamp)}}</v-btn>
                    </v-col>
                    <v-col class="mx-0 px-0"
                      cols="2">
                      <v-btn
                        elevation="0"
                        color="transparent"
                        class="font-weight-bold inples__table__item">{{getTimestampType(timestamp)}}</v-btn>
                    </v-col>
                    <v-spacer />
                    <v-menu>
                      <template v-slot:activator="{on, attrs}">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click.native="null">
                          <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                      </template>
                      <v-list width="300">
                        <v-list-item @click="examineTimestamp(index)">
                          <v-list-item-title>Examine</v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>Fine tune</v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>Add content</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="exportContentTimestamp(index)">
                          <v-list-item-title>Export content</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="certTimestamp(index)">
                          <v-list-item-title>Certificate</v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>Transfer rights</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="deleteTimestamp(index)">
                          <v-list-item-title>Delete</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-toolbar>
                </v-col>
              </v-container>
            </v-expand-transition>
          </v-container>
        </v-container>
      </v-col>
      <v-col
        v-else-if="selectedItem === 'Anti-piracy'"
        style="height: calc(100vh);"
        :cols="(isShowDrawer && isShowNotificationsDrawer) ? 6 : (isShowDrawer || isShowNotificationsDrawer) ? 9 : 12"
        :class="{'blured_content': isShowNotificationsDrawer}">
        <v-container style="height: 100%;">
          <v-row
            style="height: 100%;"
            justify="center"
            align="center">
            <v-container v-if="!antiPiracyStatus">
              <v-row justify="center">
                <v-img
                  class="delete-project"
                  src="../assets/anti_piracy_join_waitlist.svg"
                  contain
                  width="66.67"
                  height="80" />
              </v-row>
              <v-row justify="center">
                <v-card
                  :style="{background: 'linear-gradient(0deg, #02C6A233, #19A4FB33)'}"
                  max-width="528"
                  max-height="605"
                  color="transparent"
                  elevation="0"
                  class="inples__card d-flex flex-column align-center">
                  <template #append>
                    <v-icon>mdi-close</v-icon>
                  </template>
                  <v-card-title class="headline inples__card__title pa-13">
                    <span class="text-center text-h5 font-weight-medium">We’re working hard to bring you Anti-piracy as soon as possible.</span>
                  </v-card-title>
                  <v-card-text class="px-5">
                    <div>
                      <div>
                        <div>
                          <div>
                            <p class="text-center font-weight-regular black--text text-h6">Meanwhile you can join the waitlist.<br />We’ll send you a notification once<br />it’s up & running.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                  <v-card-actions class="ma-0">
                    <v-col>
                      <v-btn
                        width="284"
                        height="60"
                        :color="isHovering[0] ? '#4087ED' : '#171776'"
                        class="white--text inples__btn inples__btn__upload text-h6 font-weight-regular"
                        style="text-transform: unset !important;"
                        elevation="0"
                        @mouseover="setHover(0)"
                        @mouseout="setHout(0)"
                        @click.prevent="joinWaitList">Join waitlist</v-btn>
                    </v-col>
                  </v-card-actions>
                </v-card>
              </v-row>
            </v-container>
            <v-container v-else>
              <v-row justify="center">
                <v-img
                  class="delete-project"
                  src="../assets/ts_delete_success_status.svg"
                  contain
                  width="66.67"
                  height="80" />
              </v-row>
              <v-row justify="center">
                <v-card
                  :style="{background: 'linear-gradient(0deg, #02C6A233, #19A4FB33)'}"
                  max-width="528"
                  max-height="296"
                  color="transparent"
                  elevation="0"
                  class="inples__card d-flex flex-column align-center">
                  <template #append>
                    <v-icon>mdi-close</v-icon>
                  </template>
                  <v-card-title class="headline inples__card__title pa-13">
                    <span class="text-center text-h5 font-weight-regular">Great!<br />We’ll let you when it’s all done.</span>
                  </v-card-title>
                  <v-card-actions class="ma-0">
                    <v-col>
                      <v-btn
                        width="284"
                        height="60"
                        :color="isHovering[0] ? '#4087ED' : '#171776'"
                        class="white--text inples__btn inples__btn__upload text-h6 font-weight-regular"
                        style="text-transform: unset !important;"
                        elevation="0"
                        @mouseover="setHover(0)"
                        @mouseout="setHout(0)"
                        @click.prevent="select('Dashboard')">Return home</v-btn>
                    </v-col>
                  </v-card-actions>
                </v-card>
              </v-row>
            </v-container>
          </v-row>
        </v-container>
      </v-col>
      <v-col
        v-if="isShowDrawer"
        class="ma-0 pa-0"
        cols="12"
        md="3"
        sm="12">
        <v-navigation-drawer
          v-if="isShowNotificationsDrawer"
          :border="0"
          width="100%"
          theme="dark"
          :class="{'pa-5 ma-0 d-flex align-center overflow-hidden': true, 'inples__drawer': isShowDrawer && $vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'xs'}"
          permanent
          :elevation="1"
          style="height: 100% !important; display: flex; justify-content: center; background-color: rgba(255, 255, 255, 0.5); border: 0px solid transparent !important; outline: 0px !important;">
          <v-list v-if="auxDrawer === 'notifications'">
            <v-list-item style="min-width: 100%;">
              <v-row
                style="min-width: 100%;"
                justify="center"
                align="center">
                <v-btn
                  elevation="0"
                  color="transparent"
                  @click="checkNotifications">
                  <v-row
                    no-gutters
                    align="center">
                    <v-icon
                      class="ma-0 pa-0"
                      color="#171776">mdi-eraser</v-icon>
                    <p class="ml-2 my-0 pa-0 inples__label">Clear all</p>
                  </v-row>
                </v-btn>
                <v-spacer />
                <v-btn
                  icon
                  @click="isShowNotificationsDrawer = false">
                  <v-icon color="#171776">mdi-close</v-icon>
                </v-btn>
              </v-row>
            </v-list-item>
            <v-container v-if="notifications.length">
              <v-list-item
                v-for="(notification, i) in getNewNotifications"
                :key="i"
                class="justify-center ma-0"
                title="Settings"
                value="Settings">
                <v-col>
                  <p class="inples__label">{{notification.text}}</p>
                  <v-btn
                    :outlined="notification.status === 'NEW'"
                    elevation="0"
                    height="40"
                    width="94"
                    style="text-transform: unset !important;"
                    rounded
                    :class="{'white--text': notification.status !== 'NEW'}"
                    color="#171776"
                    @click="checkNotification(i)">View</v-btn>
                </v-col>
              </v-list-item>
            </v-container>
            <p
              v-else
              class="inples__label my-5">Notifications is empty</p>
          </v-list>
          <v-list v-else-if="auxDrawer === 'hints'">
            <v-row no-gutters>
              <v-col>
                <p class="text-h5">{{hintTitle}}</p>
              </v-col>
              <v-spacer />
              <v-btn
                icon
                color="#171776"
                @click="isShowNotificationsDrawer = false">
                <v-icon size="24">mdi-close-circle</v-icon>
              </v-btn>
            </v-row>
            <p>{{hintContent}}</p>
          </v-list>
        </v-navigation-drawer>
      </v-col>
    </v-layout>
    <PersonalAreaMiddleware />
  </v-card>
</template>

<script>
  import Vue, { ref } from 'vue'
  import axios from 'axios'
  import { mapState, mapMutations } from 'vuex'
  import PersonalAreaMiddleware from '@/components/middlewares/PersonalAreaMiddleware.vue'
  import { API_DOMAIN, BYTES_IN_GB } from '../utils/consts'
  import moment from 'moment'
  import numbro from 'numbro'
  // import Multiselect from 'vue-multiselect'

  var licenseTimeout = []

  export default Vue.extend({
    name: 'HomePageView',
    setup () {
      const search = ref('')
      const theme = ref(true)
      const isShowDrawer = ref(false)
      const selectedItem = ref('Dashboard')
      const timestampViewMode = ref('list')
      const isExpand = ref(false)
      const isExpandTimestamp = ref(false)
      const isExpandAntiPiracy = ref(false)
      const timestamps = ref([])
      const isShowDetails = ref([])
      const archiveNames = ref([])
      const drafts = ref([])
      const rawDrafts = ref([])
      const isDisableNext = ref(true)
      const addNewCursor = ref(0)
      const addNewProgress = ref(25)
      const file = ref('')
      const hash = ref('')
      const files = ref([])
      const moreFiles = ref([])
      const progress = ref(25)
      const isHovering = ref([false])
      const firstName = ref('')
      const firstNameVal = ref('')
      const lastName = ref('')
      const lastNameVal = ref('')
      const nameRules = ref([
        (v) => !!v || 'Name is required',
        (v) => v.length || 'Name field may not be blank.',
      ])
      const nationalId = ref('')
      const nationalIdVal = ref('')
      const nationalIdRules = ref([
        (v) => !!v || 'National id is required'
      ])
      const country = ref('')
      const countryVal = ref('')
      const countryRules = ref([
        (v) => !!v || 'Country is required'
      ])
      const address = ref('')
      const addressVal = ref('')
      const email = ref('')
      const emailVal = ref('')
      const addressRules = ref([
        (v) => !!v || 'Address is required'
      ])
      const emailRules = ref([
        (v) => !!v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) || 'Email is required'
      ])
      const workTitle = ref('')
      const workTitleRules = ref([
        (v) => !!v || 'Work title is required'
      ])
      const workDesc = ref('')
      const workDescVal = ref('')
      const year = ref('')
      const yearVal = ref('')
      const yearRules = ref([
        (v) => Number.isInteger(Number(v)) || 'Year is required',
      ])
      const workType = ref('')
      const workTypeVal = ref('')
      const workTypeRules = ref([
        (v) => !!v || 'Work type is required'
      ])
      const typeDisplay = ref([])
      const typeValues = ref([])
      const workTypeItems = ref([])
      const workPublished = ref('')
      const workPublishedVal = ref('')
      const workPublishedItems = ref([])
      const workPublishedRules = ref([
        (v) => true || 'Work published is required'
      ])
      const publishedDisplay = ref([])
      const publishedValues = ref([])
      const licenseValues = ref([])
      const licenseDisplay = ref([])
      const licenseItems = ref([])
      const licenseLinks = ref([
        'https://www.gnu.org/licenses/gpl-3.0.txt',
        'https://www.gnu.org/licenses/lgpl-3.0.txt',
        'https://www.gnu.org/licenses/fdl-1.3.txt',
        'https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32017D0863&from=en',
        'https://www.gnu.org/licenses/gpl-3.0.txt',
      ])
      const author = ref('')
      const authorVal = ref('')
      const authorDetails = ref('')
      const fullName = ref('')
      const fullNameVal = ref('')
      const born = ref('')
      const bornRules = ref([
        (v) => !!v || 'Date of birth is required'
      ])
      const nationality = ref('')
      const nationalityRules = ref([
        (v) => !!v || 'Nationality is required'
      ])
      const residentialAddress = ref('')
      const residentialAddressVal = ref('')
      const phone = ref('')
      const phoneVal = ref('')
      const phoneRules = ref([
        (v) => !!v || 'Phone is required',
        (v) => Number.isInteger(Number(v))  || 'Phone is required'
      ])
      const authorEmail = ref('')
      const authorEmailVal = ref('')
      const pseudonym = ref('')
      const pseudonymVal = ref('')
      const pseudonymRules = ref([
        (v) => true || 'Pseudonym is required'
      ])
      const pseudonymPublish = ref('')
      const pseudonymPublishVal = ref('')
      const pseudonymPublishRules = ref([
        (v) => !!v || 'Pseudonym publish is required'
      ])
      const holder = ref('')
      const holderVal = ref('')
      const holderRules = ref([
        (v) => true || 'Holder is required'
      ])
      const attitude = ref('')
      const attitudeVal = ref('')
      const attitudeItems = ref([])
      const attitudeDisplay = ref([])
      const attitudeValues = ref([])
      const authors = ref([])
      const splitRules = ref([
        (v) => Number.isInteger(Number(v))  || 'Value is required'
      ])
      const distribution = ref('100')
      const permitted = ref('')
      const permitDisplay = ref([])
      const credited = ref('')
      const creditedDisplay = ref([])
      const creditedValues = ref([])
      const adaptationsDisplay = ref([])
      const adaptationsValues = ref([])
      const adaptationsPublished = ref('')
      const adaptationsPublishedItems = ref([])
      const isShowTypes = ref(false)
      const proofOfOriginality = ref(false)
      const termsOfUse = ref(false)
      const dragging = ref(false)
      const licensesInUse = ref([])
      const windowHeight = ref('')
      const authorItems = ref([])
      const authoredByDisplay = ref([])
      const authoredByValues = ref([])
      const licenses = ref([])
      const creditedItems = ref([])
      const creditedRules = ref([])
      const commercial = ref('')
      const commercialItems = ref([])
      const commercialDisplay = ref([])
      const commercialValues = ref([])
      const commercialRules = ref([
        (v) => !!v || 'Commercial is required'
      ])
      const pseudonymPublishItems = ref([
        'Yes',
        'No'
      ])
      const isShowLicenses = ref(true)
      const nationalities = ref(`Afghan
Albanian
Algerian
American
Andorran
Angolan
Anguillan
Citizen of Antigua and Barbuda
Argentine
Armenian
Australian
Austrian
Azerbaijani
Bahamian
Bahraini
Bangladeshi
Barbadian
Belarusian
Belgian
Belizean
Beninese
Bermudian
Bhutanese
Bolivian
Citizen of Bosnia and Herzegovina
Botswanan
Brazilian
British
British Virgin Islander
Bruneian
Bulgarian
Burkinan
Burmese
Burundian
Cambodian
Cameroonian
Canadian
Cape Verdean
Cayman Islander
Central African
Chadian
Chilean
Chinese
Colombian
Comoran
Congolese (Congo)
Congolese (DRC)
Cook Islander
Costa Rican
Croatian
Cuban
Cymraes
Cymro
Cypriot
Czech
Danish
Djiboutian
Dominican
Citizen of the Dominican Republic
Dutch
East Timorese
Ecuadorean
Egyptian
Emirati
English
Equatorial Guinean
Eritrean
Estonian
Ethiopian
Faroese
Fijian
Filipino
Finnish
French
Gabonese
Gambian
Georgian
German
Ghanaian
Gibraltarian
Greek
Greenlandic
Grenadian
Guamanian
Guatemalan
Citizen of Guinea-Bissau
Guinean
Guyanese
Haitian
Honduran
Hong Konger
Hungarian
Icelandic
Indian
Indonesian
Iranian
Iraqi
Irish
Israeli
Italian
Ivorian
Jamaican
Japanese
Jordanian
Kazakh
Kenyan
Kittitian
Citizen of Kiribati
Kosovan
Kuwaiti
Kyrgyz
Lao
Latvian
Lebanese
Liberian
Libyan
Liechtenstein citizen
Lithuanian
Luxembourger
Macanese
Macedonian
Malagasy
Malawian
Malaysian
Maldivian
Malian
Maltese
Marshallese
Martiniquais
Mauritanian
Mauritian
Mexican
Micronesian
Moldovan
Monegasque
Mongolian
Montenegrin
Montserratian
Moroccan
Mosotho
Mozambican
Namibian
Nauruan
Nepalese
New Zealander
Nicaraguan
Nigerian
Nigerien
Niuean
North Korean
Northern Irish
Norwegian
Omani
Pakistani
Palauan
Palestinian
Panamanian
Papua New Guinean
Paraguayan
Peruvian
Pitcairn Islander
Polish
Portuguese
Prydeinig
Puerto Rican
Qatari
Romanian
Russian
Rwandan
Salvadorean
Sammarinese
Samoan
Sao Tomean
Saudi Arabian
Scottish
Senegalese
Serbian
Citizen of Seychelles
Sierra Leonean
Singaporean
Slovak
Slovenian
Solomon Islander
Somali
South African
South Korean
South Sudanese
Spanish
Sri Lankan
St Helenian
St Lucian
Stateless
Sudanese
Surinamese
Swazi
Swedish
Swiss
Syrian
Taiwanese
Tajik
Tanzanian
Thai
Togolese
Tongan
Trinidadian
Tristanian
Tunisian
Turkish
Turkmen
Turks and Caicos Islander
Tuvaluan
Ugandan
Ukrainian
Uruguayan
Uzbek
Vatican citizen
Citizen of Vanuatu
Venezuelan
Vietnamese
Vincentian
Wallisian
Welsh
Yemeni
Zambian
Zimbabwean`
  .split('\n'))
      const timestampsCount = ref(0)
      const memoryUsed = ref(0)
      const maxTimestampsCount = ref(0)
      const maxMemoryUsed = ref(0)
      const plan = ref('')
      const fileToken = ref('')
      const hasShowNotification = ref(false)
      const notificationContent = ref('')
      const isShowDatePicker = ref(false)
      const mode = ref('choose')
      const isSearchFocus = ref(false)
      const isHaveNotifications = ref(false)
      const isShowNotificationsDrawer = ref(false)
      const notifications = ref([])
      const isDisableSendFiles = ref(false)
      const cert = ref('')
      const checkUpError = ref(null)
      const antiPiracyStatus = ref(null)
      const filter = 'Filter by date'
      const filters = ref([
        'Filter by date',
        'Filter by time',
      ])
      const certs = ref([])
      const isShowHintDrawer = ref(false)
      const auxDrawer = ref('')
      const hintTitle = ref('')
      const hintContent = ref('')
      const dialog = ref(false)
      const isHavePhoto = ref(false)
      const photo = ref('@/assets/default_avatar.png')
      return {
        search,
        theme,
        isShowDrawer,
        selectedItem,
        timestampViewMode,
        isExpand,
        isExpandTimestamp,
        isExpandAntiPiracy,
        timestamps,
        isShowDetails,
        archiveNames,
        drafts,
        rawDrafts,
        isDisableNext,
        addNewCursor,
        addNewProgress,
        file,
        hash,
        files,
        moreFiles,
        progress,
        isHovering,
        firstName,
        firstNameVal,
        lastName,
        lastNameVal,
        nameRules,
        nationalId,
        nationalIdVal,
        nationalIdRules,
        country,
        countryVal,
        countryRules,
        address,
        addressVal,
        addressRules,
        email,
        emailVal,
        emailRules,
        workTitle,
        workTitleRules,
        workDesc,
        workDescVal,
        year,
        yearVal,
        yearRules,
        workType,
        workTypeVal,
        workTypeRules,
        workPublished,
        workPublishedVal,
        workPublishedItems,
        workPublishedRules,
        author,
        authorVal,
        authorDetails,
        fullName,
        fullNameVal,
        born,
        bornRules,
        nationality,
        nationalityRules,
        residentialAddress,
        residentialAddressVal,
        phone,
        phoneVal,
        authorEmail,
        authorEmailVal,
        phoneRules,
        pseudonym,
        pseudonymVal,
        pseudonymRules,
        pseudonymPublish,
        pseudonymPublishVal,
        pseudonymPublishRules,
        holder,
        holderVal,
        holderRules,
        attitude,
        attitudeVal,
        attitudeItems,
        attitudeValues,
        attitudeDisplay,
        authors,
        splitRules,
        permitted,
        permitDisplay,
        credited,
        creditedDisplay,
        creditedValues,
        adaptationsDisplay,
        adaptationsValues,
        adaptationsPublished,
        adaptationsPublishedItems,
        isShowTypes,
        proofOfOriginality,
        termsOfUse,
        dragging,
        licensesInUse,
        windowHeight,
        authorItems,
        authoredByDisplay,
        authoredByValues,
        licenses,
        creditedItems,
        creditedRules,
        commercial,
        commercialItems,
        commercialDisplay,
        commercialValues,
        commercialRules,
        pseudonymPublishItems,
        isShowLicenses,
        nationalities,
        timestampsCount,
        memoryUsed,
        maxTimestampsCount,
        maxMemoryUsed,
        plan,
        fileToken,
        typeDisplay,
        typeValues,
        workTypeItems,
        publishedDisplay,
        publishedValues,
        licenseValues,
        licenseDisplay,
        licenseItems,
        licenseLinks,
        distribution,
        hasShowNotification,
        notificationContent,
        isShowDatePicker,
        mode,
        isSearchFocus,
        isHaveNotifications,
        isShowNotificationsDrawer,
        notifications,
        isDisableSendFiles,
        cert,
        checkUpError,
        antiPiracyStatus,
        filter,
        filters,
        certs,
        isShowHintDrawer,
        auxDrawer,
        hintTitle,
        hintContent,
        dialog,
        isHavePhoto,
        photo
      }
    },
    computed: {
      ...mapState([
        'token',
        'user'
      ]),
      getNewNotifications () {
        return this.notifications.filter(notification => {
          const status = notification.status
          const isNew = status === 'NEW'
          return isNew
        })
      },
      getCurrentYear () {
        return moment().format('YYYY')
      },
      userName () {
        return `${this.user.first_name} ${this.user.last_name}`
      },
      isCreateTimestampEnabled () {
        const isFirstStep = this.termsOfUse && this.proofOfOriginality && this.permitted.length && this.permitted !== 'Permission is granted to use the work free of charge under the conditions described in the licence:'
        const isSecondStep = this.termsOfUse && this.proofOfOriginality && this.permitted.length && this.permitted === 'Permission is granted to use the work free of charge under the conditions described in the licence:' && this.credited.length && this.adaptationsPublished.length && this.commercial.length
        return isFirstStep || isSecondStep
      },
      isHaveAuthors () {
        return this.authors.length > 0 || !this.isGroupAuthors
      },
      isGroupAuthors () {
        const groupDisplay = this.authorItems[1]
        const result = this.author === groupDisplay
        return result
      },
      dashboardLabel () {
        return 'Dashboard'
      }
    },
    watch: {
      selectedItem (val) {
        if (this.$store.state.lastScroll) {
          this.loadScroll(val)
        }
      }
    },
    mounted () {
      // define a language
      numbro.registerLanguage({
        languageTag: "en-US",
          delimiters: {
              thousands: " ",
              decimal: ","
          },
          abbreviations: {
              thousand: "k",
              million: "m",
              billion: "b",
              trillion: "t"
          },
          ordinal: (number) => {
              return number === 1 ? "er" : "ème";
          },
          currency: {
              symbol: "€",
              position: "postfix",
              code: "EUR"
          },
          currencyFormat: {
              thousandSeparated: true,
              totalLength: 4,
              spaceSeparated: true,
              average: true
          },
          formats: {
              fourDigits: {
                  totalLength: 4,
                  spaceSeparated: true,
                  average: true
              },
              fullWithTwoDecimals: {
                  output: "currency",
                  mantissa: 2,
                  spaceSeparated: true,
                  thousandSeparated: true
              },
              fullWithTwoDecimalsNoCurrency: {
                  mantissa: 2,
                  thousandSeparated: true
              },
              fullWithNoDecimals: {
                  output: "currency",
                  spaceSeparated: true,
                  thousandSeparated: true,
                  mantissa: 0
              }
          }
      })
      // define a language
      numbro.registerLanguage({
        languageTag: "ru-RU",
          delimiters: {
              thousands: " ",
              decimal: ","
          },
          abbreviations: {
              thousand: "k",
              million: "m",
              billion: "b",
              trillion: "t"
          },
          ordinal: (number) => {
              return number === 1 ? "er" : "ème";
          },
          currency: {
              symbol: "€",
              position: "postfix",
              code: "EUR"
          },
          currencyFormat: {
              thousandSeparated: true,
              totalLength: 4,
              spaceSeparated: true,
              average: true
          },
          formats: {
              fourDigits: {
                  totalLength: 4,
                  spaceSeparated: true,
                  average: true
              },
              fullWithTwoDecimals: {
                  output: "currency",
                  mantissa: 2,
                  spaceSeparated: true,
                  thousandSeparated: true
              },
              fullWithTwoDecimalsNoCurrency: {
                  mantissa: 2,
                  thousandSeparated: true
              },
              fullWithNoDecimals: {
                  output: "currency",
                  spaceSeparated: true,
                  thousandSeparated: true,
                  mantissa: 0
              }
          }
      })

      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
        if (this.$store.state.lastTab) {
          this.select(this.$store.state.lastTab)
        }
      })
      this.isShowDrawer = this.$vuetify.breakpoint.name !== 'xs' && this.$vuetify.breakpoint.name !== 'sm'
      if (this.token) {
        axios({
          url: `${API_DOMAIN}/api/users/`,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
          .then((response) => {
            console.log(response)
            const data = response.data
            const user = data[0]
            this.SET_USER({ user })
            this.firstName = user.first_name
            this.lastName = user.last_name
            this.nationalId = user.doc_id
            this.country = user.country
            this.address = user.address
            this.email = user.email
            this.pseudonym = user.pseudonym
            this.isHavePhoto = !!user.profile_photo
            this.photo = `${API_DOMAIN}${user.profile_photo}`
            axios({
              url: `${API_DOMAIN}/api/stamps/`,
              headers: {
                'Authorization': `Bearer ${this.token}`
              }
            })
              .then((response) => {
                console.log(response)
                const timestamps = response.data
                const filteredTimestamps = this.getFilteredTimsetamps(timestamps)
                this.timestamps = filteredTimestamps
                axios({
                  url: `${API_DOMAIN}/api/files/?drafts=true`,
                  headers: {
                    'Authorization': `Bearer ${this.token}`
                  }
                })
                  .then((response) => {
                    console.log(response)
                    const data = response.data
                    this.rawDrafts = data
                    for (const dataItem of data) {
                      const token = dataItem.file_token
                      const names = dataItem.filenames
                      this.archiveNames.push(token)
                      this.drafts.push(names)
                      // this.rawDrafts.push(dataItem)
                      this.isShowDetails.push(false)
                    }
                    this.$forceUpdate()
                    this.isDisableNext = !this.archiveNames.length
                    axios({
                      method: 'get',
                      url: `${API_DOMAIN}/api/rids/rid-choices/`,
                      headers: {
                        'Authorization': `Bearer ${this.token}`
                      }
                    })
                      .then((response) => {
                        console.log(response)
                        const { data } = response
                        const authoredBy = data.authored_by
                        const authoredByDisplay = authoredBy.map(e => e.display)
                        const authoredByValues = authoredBy.map(e => e.value)
                        this.authorItems = authoredByDisplay
                        this.authoredByDisplay = authoredByDisplay
                        this.authoredByValues = authoredByValues
                        const isPublished = data.is_published
                        const isPublishedDisplay = isPublished.map(e => e.display)
                        this.workPublishedItems = isPublishedDisplay
                        const types = data.type
                        const typesDisplay = types.map(e => e.display)
                        this.workTypeItems = typesDisplay
                        const typesValues = types.map(e => e.value)
                        this.typeDisplay = typesDisplay
                        this.typeValues = typesValues
                        const credited = data.credited
                        const creditedDisplay = credited.map(e => e.display)
                        const creditedValues = credited.map(e => e.value)
                        this.creditedDisplay = creditedDisplay
                        this.creditedValues = creditedValues
                        this.creditedItems = creditedDisplay
                        const permit = data.permit
                        const permitValues = permit.map(e => e.value)
                        const permitDisplay = permit.map(e => e.display)
                        this.permittedItems = permit
                        this.permitDisplay = permitDisplay
                        this.permitValues = permitValues
                        const commercial = data.commercial
                        const commercialDisplay = commercial.map(e => e.display)
                        const commercialValues = commercial.map(e => e.value)
                        this.commercialDisplay = commercialDisplay
                        this.commercialValues = commercialValues
                        this.commercialItems = commercialDisplay
                        const adaptations = data.adaptations
                        const adaptationsDisplay = adaptations.map(e => e.display)
                        this.adaptationsDisplay = adaptationsDisplay
                        const adaptationsValues = adaptations.map(e => e.value)
                        this.adaptationsValues = adaptationsValues
                        this.adaptationsPublishedItems = adaptationsDisplay
                        const attitude = data.attitude
                        const attitudeValues = attitude.map(e => e.value)
                        const attitudeDisplay = attitude.map(e => e.display)
                        this.attitudeItems = attitudeDisplay
                        this.attitudeValues = attitudeValues
                        this.attitudeDisplay = attitudeDisplay
                        const license = data.license
                        const licenseValues = license.map(e => e.value)
                        const licenseDisplay = license.map(e => e.display)
                        this.licenseValues = licenseValues
                        this.licenseDisplay = licenseDisplay
                        this.licenseItems = licenseDisplay
                        axios({
                          method: 'get',
                          url: `${API_DOMAIN}/api/stats/`,
                          headers: {
                            'Authorization': `Bearer ${this.token}`
                          }
                        })
                          .then((response) => {
                            console.log(response)
                            this.timestampsCount = response.data.usage.rights_registration
                            this.memoryUsed = response.data.usage.protected_storage_volume
                            this.maxTimestampsCount = response.data.limits.rights_registration
                            this.maxMemoryUsed = response.data.limits.protected_storage_volume
                            this.plan = response.data.limits.name
                            axios({
                              method: 'get',
                              url: `${API_DOMAIN}/api/notifications/`,
                              headers: {
                                'Authorization': `Bearer ${this.token}`
                              }
                            })
                              .then((response) => {
                                console.log(response)
                                const data = response.data
                                this.isHaveNotifications = data.length
                                this.notifications = data
                                axios({
                                  url: `${API_DOMAIN}/api/waitlists/`,
                                  headers: {
                                    'Authorization': `Bearer ${this.token}`,
                                    'Content-Type': 'application/json',
                                  },
                                })
                                  .then((response) => {
                                    console.log(response)
                                    if (response.data.length)
                                      this.antiPiracyStatus = 'JOINED_TO_WAITLIST'
                                  })
                                  .catch((error) => {
                                    // handle error
                                    const response = error.response
                                    console.log(response)
                                  })
                              })
                              .catch((response) => {
                                console.log(response)
                              })
                          })
                          .catch((response) => {
                            console.log(response)
                          })
                      })
                      .catch((response) => {
                        console.log(response)
                      })
                  })
                  .catch((response) => {
                    console.log(response)
                  })

              })
              .catch((response) => {
                console.log(response)
              })
          })
          .catch((response) => {
            console.log(response)
          })
      }
    },
    beforeDestroy() { 
      window.removeEventListener('resize', this.onResize); 
    },
    filters: {
      formatBytesInt (value) {
        if (value === 0) return '0 Bytes'
        const k = 1024
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        const i = Math.floor(Math.log(value) / Math.log(k))
        return parseFloat((value / Math.pow(k, i)).toFixed(0)) + ' ' + sizes[i]
      }
    },
    methods: {
      ...mapMutations([
        'SET_USER',
        'SET_TIMESTAMP',
        'SET_LAST_TAB',
        'SET_LAST_SCROLL',
      ]),
      hideNotificationsBar () {
          if (this.isShowNotificationsDrawer) {
            this.isShowNotificationsDrawer = false
          }
      },
      openLink (url) {
        window.open(url, '_blank')
      },
      goToApp () {
        this.addNewCursor = 0
        this.addNewProgress = 25
        axios({
          url: `${API_DOMAIN}/api/stamps/`,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
          .then((response) => {
            console.log(response)
            const timestamps = response.data
            const filteredTimestamps = this.getFilteredTimsetamps(timestamps)
            this.timestamps = filteredTimestamps
            this.select('Dashboard')
            this.dialog = false
          })
          .catch((error) => {
            // handle error
            const response = error.response
            console.log(response)
          })
      },
      openHint (hint) {
        setTimeout(() => {
          this.isShowNotificationsDrawer = true
          this.auxDrawer = 'hints'
          let hintTitle = ''
          let hintContent = ''
          if (hint === 'Creative pseudonym') {
            hintTitle = hint
            hintContent = hint
          } else if (hint === 'Attitude of the applicant/owner towards the author') {
            hintTitle = hint
            hintContent = hint
          }
          this.hintTitle = hintTitle
          this.hintContent = hintContent
        }, 100)
      },
      checkUpNow () {
        axios({
          url: `${API_DOMAIN}/api/stamps/search-by-id/?q=${this.cert}`,
          headers: {
            'Authorization': `Bearer ${this.token}`
          },
        })
          .then((response) => {
            console.log(response)
            if (!response.data.length) {
              this.checkUpError = 'Cert is not found'
            } else {
              this.checkUpError = null
              this.certs = response.data
              if (this.certs.length) {
                this.openTimestamp(this.certs[0])
              }
            }
          })
          .catch((error) => {
            // handle error
            const response = error.response
            console.log(response)
          })
      },
      selectWorkType (type) {
        this.workType = type
        this.isShowTypes = false
        this.isHovering = []
      },
      joinWaitList () {
        axios({
          url: `${API_DOMAIN}/api/waitlists/`,
          headers: {
            'Authorization': `Bearer ${this.token}`
          },
          method: 'post',
          data: {
            email: this.user.email
          }
        })
          .then((response) => {
            console.log(response)
            this.antiPiracyStatus = 'JOINED_TO_WAITLIST'
          })
          .catch((error) => {
            // handle error
            const response = error.response
            console.log(response)
          })
      },
      checkUp () {
        this.select('Check up')
      },
      async checkNotifications () {
        await Promise.all(
          this.notifications.map((e, i) => this.checkNotification(i))
        )
        this.notifications = []
      },
      checkNotification (i) {
        axios({
          method: 'post',
          url: `${API_DOMAIN}/api/notifications/${this.notifications[i].id}/mark_as_read/`,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
          }
        })
          .then((response) => {
            this.notifications[i].status = 'CHECKED'
            this.$forceUpdate()
          })
          .catch((error) => {
            // handle error
            const response = error.response
            console.log(response)
          })
      },
      viewNotifications () {
        this.isHaveNotifications = false
        this.isShowNotificationsDrawer = !this.isShowNotificationsDrawer
        this.auxDrawer = 'notifications'
      },
      getDraftDate (draft) {
        let date = ''
        const uploadedAt = draft.uploaded_at
        if (uploadedAt) {
          date = `${moment(uploadedAt).format('DD.MM.YYYY hh:mm a')} UTM`
        }
        return date
      },
      exportContentTimestamp (index) {
        const timestamp = this.timestamps[index]
        const id = timestamp.id
        this.SET_TIMESTAMP({ timestamp })
        this.$router.push({ name: 'timestamp', query: { id, action: 'export-content' } })
      },
      certTimestamp (index) {
        const timestamp = this.timestamps[index]
        const id = timestamp.id
        this.SET_TIMESTAMP({ timestamp })
        this.$router.push({ name: 'timestamp', query: { id, action: 'cert' } })
      },
      examineTimestamp (index) {
        const timestamp = this.timestamps[index]
        const id = timestamp.id
        this.SET_TIMESTAMP({ timestamp })
        this.$router.push({ name: 'timestamp', query: { id, action: 'examine' } })
      },
      deleteTimestamp (index) {
        const timestamp = this.timestamps[index]
        const id = timestamp.id
        this.SET_TIMESTAMP({ timestamp })
        this.$router.push({ name: 'timestamp', query: { id, action: 'delete' } })
      },
      getFilteredTimsetamps (timestamps) {
        const tsIds = timestamps.map(ts => {
        const rid = ts.rid
          if (rid) {
            const ridId = rid.id
            return ridId
          }
        })
        var unique = tsIds.filter(this.onlyUnique)
        const filteredTimestamps = unique.map(id => {
          const stamps = timestamps.filter(ts => {
            const rid = ts.rid
            if (rid) {
              const ridId = rid.id
              return ridId === id
            }
            return false
          })
          const newStamps = stamps.filter(ts => {
            const status = ts.status
            const isNew = status === 'NEW'
            return isNew
          })
          const sentStamps = stamps.filter(ts => {
            const status = ts.status
            const isSent = status === 'SENT'
            return isSent
          })
          const stampedStamps = stamps.filter(ts => {
            const status = ts.status
            const isStamped = status === 'STAMPED'
            return isStamped
          })
          if (stampedStamps.length) {
            return stampedStamps[0]
          } else if (sentStamps.length) {
            return sentStamps[0]
          } else if (newStamps.length) {
            return newStamps[0]
          }
        })
        return filteredTimestamps
      },
      onlyUnique (value, index, array) {
        return array.indexOf(value) === index;
      },
      deleteDraft (index) {
        const fileToken = this.archiveNames[index]
        const data = {
          file_tokens: [fileToken],
        }
        axios({
          method: 'post',
          url: `${API_DOMAIN}/api/files/delete-files/`,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
          },
          data
        })
          .then((response) => {
            this.archiveNames = this.archiveNames.filter((name, idx) => idx !== index)
            this.drafts = this.drafts.filter((draft, idx) => idx !== index)
            this.isShowDetails = this.isShowDetails.filter((details, idx) => idx !== index)
          })
          .catch((error) => {
            // handle error
            const response = error.response
            console.log(response)
          })
      },
      getTimestampType (timestamp) {
        let type = ''
        const rid = timestamp.rid
        if (rid) {
          type = rid.type
        }
        return type
      },
      getTimestampName (timestamp) {
        let title = ''
        const rid = timestamp.rid
        if (rid) {
          title = rid.title
        }
        return title
      },
      searchRids () {
        this.isSearchFocus = false
        this.select('Timestamp')
        axios({
          url: `${API_DOMAIN}/api/stamps/search/?q=${this.search}`,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
          }
        })
          .then((response) => {
            // handle success
            console.log(response)
            const timestamps = response.data
            const filteredTimestamps = this.getFilteredTimsetamps(timestamps)
            this.timestamps = filteredTimestamps
            this.$forceUpdate()
          })
          .catch((error) => {
            // handle error
            const response = error.response
            console.log(response)
            const data = response.data
            const keys = Object.keys(data)
            const key = keys[0]
            const dataItem = data[key]
            let msg = dataItem[0]
            if (!msg) {
              msg = 'Error'
            }
            this.notificationContent = msg
            this.hasShowNotification = true
          })
      },
      loadScroll (val) {
        setTimeout(() => {
          if (val === 'Drafts')
            this.$refs.draftsContainer.scrollTop = this.$store.state.lastScroll
          else if (val === 'Timestamp')
            this.$refs.timestampsContainer.scrollTop = this.$store.state.lastScroll
        }, 500)
      },
      detectScroll () {
        let scroll = 0
        if (this.selectedItem === 'Drafts')
          scroll = this.$refs.draftsContainer.scrollTop
        else if (this.selectedItem === 'Timestamp')
          scroll = this.$refs.timestampsContainer.scrollTop
        this.SET_LAST_SCROLL({ scroll })
      },
      getTimestampDate (timestamp) {
        let date = ''
        const rid = timestamp.rid
        if (rid) {
          const data = rid.created_at
          if (data) {
            date = `${moment(data).format('DD.MM.YYYY hh:mm a')} UTM`
          }
        }
        return date
      },
      back (val) {
        this.addNewCursor--
        this.addNewProgress = val
        if (val === 25) {
          this.isDisableSendFiles = false
        } else if (val === 38) {
          this.email = this.emailVal
          this.address = this.addressVal
          this.nationalId = this.nationalIdVal
          this.year = this.yearVal
          this.country = this.countryVal
          this.firstName = this.firstNameVal
          this.lastName = this.lastNameVal
        } else if (val === 50) {
          this.workTitle = this.workTitleVal
          this.workType = this.workTypeVal
          this.workPublished = this.workPublishedVal
          this.workDesc = this.workDescVal
          this.year = this.yearVal
        } else if (val === 60) {
          this.author = this.authorVal
          this.pseudonymPublish = this.pseudonymPublishVal
          this.attitude = this.attitudeVal
          this.holder = this.holderVal
          this.pseudonym = this.pseudonymVal
          this.nationality = this.nationalityVal
          this.fullName = this.fullNameVal
          this.phone = this.phoneVal
          this.born = this.bornVal
          this.residentialAddress = this.residentialAddressVal
          this.authorEmail = this.authorEmailVal
        }
      },
      createTimestamp () {
        const json = {}
        json.name = this.fullNameVal
        json.national_id = this.nationalIdVal
        json.nationality = this.nationalityVal
        json.address = this.addressVal
        json.phone = this.phoneVal
        json.email = this.emailVal
        json.title = this.workTitleVal
        if (this.workDescVal.length) {
          json.description = this.workDescVal
        }
        json.year = this.yearVal
        if (this.workTypeVal.length) {
          const index = this.typeDisplay.indexOf(this.workTypeVal)
          const type = this.typeValues[index]
          json.type = type
        }
        if (this.workPublishedVal.length) {
          const index = this.publishedDisplay.indexOf(this.workPublishedVal)
          const isPublished = this.publishedValues[index]
          json.is_published = isPublished
        }
        if (this.authorVal.length) {
          const index = this.authoredByDisplay.indexOf(this.authorVal)
          const authoredBy = this.authoredByValues[index]
          json.authored_by = authoredBy
        }
        if (!this.isGroupAuthors) {
          if (this.fullNameVal.length || this.nationalIdVal.length || this.addressVal.length || this.emailVal.length)
            this.authorDetails = `${this.fullNameVal}`
          if (this.nationalIdVal.length)
            this.authorDetails += ` ${this.nationalIdVal}`
          if (this.addressVal.length)
            this.authorDetails += ` ${this.addressVal}`
          if (this.phoneVal.length)
            this.authorDetails += ` ${this.phoneVal}`
          if (this.emailVal.length)
            this.authorDetails += ` ${this.emailVal}`
          if (!this.fullNameVal.length && !this.nationalIdVal.length && !this.addressVal.length && !this.emailVal.length) {
            this.authorDetails = ''
          }
        }
        json.author_details = this.authorDetails
        json.pseudonym = this.pseudonymVal
        if (this.pseudonymPublishVal.length) {
          json.publish_by_pseudonym = this.pseudonymPublishVal
        }
        json.distribution = this.distribution
        json.right_holder = this.holderVal
        if (this.attitudeVal.length) {
          const index = this.attitudeDisplay.indexOf(this.attitudeVal)
          const attitude = this.attitudeValues[index]
          json.attitude = attitude
        }
        if (this.permitted.length) {
          const index = this.permitDisplay.indexOf(this.permitted)
          const permitted = this.permitValues[index]
          json.permit = permitted
        }
        if (this.credited.length) {
          const index = this.creditedDisplay.indexOf(this.credited)
          const credited = this.creditedValues[index]
          json.credited = credited
        }
        if (this.adaptationsPublished.length) {
          const index = this.adaptationsDisplay.indexOf(this.adaptationsPublished)
          const adaptations = this.adaptationsValues[index]
          json.adaptations = adaptations
        }
        if (this.commercial.length) {
          const index = this.commercialDisplay.indexOf(this.commercial)
          const commercial = this.commercialValues[index]
          json.commercial = commercial
        }
        if (this.licenses.length) {
          const index = this.licenseDisplay.indexOf(this.licenses[0])
          const license = this.licenseValues[index]
          json.license = license
        }
        if (this.proofOfOriginality.length) {
          json.proof = this.proofOfOriginality
        }
        json.file_token = this.fileToken
        axios({
          method: 'post',
          url: `${API_DOMAIN}/api/rids/`,
          data: json,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
          }
        })
          .then((response) => {
            // handle success
            console.log(response)
            // this.select('Timestamp')
            this.dialog = true
          })
          .catch((error) => {
            // handle error
            const response = error.response
            console.log(response)
            const data = response.data
            const keys = Object.keys(data)
            const key = keys[0]
            const dataItem = data[key]
            let msg = dataItem[0]
            if (!msg) {
              msg = 'Error'
            }
            this.notificationContent = msg
            this.hasShowNotification = true
          })
      },
      getPercent (min, max, val) {
        return (100 * (val - min) / (max - min)).toFixed(0)
      },
      openLicense (link) {
        window.open(link)
      },
      closeLicenses () {
        if (this.licenses.length) {
          this.isShowLicenses = false
        }
      },
      toggleLicenses () {
        if (this.licenses.length) {
          this.isShowLicenses = !this.isShowLicenses
        }
      },
      deleteAuthor (index) {
        this.authors = this.authors.filter((val, i) => index !== i)
      },
      checkBrief(val) {
        let form = this.$refs.termsOfUseAndDistributionForm
        if (val === 50) {
          form = this.$refs.detailsOfTheApplicantForm
          this.emailVal = this.email
          this.addressVal = this.address
          this.nationalIdVal = this.nationalId
          this.yearVal = this.year
          this.countryVal = this.country
          this.firstNameVal = this.firstName
          this.lastNameVal = this.lastName
        } else if (val === 60) {
          form = this.$refs.theWorkForm
          this.workTitleVal = this.workTitle
          this.workTypeVal = this.workType
          this.workPublishedVal = this.workPublished
          this.workDescVal = this.workDesc
          this.yearVal = this.year
        } else if (val === 100) {
          form = this.$refs.aboutTheAuthorForm
          this.authorVal = this.author
          this.pseudonymPublishVal = this.pseudonymPublish
          this.attitudeVal = this.attitude
          this.holderVal = this.holder
          this.pseudonymVal = this.pseudonym
          this.nationalityVal = this.nationality
          if (this.authors.length) {
            this.distribution = this.authors.join(',')
          }
          this.fullNameVal = this.fullName
          this.phoneVal = this.phone
          this.bornVal = this.born
          this.residentialAddressVal = this.residentialAddress
          this.authorEmailVal = this.authorEmail
        }
        const isValid = form.validate()
        if (isValid) {
          this.addNewCursor++
          this.addNewProgress = val
          form.reset()
        }
      },
      toggleLicense (val) {
        this.licenses = []
        if (this.licenses.includes(val)) {
          this.licenses = this.licenses.filter(license => license !== val)
        } else {
          this.licenses.push(val)
        }
        licenseTimeout.map(val => clearTimeout(licenseTimeout))
        licenseTimeout = []
        licenseTimeout.push(setTimeout(() => this.closeLicenses(), 1000))
      },
      switchCursor () {
        if (this.name.length && this.nationalId.length && this.nationality.length && this.address.length && this.phone.length && this.email.length && this.cursor < 1)
          this.cursor = 1
        if (this.workTitle.length && this.year > 0 && this.workType.length && this.workPublished.length && this.cursor < 2)
          this.cursor = 2
        if (this.author.length && this.distribution.length && this.cursor < 3)
          this.cursor = 3
        if (this.permitted.length && this.cursor < 4)
          this.cursor = 4
        if (this.licenses.length && this.cursor < 5)
          this.cursor = 5
      },
      onResize() {
        this.windowHeight = window.innerHeight
      },
      evenSplit () {
        for (let i = 0; i < this.authors.length; i++) {
          this.authors[i].split = (100 / this.authors.length).toFixed(2)
        }
      },
      addAuthor () {
        this.authors.push({
          fullName: '',
          born: '',
          nationality: '',
          address: '',
          phone: '',
          email: '',
          pseudonym: '',
          pseudonymPublish: '',
          split: ''
        })
      },
      toggleDraft(index) {
        this.isShowDetails[index] = !this.isShowDetails[index]
        this.$forceUpdate()
      },
      addNew () {
        this.select('Add new')
      },
      openDrafts () {
        this.select('Drafts')
      },
      isShowMobileBtn () {
        console.log(this.$refs.searchRef)
        if (this.$refs.searchRef) {
          return this.$refs.searchRef.isFocused
        }
        return false
      },
      isShowMobileBtn2 () {
        console.log(this.$refs.searchRef)
        if (this.$refs.searchRef2) {
          return this.$refs.searchRef2.isFocused
        }
        return false
      },
      openTimestamp (timestamp) {
        const id = timestamp.id
        this.SET_TIMESTAMP({ timestamp })
        this.$router.push({ name: 'timestamp', query: { id } })
      },
      setViewMode (mode) {
        this.timestampViewMode = mode

      },
      select (val) {
        if (this.selectedItem !== val) {
          this.isExpand = false
        }
        if (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm') {
          this.isShowDrawer = false
        }
        this.selectedItem = val
        this.SET_LAST_TAB({tab: val})
      },
      toggleDrawer () {
        if (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm') {
          this.isShowDrawer = !this.isShowDrawer
        }
      },
      switchTheme () {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      },
      openScreen () {
        this.$router.push({ name: 'settings' })
      },
      async onChange (e) {
        var files = e.target.files || e.dataTransfer.files
        if (!files.length) {
          this.dragging = false
          return
        }
        let totalSize = 0
        for (let i = 0; i < files.length; i++)
          totalSize += files[i].size
        this.notificationContent = ''
        this.hasShowNotification = totalSize > BYTES_IN_GB
        if (this.hasShowNotification) {
          this.notificationContent = 'You cannot upload files larger than 1 GB'
          this.dragging = false
          return
        }
        const isChoose = this.mode === 'choose'
        if (isChoose)
          this.files = []
        this.moreFiles = []
        await this.createFiles(files)
        if (!this.isShowPopup) {
          this.isShowPopup = true
        }
        this.notificationContent = ''
        const isFilesUploaded = await files.length
        this.hasShowNotification = !isFilesUploaded
        if (this.hasShowNotification) {
          this.notificationContent = 'Files not uploaded'
        }
      },
      async createFiles (files) {
        for (let i = 0; i < files.length; i++)
          await this.createFile(files[i])
      },
      calculateSha2 (file) {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader()
          fileReader.readAsArrayBuffer(file)
          fileReader.onload = () => {
            const data = fileReader.result
            window.crypto.subtle.digest('SHA-256', data)
              .then(buffer => {
                const array = Array.from(new Uint8Array(buffer))
                const hash = array.map(b => b.toString(16).padStart(2, '0')).join('')
                resolve(hash)
              })
              .catch(reject)
          }
          fileReader.onerror = reject
        })
      },
      async createFile (file) {
        this.dragging = false
        const hash = await this.calculateSha2(file)
        const rawHash = (hash.toString())
        const fileData = {
          name: file.name,
          size: file.size,
          hash: rawHash,
          data: file,
          progress: 0
        }
        this.files.push(fileData)
        const isMore = this.mode === 'more'
        if (isMore)
          this.moreFiles.push(fileData)
      },
      removeFiles () {
        this.file = ''
        this.files = []
      },
      removeFile (i) {
        this.file = ''
        this.files = this.files.filter((file, index) => index !== i)
      },
      chooseMore () {
        this.mode = 'more'
        this.$refs.inputRef2.click()
      },
      async sendFiles () {
        this.isDisableSendFiles = true
        const isFilesUploaded = await this.files.length
        const isValid = isFilesUploaded
        if (isValid) {
          this.isLoading = true
          let index = -1
          var bodyFormData = new FormData()
          let userFiles = this.files
          for (const file of userFiles) {
            index++
            const hash = file.hash
            bodyFormData.append(`files[${index}]file`, file.data)
            bodyFormData.append(`files[${index}]hash`, hash)
          }
          if (index > -1) {
            try {
              axios({
                method: 'post',
                url: `${API_DOMAIN}/api/files/`,
                data: bodyFormData,
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${this.token}`
                },
                onUploadProgress: (event) => {
                  const totalPercent = Math.round((100 * event.loaded) / event.total)
                  for (let i = 0; i < userFiles.length; i++)
                    this.files[i].progress = totalPercent
                  if (totalPercent >= 100) {
                    setTimeout(() => {
                      this.addNewCursor++
                      this.addNewProgress = 38
                    }, 1000)
                    this.$forceUpdate()
                  }
                }
              })
                .then((response) => {
                  // handle success
                  const { data } = response
                  const uuid = data.file_token
                  this.fileToken = uuid
                  this.isLoading = false
                })
                .catch((response) => {
                  // handle error
                  console.log(response)
                })
              } catch (e) {
                console.error(e)
              }
          }
        }
      },
      setHover (i) {
        this.isHovering[i] = true
        this.$forceUpdate()
      },
      setHout (i) {
        this.isHovering[i] = false
        this.$forceUpdate()
      },
      getNationalities () {
        const filtered = this.nationalities.filter((nationality) => nationality.includes(this.nationality))
        return filtered
      }
    },
    components: {
      PersonalAreaMiddleware,
      // Multiselect,
    }
  })
</script>
<style
  scoped
  lang="scss">
  
  .inples__label {
    color: #171776;
  }
  .clickable {
    cursor: pointer;
  }
  .inples__settings__payment-method {
    border-radius: 14px;
  }
  .inples__drawer {
    position: fixed;
    left: 0px;
    top: 0px;
  }
  .inples__drawer__item {
    width: 226px;
  }
  .inples__drawer__item-icon {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 100%;
  }
  .inples__drawer__selected-item {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 14px;
  }
  .inples__timestamp__tile {
    float: left;
  }
  .inples__timestamp:hover {
    background-color: #fff !important;
  }
  .inples__tab__content {
    height: calc(100% - (20px + 46px));
  }
  .v-input__slot {
    background: green !important;
  }
  .inples__card {
    overflow: hidden;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 14px !important;
  }
  .blured_content {
    filter: blur(20px);
  }
  .search_field {
    filter: blur(0px);
  }
  .inples__table__item::before {
    display: none;
  }
  >>> .v-navigation-drawer__content {
    min-width: 100% !important;
    width: 100% !important;
  }
  .dropZone {
    background: rgba(255, 255, 255, 0);
    width: 100%;
    margin-top: 47px;
  }
  .inples__field__rounded {
    border-radius: 60px;
  }
  .delete-project {
    position: relative;
    top: 40px;
    z-index: 1;
  }
  
  .selectedChip .v-chip__content {
    color: white;
    font-weight: bold;
  }
  .v-chip:hover *{
    cursor: pointer!important;
    background: red !important;
  }
  >>> .v-chip .v-chip__content:hover {
    cursor: pointer;
    background: red !important;
  }
  .inples__label__faq:hover {
    color: #000;
  }
  .inples__label__faq:hover {
    color: #171776;
  }
  .inples__label__btn:hover {
    color: #000;
  }
  .v-navigation-drawer >>> .v-navigation-drawer__border {
    display: none !important;
  }
  .multiselect, .multiselect__tags {
    background: rgba(255, 255, 255, 0.7) !important;
    height: 55px;
    border: none !important;
    outline: none !important;
  }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css" />
<style>
  .multiselect__option, .multiselect__option--highlight {
    background: rgba(255, 255, 255, 0.7) !important;
    color: black ;
  }
  .multiselect, .multiselect__tags {
    background: rgba(255, 255, 255, 0.7) !important;
    height: 55px;
    border: none !important;
    outline: none !important;
  }
  .multiselect__placeholder {
    background: rgba(255, 255, 255, 0.7) !important;
    padding-top: 10px !important;
  }
  .multiselect__single {
    padding-top: 10px !important;
    background: transparent !important;
  }
</style>
<style scoped>
  .multiselect__option, .multiselect__option--highlight {
    background: rgba(255, 255, 255, 0.7) !important;
    color: black !important;
  }
  .multiselect, .multiselect__tags {
    background: rgba(255, 255, 255, 0.7) !important;
    height: 55px;
    border: none !important;
    outline: none !important;
    border-radius: 14px !important;
  }
  .multiselect__placeholder, .multiselect__single {
    padding-top: 10px !important;
    background: rgba(255, 255, 255, 0.7) !important;
  }
  .multiselect__option--highlight .multiselect__option--selected, .multiselect__option--highlight:hover .multiselect__option--selected:hover {
    color: black !important;
  }
</style>
