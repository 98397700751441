<template>
  <PersonalAreaMiddleware>
    <v-card style="height: 100% !important;">
      <v-layout :style="{ backgroundImage: `url(${require('@/assets/background.png')})`, backgroundSize: 'cover', height: '100%', '-webkit-filter': $vuetify.theme.dark ? 'saturate(0)' : 'none' }">
        <v-navigation-drawer
          app
          :mini-variant="rail"
          :border="0"
          class="pa-5 d-flex align-center col-3"
          permanent
          :elevation="1"
          style="height: 100% !important; display: flex; justify-content: center; background-color: transparent; border: 0px solid transparent !important; outline: 0px !important;">
          <v-list nav>
            <v-list-item
              v-if="rail"
              :class="{'justify-space-between': true, 'clickable': true}"
              @click="select('Timestamp')">
              <v-row
                v-if="($vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm') && rail"
                justify="center">
                <v-list-item-icon
                  class="ma-0">
                  <v-btn
                    icon
                    @click="rail = !rail">
                    <v-icon color="#171776">mdi-menu</v-icon>
                  </v-btn>
                </v-list-item-icon>
              </v-row>
            </v-list-item>
            <v-row
              v-else
              justify="start"
              class="clickable"
              @click="back">
              <v-col cols="1">
                <v-icon style="background-color: rgba(255, 255, 255, 0.5); border-radius: 100%;" class="align-self-center">mdi-chevron-left</v-icon>
              </v-col>
              <v-col>
                <p class="align-self-center ml-3 inples__label">Back</p>
              </v-col>
            </v-row>
            <v-list-item
              :class="{'inples__settings__selected-item': isSelected('Timestamp'), 'justify-space-between': true, 'clickable': true}"
              @click="select('Timestamp')">
              <v-row
                v-if="rail"
                justify="center">
                <v-list-item-icon
                  class="ma-0">
                  <v-icon color="#171776">mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-row>
              <v-row
                v-else
                style="display: flex; align-items: center; justify-content: space-between;">
                  <v-col>
                      <p class="inples__label align-self-center ml-3 my-0">Timestamp</p>
                  </v-col>
                  <v-icon class="mx-5 align-self-center">mdi-chevron-right</v-icon>
              </v-row>
            </v-list-item>
            <v-list-item
              :class="{'inples__settings__selected-item': isSelected('Examine'), 'justify-space-between': true, 'clickable': true}"
              @click="select('Examine')">
              <v-row
                v-if="rail"
                justify="center">
                <v-list-item-icon
                  class="ma-0">
                  <v-icon color="#171776">mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-row>
              <v-row
                v-else
                style="display: flex; align-items: center; justify-content: space-between;">
                  <v-col>
                      <p class="inples__label align-self-center ml-3 my-0">Examine</p>
                  </v-col>
                  <v-icon class="mx-5 align-self-center">mdi-chevron-right</v-icon>
              </v-row>
            </v-list-item>
            <v-list-item
              :class="{'inples__settings__selected-item': isSelected('Fine tune'), 'justify-space-between': true, 'clickable': true}"
              @click="select('Fine tune')">
              <v-row
                v-if="rail"
                justify="center">
                <v-list-item-icon
                  class="ma-0">
                  <v-icon color="#171776">mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-row>
              <v-row
                v-else
                style="display: flex; align-items: center; justify-content: space-between;">
                  <v-col>
                      <p class="inples__label align-self-center ml-3 my-0">Fine tune</p>
                  </v-col>
                  <v-icon class="mx-5 align-self-center">mdi-chevron-right</v-icon>
              </v-row>
            </v-list-item>
            <v-list-item
              v-if="false"
              :class="{'inples__settings__selected-item': isSelected('Add content'), 'justify-space-between': true, 'clickable': true}"
              @click="select('Add content')">
              <v-row
                v-if="rail"
                justify="center">
                <v-list-item-icon
                  class="ma-0">
                  <v-icon color="#171776">mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-row>
              <v-row
                v-else
                style="display: flex; align-items: center; justify-content: space-between;">
                  <v-col>
                      <p class="inples__label align-self-center ml-3 my-0">Add content</p>
                  </v-col>
                  <v-icon class="mx-5 align-self-center">mdi-chevron-right</v-icon>
              </v-row>
            </v-list-item>
            <v-list-item
              :class="{'inples__settings__selected-item': isSelected('Export content'), 'justify-space-between': true, 'clickable': true}"
              @click="select('Export content')">
              <v-row
                v-if="rail"
                justify="center">
                <v-list-item-icon
                  class="ma-0">
                  <v-icon color="#171776">mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-row>
              <v-row
                v-else
                style="display: flex; align-items: center; justify-content: space-between;">
                  <v-col>
                      <p class="inples__label align-self-center ml-3 my-0">Export content</p>
                  </v-col>
                  <v-icon class="mx-5 align-self-center">mdi-chevron-right</v-icon>
              </v-row>
            </v-list-item>
            <v-list-item
              :class="{'inples__settings__selected-item': isSelected('Certificate'), 'justify-space-between': true, 'clickable': true}"
              @click="select('Certificate')">
              <v-row
                v-if="rail"
                justify="center">
                <v-list-item-icon
                  class="ma-0">
                  <v-icon color="#171776">mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-row>
              <v-row
                v-else
                style="display: flex; align-items: center; justify-content: space-between;">
                  <v-col>
                      <p class="inples__label align-self-center ml-3 my-0">Certificate</p>
                  </v-col>
                  <v-icon class="mx-5 align-self-center">mdi-chevron-right</v-icon>
              </v-row>
            </v-list-item>
            <v-list-item
              v-if="false"
              :class="{'inples__settings__selected-item': isSelected('Transfer rights'), 'justify-space-between': true, 'clickable': true}"
              @click="select('Transfer rights')">
              <v-row
                v-if="rail"
                justify="center">
                <v-list-item-icon
                  class="ma-0">
                  <v-icon color="#171776">mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-row>
              <v-row
                v-else
                style="display: flex; align-items: center; justify-content: space-between;">
                  <v-col>
                      <p class="inples__label align-self-center ml-3 my-0">Transfer rights</p>
                  </v-col>
                  <v-icon class="mx-5 align-self-center">mdi-chevron-right</v-icon>
              </v-row>
            </v-list-item>
            <v-list-item
              :class="{'inples__settings__selected-item': isSelected('Delete'), 'justify-space-between': true, 'clickable': true}"
              @click="select('Delete')">
              <v-row
                v-if="rail"
                justify="center">
                <v-list-item-icon
                  class="ma-0">
                  <v-icon color="#171776">mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-row>
              <v-row
                v-else
                style="display: flex; align-items: center; justify-content: space-between;">
                  <v-col>
                      <p class="inples__label align-self-center ml-3 my-0">Delete</p>
                  </v-col>
                  <v-icon class="mx-5 align-self-center">mdi-chevron-right</v-icon>
              </v-row>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
        <v-col
          v-if="isSelected('Timestamp')"
          @click="hideDrawer">
          <v-toolbar
            color="transparent"
            flat>
            <v-toolbar-title class="font-weight-bold">
              {{getTimestampTitle()}}
            </v-toolbar-title>
          </v-toolbar>
          <v-container class="bg-surface-variant">
            <v-row
              class="mb-6"
              no-gutters>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="6"
                xl="6">
                <v-card
                  elevation="0"
                  color="#FFFFFF66"
                  class="ma-2 overflow-hidden"
                  :style="{ background: 'linear-gradient(#19A4FB33, #02C6A233)', backgroundSize: 'cover', backgroundPosition: '-35px', borderRadius: '14px', padding: '30px' }"
                  height="310">
                  <p class="inples__label text-uppercase">Work information</p>
                  <v-row class="ma-0">
                    <p>Title:</p>
                    <v-spacer />
                    <p class="font-weight-medium">{{getTimestampTitle()}}</p>
                  </v-row>
                  <v-row class="ma-0">
                    <p>Year of creation:</p>
                    <v-spacer></v-spacer>
                    <p class="font-weight-medium">2023</p>
                  </v-row>
                  <v-row class="ma-0">
                    <p>Type if work:</p>
                    <v-spacer />
                    <p class="font-weight-medium">{{getTimestampType()}}</p>
                  </v-row>
                  <v-row class="ma-0">
                    <p>Entry date:</p>
                    <v-spacer></v-spacer>
                    <p class="font-weight-medium">{{getTimestampDate(timestamp)}}</p>
                  </v-row>
                  <v-row class="ma-0">
                    <p>Identifier:</p>
                    <v-spacer></v-spacer>
                    <p class="font-weight-medium">{{getTimestampId()}}</p>
                  </v-row>
                  <v-row class="ma-0">
                    <p>Licence:</p>
                    <v-spacer></v-spacer>
                    <p class="font-weight-medium">{{getTimestampLicense()}}</p>
                  </v-row>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="6"
                xl="6">
                <v-card
                  elevation="0"
                  color="#FFFFFF66"
                  class="ma-2 overflow-hidden"
                  :style="{ background: 'linear-gradient(#19A4FB33, #02C6A233)', backgroundSize: 'cover', backgroundPosition: '-35px', borderRadius: '14px', padding: '30px' }"
                  height="310">
                  <p class="inples__label text-uppercase">Registered declaration</p>
                  <v-row class="ma-0">
                    <p>Author(s):</p>
                    <v-spacer></v-spacer>
                    <p class="font-weight-medium">{{getTimestampName()}}</p>
                  </v-row>
                  <v-row class="ma-0">
                    <p>Name:</p>
                    <v-spacer></v-spacer>
                    <p class="font-weight-medium">{{getTimestampName()}}</p>
                  </v-row>
                  <v-row class="ma-0">
                    <p>Files Name:</p>
                    <v-spacer></v-spacer>
                    <p class="font-weight-medium">{{files.join(', ')}}</p>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                sm="12"
                md="12"
                lg="12">
                <v-card
                  elevation="0"
                  color="#FFFFFF66"
                  :rounded="14"
                  :style="{ borderRadius: '14px', padding: '30px', background: 'linear-gradient(#19A4FB33, #02C6A233)' }"
                  class="ma-2 overflow-hidden"
                  height="329">
                  <v-row class="ma-0">
                    <p class="font-weight-bold">Hash</p>
                  </v-row>
                  <v-row class="ma-0">
                    <p class="">{{getTimestampHash()}}</p>
                  </v-row>
                  <v-row class="ma-0">
                    <p class="font-weight-bold">Transaction Hash</p>
                  </v-row>
                  <v-row class="ma-0">
                    <a
                      target="_blank"
                      :href="`https://etherscan.io/tx/${getTimestampTransactionHash()}`">{{getTimestampTransactionHash()}}</a>
                  </v-row>
                  <v-row class="ma-0">
                    <p class="font-weight-bold">Root Hash</p>
                  </v-row>
                  <v-row class="ma-0">
                    <p class="">{{getTimestampRootHash()}}</p>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col
          v-else-if="isSelected('Examine')"
          @click="hideDrawer">
          <v-toolbar
            color="transparent"
            flat>
            <v-toolbar-title class="font-weight-bold">
              Examine
            </v-toolbar-title>
          </v-toolbar>
          <v-row
            align="end"
            class="px-4">
            <v-col
              cols="6"
              sm="12"
              md="12"
              lg="6"
              xl="6">
              <p class="font-weight-bold text-h5">Your uploaded files</p>
              <p>These are the files that you’ve uploaded<br />when you were creating a timestamp.</p>
              <v-toolbar
                v-for="(fileToken, i) in fileTokens"
                :key="i"
                color="#FFFFFF44"
                height="80"
                elevation="0"
                class="inples__settings__payment-method my-6">
                <p class="ma-0 pa-0 font-weight-bold">{{files[i]}}</p>
                <v-spacer />
                <p class="ma-0 pa-0">{{getFileDate()}}</p>
              </v-toolbar>
            </v-col>
            <v-col
              cols="6"
              sm="12"
              md="12"
              lg="6"
              xl="6">
              <p class="font-weight-bold text-h5">Examine</p>
              <p>Upload the same files to run a check to make sure everything is in order.</p>
              <v-progress-linear
                style="border-radius: 14px;" 
                :clickable="false"
                readonly
                v-for="(fileToken, i) in fileTokens"
                :key="i"
                :value="examines[i].progress"
                rounded
                color="#fff"
                class="my-5"
                :height="$vuetify.breakpoint.name === 'md' || $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl' ? 80 : windowHeight / 3"
                @click.prevent>
                <template v-slot:default>
                  <v-row
                    class="px-8"
                    align="center"
                    justify="space-around">
                    <p class="ma-0 pa-0 font-weight-bold">{{
                      examines[i].hash ?
                        examines[i].name
                      :
                        ''  
                    }}</p>
                    <v-spacer />
                    <v-btn
                      v-if="!examines[i].hash"
                      elevation="0"
                      rounded
                      width="160"
                      height="40"
                      color="#171776"
                      class="text-capitalize inples__btn my-5 inples__outlined-btn"
                      @click="upload(i)">
                      <v-row
                        justify="center"
                        align="center"
                        no-gutters>
                        <v-col>
                          <span class="white--text font-weight-bold">Upload</span>
                        </v-col>
                        <v-spacer />
                        <v-col>
                          <v-img
                            contain
                            width="20"
                            src="../assets/upload.svg" />
                        </v-col>
                      </v-row>
                    </v-btn>
                    <v-img
                      v-else-if="fileHashes.includes(examines[i].hash)"
                      contain
                      max-width="40"
                      width="40"
                      height="39.67"
                      src="../assets/success.svg" />
                    <v-img
                      v-else-if="!fileHashes.includes(examines[i].hash)"
                      contain
                      max-width="40"
                      width="40"
                      height="40"
                      src="../assets/fail.svg" />
                  </v-row>
                </template>
              </v-progress-linear>
              <!-- <v-toolbar
                v-for="(fileToken, i) in fileTokens"
                :key="i"
                color="#FFFFFF44"
                height="80"
                elevation="0"
                class="inples__settings__payment-method my-6">
                <p class="ma-0 pa-0 font-weight-bold">{{
                  examines[i].hash ?
                    '1381749359.zip'
                  :
                    ''  
                }}</p>
                <v-spacer />
                <v-btn
                  v-if="!examines[i].hash"
                  elevation="0"
                  rounded
                  width="160"
                  height="40"
                  color="#171776"
                  class="text-capitalize inples__btn my-5 inples__outlined-btn"
                  @click="upload(i)">
                  <v-row
                    justify="center"
                    align="center"
                    no-gutters>
                    <v-col>
                      <span class="white--text font-weight-bold">Upload</span>
                    </v-col>
                    <v-spacer />
                    <v-col>
                      <v-img
                        contain
                        width="20"
                        src="../assets/upload.svg" />
                    </v-col>
                  </v-row>
                </v-btn>
                <v-img
                  v-else-if="examines[i].hash === fileTokens[i]"
                  contain
                  max-width="40"
                  width="40"
                  height="39.67"
                  src="../assets/success.svg" />
                <v-img
                  v-else-if="examines[i].hash !== fileTokens[i]"
                  contain
                  max-width="40"
                  width="40"
                  height="40"
                  src="../assets/fail.svg" />
              </v-toolbar> -->
            </v-col>
          </v-row>
          <input
            class="d-none"
            type="file"
            ref="inputRef"
            @change="onChange" />
        </v-col>
        <v-col
          v-else-if="isSelected('Fine tune')"
          @click="hideDrawer">
          <v-toolbar
            color="transparent"
            flat>
            <v-toolbar-title class="font-weight-bold">
              Fine tune
            </v-toolbar-title>
          </v-toolbar>
        </v-col>
        <v-col
          v-else-if="isSelected('Add content')"
          @click="hideDrawer">
          <v-toolbar
            color="transparent"
            flat>
            <v-toolbar-title class="font-weight-bold">
              Add content
            </v-toolbar-title>
          </v-toolbar>
        </v-col>
        <v-col
          v-else-if="isSelected('Export content')"
          @click="hideDrawer">
          <v-toolbar
            color="transparent"
            flat>
            <v-toolbar-title class="font-weight-bold">
              Export content
            </v-toolbar-title>
          </v-toolbar>
          <v-container
            ref="draftsContainer"
            style="height: calc(100vh - 175px) !important; overflow-y: scroll;"
            class="inples__tab__content">
            <v-container v-if="files.length">
              <v-expand-transition>
                  <div>
                    <v-toolbar
                      color="transparent"
                      height="20"
                      elevation="0"
                      class="inples__settings__payment-method my-5">
                      <v-col
                        class="overflow-hidden mx-0 px-0"
                        cols="3">
                        <v-btn
                          elevation="0"
                          color="transparent"
                          :ripple="false"
                          class="font-weight-bold inples__table__item">Name</v-btn>
                      </v-col>
                      <v-col
                        class="overflow-hidden mx-0 px-0"
                        cols="3">
                        <v-btn
                          elevation="0"
                          color="transparent"
                          :ripple="false"
                          class="font-weight-bold inples__table__item">Date</v-btn>
                      </v-col>
                    </v-toolbar>
                    <v-toolbar
                      prominent
                      color="#FFFFFF44"
                      height="80"
                      elevation="0"
                      class="overflow-hidden inples__settings__payment-method my-5 clickable"
                      v-for="(archiveName, index) in files"
                      :key="index"
                      @click="toggleFile(index)">
                      <v-col
                        cols="3"
                        class="overflow-hidden align-self-center">
                        <v-btn
                          elevation="0"
                          color="transparent"
                          :ripple="false"
                          class="overflow-hidden font-weight-bold inples__table__item">{{archiveName}}</v-btn>
                      </v-col>
                      <v-col
                        cols="3"
                        class="overflow-hidden align-self-center">
                        <v-btn
                          elevation="0"
                          color="transparent"
                          :ripple="false"
                          class="overflow-hidden font-weight-bold inples__table__item">{{getFileDate()}}</v-btn>
                      </v-col>
                      <v-btn
                        style="text-transform: unset;"
                        elevation="0"
                        color="transparent"
                        class="overflow-hidden font-weight-bold indigo--text align-self-center inples__table__item"
                        @click="exportContent">Download</v-btn>
                    </v-toolbar>
                  </div>
              </v-expand-transition>
            </v-container>
            <p
              v-else
              class="title text-center my-5">Drafts is empty</p>
          </v-container>
        </v-col>
        <v-col
          v-else-if="isSelected('Certificate')"
          @click="hideDrawer">
          <v-toolbar
            color="transparent"
            flat>
            <v-toolbar-title class="font-weight-bold">
              Certificate
            </v-toolbar-title>
          </v-toolbar>
          <PDFViewer
            :source="getTimestampCert()"
            style="height: 100vh;"
            @download="handleDownload" />
        </v-col>
        <v-col
          v-else-if="isSelected('Transfer rights')"
          @click="hideDrawer">
          <v-toolbar
            color="transparent"
            flat>
            <v-toolbar-title class="font-weight-bold">
              Transfer rights
            </v-toolbar-title>
          </v-toolbar>
        </v-col>
        <v-col
          v-else-if="isSelected('Delete')"
          @click="hideDrawer">
          <v-container style="height: 100%;">
            <v-row
              style="height: 100%;"
              justify="center"
              align="center">
              <v-container v-if="!deleteTimestampStatus">
                <v-row justify="center">
                  <v-img
                    class="delete-project"
                    src="../assets/delete_primary.svg"
                    contain
                    width="66.67"
                    height="80" />
                </v-row>
                <v-row justify="center">
                  <v-card
                    :style="{background: 'linear-gradient(0deg, #02C6A233, #19A4FB33)'}"
                    max-width="528"
                    max-height="605"
                    color="transparent"
                    elevation="0"
                    class="inples__card d-flex flex-column align-center">
                    <template #append>
                      <v-icon>mdi-close</v-icon>
                    </template>
                    <v-card-title class="headline inples__card__title pa-13">
                      <span class="text-center text-h5 font-weight-medium">You attempting to delete a project with a name: <span class="font-weight-bold inples__label">{{getTimestampTitle()}}</span></span>
                    </v-card-title>
                    <v-card-text class="px-5">
                      <div>
                        <div>
                          <div>
                            <div>
                              <p class="text-center font-weight-regular black--text text-h6">All data will be irretrievably deleted and you will<br />lose the ability to prove authorship of your work.<br />To delete a project, enter the title in the field<br />below and click the Delete button</p>
                              <p class="text-center font-weight-regular black--text">Enter title here</p>
                              <v-text-field
                                v-model="project"
                                height="60"
                                rounded
                                filled
                                class="inples__field"
                                :background-color="focuses[0] ? 'white' : 'rgba(255, 255, 255, 0.7)'"
                                @focus="setFocus(0, true)"
                                @blur="setFocus(0, false)" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card-text>
                    <v-card-actions class="ma-0">
                      <v-col>
                        <v-btn
                          width="284"
                          height="60"
                          :color="isHovering[0] ? '#4087ED' : '#171776'"
                          class="white--text inples__btn inples__btn__upload text-h6 font-weight-regular"
                          style="text-transform: unset !important;"
                          elevation="0"
                          :disabled="project !== getTimestampTitle()"
                          @mouseover="setHover(0)"
                          @mouseout="setHout(0)"
                          @click.prevent="deleteTimestamp">Delete timestamp</v-btn>
                      </v-col>
                    </v-card-actions>
                  </v-card>
                </v-row>
              </v-container>
              <v-container v-else>
                <v-row justify="center">
                  <v-img
                    class="delete-project"
                    src="../assets/ts_delete_success_status.svg"
                    contain
                    width="66.67"
                    height="80" />
                </v-row>
                <v-row justify="center">
                  <v-card
                    :style="{background: 'linear-gradient(0deg, #02C6A233, #19A4FB33)'}"
                    max-width="528"
                    max-height="296"
                    color="transparent"
                    elevation="0"
                    class="inples__card d-flex flex-column align-center">
                    <template #append>
                      <v-icon>mdi-close</v-icon>
                    </template>
                    <v-card-title class="headline inples__card__title pa-13">
                      <span class="text-center text-h5 font-weight-regular">Timestamp has been<br />deleted.</span>
                    </v-card-title>
                    <v-card-actions class="ma-0">
                      <v-col>
                        <v-btn
                          width="284"
                          height="60"
                          :color="isHovering[0] ? '#4087ED' : '#171776'"
                          class="white--text inples__btn inples__btn__upload text-h6 font-weight-regular"
                          style="text-transform: unset !important;"
                          elevation="0"
                          @mouseover="setHover(0)"
                          @mouseout="setHout(0)"
                          @click.prevent="back">Return home</v-btn>
                      </v-col>
                    </v-card-actions>
                  </v-card>
                </v-row>
              </v-container>
            </v-row>
          </v-container>
        </v-col>
      </v-layout>
    </v-card>
  </PersonalAreaMiddleware>
</template>

<script>
  import Vue, { ref } from 'vue'
  import { mapState } from 'vuex'
  import PersonalAreaMiddleware from '@/components/middlewares/PersonalAreaMiddleware.vue'
  import { API_DOMAIN } from '../utils/consts'
  import axios from 'axios'
  import moment from 'moment'
  import PDFViewer from 'pdf-viewer-vue/dist/vue2-pdf-viewer'

  export default Vue.extend({
    name: 'SettingsPageView',
    setup () {
      const selectedItem = ref('Timestamp')
      const rail = ref(false)
      const files = ref([])
      const dialog = ref(false)
      const project = ref('')
      const focuses = ref([])
      const isHovering = ref([])
      const deleteTimestampStatus = ref(null)
      const certUrl = ref('https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf')
      const isShowDetails = ref([])
      const uploadedAt = ref(false)
      const fileTokens = ref([])
      const timestamp = ref({})
      const examines = ref([
        {
          hash: null
        }
      ])
      const uploadedExamine = ref(-1)
      const fileHashes = ref([])
      return {
        selectedItem,
        rail,
        files,
        dialog,
        project,
        focuses,
        isHovering,
        deleteTimestampStatus,
        certUrl,
        isShowDetails,
        uploadedAt,
        fileTokens,
        timestamp,
        examines,
        uploadedExamine,
        fileHashes,
      }
    },
    computed: {
      ...mapState([
        'token'
      ])
    },
    mounted () {
      if (!this.$store.state.timestamp) {
        this.$router.push({ name: 'login' })
      }
      axios({
        url: `${API_DOMAIN}/api/stamps/${this.$route.query.id}/`,
        headers: {
          'Authorization': `Bearer ${this.$store.state.token}`
        }
      })
        .then((response) => {
          console.log(response)
          this.timestamp = response.data
          const rid = this.$store.state.timestamp.rid
          if (rid) {
            axios({
              url: `${API_DOMAIN}/api/files/`,
              headers: {
                'Authorization': `Bearer ${this.$store.state.token}`
              }
            })
              .then((response) => {
                console.log(response)
                let fileToken = ''
                const rid = this.$store.state.timestamp.rid
                if (rid) {
                  fileToken = rid.file_token
                }
                for (let i of response.data) {
                  const itemFileToken = i.file_token
                  const result = itemFileToken === fileToken
                  if (result) {
                    this.files = i.filenames
                    this.isShowDetails = this.files.map((details, idx) => false)
                    this.uploadedAt = i.uploaded_at
                    this.fileTokens = [
                      i.file_token
                    ]
                    this.examines = this.files.map((details, idx) => ({
                      hash: null,
                      progress: 0,
                      name: '',
                    }))
                    this.fileHashes = this.files.map((details, idx) => {
                      return null
                    })
                    break
                  }
                }
                const certificate = this.timestamp.certificate
                if (certificate) {
                  this.certUrl = certificate
                }
              })
              .catch((response) => {
                console.log(response)
              })
          }
        })
        .catch((response) => {
          console.log(response)
        })
      const query = this.$route.query
      const action = query.action
      if (action) {
        if (action === 'examine') {
          this.select('Examine')
        } else if (action === 'export-content') {
          this.select('Export content')
        } else if (action === 'cert') {
          this.select('Certificate')
        } else if (action === 'delete') {
          this.select('Delete')
        }
      }
    },
    methods: {
      calculateSha2 (file) {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader()
          fileReader.readAsArrayBuffer(file)
          fileReader.onload = () => {
            const data = fileReader.result
            window.crypto.subtle.digest('SHA-256', data)
              .then(buffer => {
                const array = Array.from(new Uint8Array(buffer))
                const hash = array.map(b => b.toString(16).padStart(2, '0')).join('')
                resolve(hash)
              })
              .catch(reject)
          }
          fileReader.onerror = reject
        })
      },
      async onChange (e) {
        var files = e.target.files || e.dataTransfer.files
        let file = files[0]
        const hash = await this.calculateSha2(file)
        this.examines[this.uploadedExamine].hash = hash
        this.examines[this.uploadedExamine].progress = 100
        this.examines[this.uploadedExamine].name = file.name
        this.uploadedExamine = -1
        const hashes = [
          hash
        ]
        axios({
          url: `${API_DOMAIN}/api/files/examine/`,
          method: 'post',
          headers: {
            'Authorization': `Bearer ${this.$store.state.token}`
          },
          data: {
            'file_hashes': hashes
          },
        })
          .then((response) => {
            console.log(response)
            this.fileHashes = Object.keys(response.data).map((hash) => {
              return hash
            })
            this.$forceUpdate()
          })
          .catch((response) => {
            console.log(response)
          })
      },
      upload (i) {
        this.uploadedExamine = i
        this.$refs.inputRef.click()
      },
      getFileDate () {
        let date = ''
        const uploadedAt = this.uploadedAt
        if (uploadedAt) {
          date = `${moment(uploadedAt).format('DD.MM.YYYY hh:mm a')} UTM`
        }
        return date
      },
      toggleFile (index) {
        this.isShowDetails[index] = !this.isShowDetails[index]
        this.$forceUpdate()
      },
      exportContent () {
        const fileTokens = this.fileTokens
        axios({
          url: `${API_DOMAIN}/api/files/get-files/`,
          method: 'post',
          headers: {
            'Authorization': `Bearer ${this.$store.state.token}`
          },
          data: {
            'file_tokens': fileTokens
          },
          responseType: 'blob',
        })
          .then((response) => {
            console.log(response)
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data)

            // create "a" HTML element with href to file & click
            const link = document.createElement('a')
            link.href = href
            link.setAttribute('download', 'inples-export.zip') //or any other extension
            document.body.appendChild(link)
            link.click()
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link)
            URL.revokeObjectURL(href)
          })
          .catch((response) => {
            console.log(response)
          })
      },
      handleDownload () {
        // create file link in browser's memory
        const href = this.certUrl
        // create "a" HTML element with href to file & click
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', 'inples-cert.pdf') //or any other extension
        document.body.appendChild(link)
        link.click()
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
      URL.revokeObjectURL(href)
      },
      getTimestampCert () {
        let cert = this.certUrl
        const certificate = this.$store.state.timestamp.certificate
        if (certificate) {
          cert = certificate
        }
        return cert
      },
      getTimestampId () {
        let id = ''
        const verifyCode = this.$store.state.timestamp.verify_code
        if (verifyCode) {
          id = verifyCode
        }
        return id
      },
      getTimestampTransactionHash () {
        let hash = '-'
        const details = this.$store.state.timestamp.details
        if (details) {
          const data = details
          if (data) {
            const timestamps = data.timestamps
            if (timestamps) {
              const transactionTimestamps = timestamps.filter((ts) => {
                const currencyId = ts.currency_id
                return currencyId === 1
              })
              if (transactionTimestamps.length) {
                const transactionTimestamp = transactionTimestamps[0]
                const transactionHash = transactionTimestamp.transaction
                if (transactionHash) {
                  hash = transactionHash
                }
              }
            }
          }
        }
        return hash
      },
      getTimestampRootHash () {
        let hash = '-'
        const details = this.$store.state.timestamp.details
        if (details) {
          const data = details
          if (data) {
            const timestamps = data.timestamps
            if (timestamps) {
              const transactionTimestamps = timestamps.filter((ts) => {
                const currencyId = ts.currency_id
                return currencyId === 1
              })
              if (transactionTimestamps.length) {
                const transactionTimestamp = transactionTimestamps[0]
                const rootHash = transactionTimestamp.private_key
                if (rootHash) {
                  hash = rootHash
                }
              }
            }
          }
        }
        return hash
      },
      getTimestampLicense () {
        let license = '-'
        const rid = this.$store.state.timestamp.rid
        if (rid) {
          license = rid.license
        }
        return license
      },
      getTimestampTransaction () {
        let hash = '-'
        const details = this.$store.state.timestamp.details
        if (details) {
          const data = details
          if (data) {
            hash = data.hash_string
          }
        }
        return hash
      },
      getTimestampHash () {
        let hash = '-'
        const details = this.$store.state.timestamp.details
        if (details) {
          const data = details
          if (data) {
            hash = data.hash_string
          }
        }
        return hash
      },
      getTimestampType () {
        let type = ''
        const rid = this.$store.state.timestamp.rid
        if (rid) {
          type = rid.type
        }
        return type
      },
      getTimestampTitle () {
        let title = ''
        const rid = this.$store.state.timestamp.rid
        if (rid) {
          title = rid.title
        }
        return title
      },
      getTimestampName () {
        let name = ''
        const rid = this.$store.state.timestamp.rid
        if (rid) {
          name = rid.name
        }
        return name
      },
      getTimestampDate () {
        let date = ''
        const rid = this.$store.state.timestamp.rid
        if (rid) {
          const data = rid.created_at
          if (data) {
            date = `${moment(data).format('DD.MM.YYYY hh:mm a')} UTM`
          }
        }
        return date
      },
      hideDrawer () {
        if ((this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm')) {
          this.rail = true
        }
      },
      isSelected (item) {
        const result = item === this.selectedItem
        return result
      },
      select (val) {
        this.selectedItem = val
      },
      back () {
        this.$router.push({name: 'home'})
      },
      setFocus(i, val) {
        this.focuses[i] = val
        this.$forceUpdate()
      },
      setHover (i) {
        this.isHovering[i] = true
        this.$forceUpdate()
      },
      setHout (i) {
        this.isHovering[i] = false
        this.$forceUpdate()
      },
      deleteTimestamp () {
        const timestamp = this.$store.state.timestamp
        const rid = timestamp.rid
        if (rid) {
          const ridId = rid.id
          axios({
            method: 'delete',
            url: `${API_DOMAIN}/api/rids/${ridId}/`,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${this.token}`
            }
          })
            .then((response) => {
              this.deleteTimestampStatus = 'DELETED'
              this.setHout(0)
            })
            .catch((error) => {
              // handle error
              const response = error.response
              console.log(response)
            })
        }
      },
    },
    components: {
      PersonalAreaMiddleware,
      PDFViewer
    }
  })
</script>

<style scoped>
  .inples__label {
    color: #171776;
  }
  .inples__settings__selected-item {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 14px;
  }
  .inples__settings__payment-method {
    border-radius: 14px;
  }
  .clickable {
    cursor: pointer;
  }
  .dropZone {
    background: rgba(255, 255, 255, 0);
    width: 100%;
    margin-top: 47px;
  }
  .delete-project {
    position: relative;
    top: 40px;
    z-index: 1;
  }
</style>