<template>
  <v-container class="d-flex flex-column justify-center align-center">
    <v-card elevation="0"
      class="inples__card d-flex flex-column align-center pa-5">
      <v-card-title class="headline inples__card__title">
        <v-img src="../assets/thanks.svg"
          contain
          width="64"
          height="53.33" />
      </v-card-title>
      <v-card-subtitle>
        <p
          class="text-subtitle-1 text-center ma-5">Almost done. Please check<br />your email and follow the link<br />to verify in order to complete the registration</p>
      </v-card-subtitle>
    </v-card>
  </v-container>
</template>
<script>
export default {
    name: 'ThanksView'
}
</script>
<style lang="scss" scoped>
.inples {
  &__card {
    overflow: hidden;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 14px;
  }
}
</style>