<template>
    <v-col
        cols="12">
        <v-toolbar
            class="px-15"
            color="transparent"
            flat>
            <v-toolbar-title>
                <v-img
                    src="../assets/logo.svg"
                    contain
                    min-width="98"
                    min-height="15"
                    width="140"
                    height="22"
                    max-width="140"
                    max-height="22"  />
            </v-toolbar-title>
            <v-spacer />
            <v-btn
                border="1"
                elevation="0"
                width="139"
                height="48"
                outlined
                color="#171776"
                class="text-capitalize inples__btn my-5 inples__outlined-btn"
                variant="text"
                @click="signIn">
                <v-row justify="center" align="center">
                    <v-col>
                        <span class="inples__label">Sign in</span>
                    </v-col>
                    <v-col>
                        <v-icon color="#171776">mdi-login</v-icon>  
                    </v-col>
                </v-row>
                </v-btn>
        </v-toolbar>
        <v-container
            ref="draftsContainer"
            class="inples__tab__content mt-16">
            <v-container>
                <v-expand-transition>
                    <v-row class="px-10">
                        <v-text-field
                            height="60"
                            v-model="cert"
                            placeholder="Enter certificate number"
                            rounded
                            filled
                            background-color="rgba(255, 255, 255, 0.7)"
                            class="inples__field inples__field__rounded" />
                        <v-btn
                            width="252"
                            height="60"
                            variant="text"
                            color="#171776"
                            class="text-capitalize inples__btn white--text ml-9"
                            :disabled="!cert.length"
                            @click="checkUpNow">Check up now</v-btn>
                    </v-row>
                </v-expand-transition>
                <v-expand-transition>
                    <v-row
                        v-if="checkUpError"
                        justify="center"
                        align="center">
                        <v-img
                            width="64"
                            height="64"
                            max-width="64"
                            max-height="64"
                            src="../assets/check_up_fail.svg"
                            contain
                            class="clickable" />
                        <p class="font-weight-bold ml-5 clickable text-h5">Oops, the document with such certificate does not seem to exist</p>
                    </v-row>
                    <v-container
                        v-else-if="!checkUpError && certs.length"
                        style="height: vh80;">
                        <v-col
                            class="mx-0 my-1 px-0"
                            sm="12"
                            md="12"
                            lg="12">
                            <v-toolbar
                                color="#FFFFFF00"
                                height="80"
                                elevation="0"
                                class="inples__settings__payment-method">
                                <v-col
                                    class="mx-0 px-0"
                                    cols="2">
                                    <v-btn
                                        elevation="0"
                                        color="transparent"
                                        class="font-weight-bold inples__table__item">Name</v-btn>
                                </v-col>
                                <v-col class="mx-0 px-0"
                                    cols="2">
                                    <v-btn
                                        elevation="0"
                                        color="transparent"
                                        class="font-weight-bold inples__table__item">Date</v-btn>
                                </v-col>
                                <v-col
                                    class="mx-0 px-0"
                                    cols="2">
                                    <v-btn
                                        elevation="0"
                                        color="transparent"
                                        class="font-weight-bold inples__table__item">Type</v-btn>
                                </v-col>
                            </v-toolbar>
                        </v-col>
                        <v-col
                            v-for="(timestamp, index) in certs"
                            :key="index"
                            class="mx-0 my-1 px-0 h-100"
                            cols="12"
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12">
                            <v-toolbar
                                color="#FFFFFF44"
                                height="80"
                                elevation="0"
                                class="inples__settings__payment-method inples__timestamp clickable">
                                <v-col
                                    class="mx-0 px-0 overflow-hidden"
                                    cols="2">
                                    <v-btn
                                    elevation="0"
                                    color="transparent"
                                    class="font-weight-bold inples__table__item">{{getTimestampName(timestamp)}}</v-btn>
                                </v-col>
                                <v-col
                                    class="mx-0 px-0 overflow-hidden"
                                    cols="2">
                                    <v-btn elevation="0"
                                    color="transparent"
                                    class="font-weight-bold inples__table__item">{{getTimestampDate(timestamp)}}</v-btn>
                                </v-col>
                                <v-col class="mx-0 px-0"
                                    cols="2">
                                    <v-btn
                                        elevation="0"
                                        color="transparent"
                                        class="font-weight-bold inples__table__item">{{getTimestampType(timestamp)}}</v-btn>
                                </v-col>
                            </v-toolbar>
                        </v-col>
                    </v-container>
                </v-expand-transition>
            </v-container>
        </v-container>
        <v-footer
            class="px-15"
            app
            color="transparent">
            <v-col cols="3">
                <p
                    class="inples__label clickable"
                    @click="openLink('https://www.inples.io/')">
                    What is Inples
                </p>
                <p class="inples__label clickable">
                    Reviews
                </p>
                <p class="inples__label clickable">
                    Media Centre
                </p>
                <p class="inples__label clickable">
                    General Questions
                </p>
            </v-col>
            <v-col cols="3">
                <p class="inples__label clickable">
                    Timestamp
                </p>
                <p class="inples__label clickable">
                    Checking the certificate
                </p>
                <p class="inples__label clickable">
                    Search for copyright
                </p>
                <p class="inples__label clickable">
                    infringement
                </p>
            </v-col>
            <v-col cols="3">
                <p class="inples__label clickable">
                    Terms & Conditions
                </p>
                <p class="inples__label clickable">
                    Privacy Policy
                </p>
                <p class="inples__label clickable">
                    Cookies Policy
                </p>
                <p class="inples__label clickable">
                    Legal evidence
                </p>
            </v-col>
            <v-col cols="3">
                <p class="inples__label clickable">
                    Contacts
                </p>
                <p class="inples__label clickable">
                    Twitter
                </p>
                <p class="inples__label clickable">
                    Instagram
                </p>
                <p
                    class="inples__label clickable"
                    @click="openLink('https://www.linkedin.com/company/inples/')">
                    LinkedIn
                </p>
            </v-col>
            <p class="black--text">
                © {{getCurrentYear}} Inples Co., ltd. All Rights Reserved.
            </p>
        </v-footer>
    </v-col>
</template>

<script>
import { ref } from 'vue'
import { API_DOMAIN } from '../utils/consts'
import axios from 'axios'
import moment from 'moment'
import { mapMutations } from 'vuex'

export default {
    setup () {
        const cert = ref('')
        const checkUpError = ref(null)
        const certs = ref([])
        return {
            cert,
            checkUpError,
            certs,
        }
    },
    computed: {
        getCurrentYear () {
          return moment().format('YYYY')
        },
    },
    mounted () {
        const query = this.$route.query
        const cert = query.cert
        if (cert) {
            this.cert = cert
            this.checkUpNow()
        }
    },
    methods: {
        ...mapMutations([
            'SET_TIMESTAMP',
        ]),
        openLink (url) {
            window.open(url)
        },
        signIn () {
            window.location = 'https://app.inples.io/'
        },
        checkUpNow () {
            axios({
                url: `${API_DOMAIN}/api/stamps/search-by-id/?q=${this.cert}`,
                headers: {
                },
            })
            .then((response) => {
                console.log(response)
                if (!response.data.length) {
                    this.checkUpError = 'Cert is not found'
                } else {
                    this.checkUpError = null
                    this.certs = response.data
                    if (this.certs.length) {
                        const timestamp = this.certs[0]
                        this.SET_TIMESTAMP({ timestamp })
                        this.$router.push({ name: 'verify-detail', query: { id: timestamp.id } })
                    }
                }
            })
            .catch((error) => {
                // handle error
                const response = error.response
                console.log(response)
            })
        },
        getTimestampName (timestamp) {
            let title = ''
            const rid = timestamp.rid
            if (rid) {
                title = rid.title
            }
            return title
        },
        getTimestampDate (timestamp) {
            let date = ''
            const rid = timestamp.rid
            if (rid) {
                const data = rid.created_at
                if (data) {
                    date = `${moment(data).format('DD.MM.YYYY hh:mm a')} UTM`
                }
            }
            return date
        },
        getTimestampType (timestamp) {
            let type = ''
            const rid = timestamp.rid
            if (rid) {
                type = rid.type
            }
            return type
        },
        
    }
}
</script>
<style scoped>
    .inples__label {
        color: #171776;
    }
    .inples__field__rounded {
        border-radius: 60px;
    }
</style>
