import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomePageView from '../views/HomePageView.vue'
import SettingsPageView from '../views/SettingsPageView.vue'
import TimestampPageView from '../views/TimestampPageView.vue'
import LoginPageView from '../views/LoginPageView.vue'
import RegisterPageView from '../views/RegisterPageView.vue'
import RidPageView from '../views/RidPageView.vue'
import ThanksPageView from '../views/ThanksPageView.vue'
import UploadFilesPageView from '../views/UploadFilesPageView.vue'
import VerifyPageView from '../views/VerifyPageView.vue'
import VerifyDetailPageView from '../views/VerifyDetailPageView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/dashboard',
    name: 'home',
    component: HomePageView
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsPageView
  },
  {
    path: '/timestamp',
    name: 'timestamp',
    component: TimestampPageView
  },
  {
    path: '/',
    name: 'login',
    component: LoginPageView
  },
  {
    path: '/signup',
    name: 'register',
    component: RegisterPageView
  },
  {
    path: '/rid',
    name: 'rid',
    component: RidPageView
  },
  {
    path: '/thanks',
    name: 'thanks',
    component: ThanksPageView
  },
  {
    path: '/upload',
    name: 'upload',
    component: UploadFilesPageView
  },
  {
    path: '/verify',
    name: 'verify',
    component: VerifyPageView
  },
  {
    path: '/verify/detail',
    name: 'verify-detail',
    component: VerifyDetailPageView
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
