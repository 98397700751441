import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"d-flex flex-column justify-center align-center"},[_c(VCard,{staticClass:"inples__card d-flex flex-column align-center pa-5",attrs:{"elevation":"0"}},[_c(VCardTitle,{staticClass:"headline inples__card__title"},[_c(VImg,{attrs:{"src":require("../assets/thanks.svg"),"contain":"","width":"64","height":"53.33"}})],1),_c(VCardSubtitle,[_c('p',{staticClass:"text-subtitle-1 text-center ma-5"},[_vm._v("Almost done. Please check"),_c('br'),_vm._v("your email and follow the link"),_c('br'),_vm._v("to verify in order to complete the registration")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }