<template>
    <PersonalAreaMiddleware>
      <v-col
        style="height: calc(100vh);">
        <v-container
          style="overflow-y: scroll;"
          class="inples__tab__content">
          <v-expand-transition>
            <v-toolbar
              color="transparent"
              flat>
              <v-row
                justify="start">
                <p class="inples__label font-weight-medium">{{addNewProgress}}%</p>
                <v-col
                  class="ml-2 my-0 pa-0"
                  cols="10">
                  <v-progress-linear
                    style="pointer-events: none; border-radius: 26px;"
                    color="#171776"
                    height="16"
                    rounded="26"
                    background-color="#fff"
                    rounded-bar
                    v-model="addNewProgress" />
                </v-col>
              </v-row>
            </v-toolbar>
          </v-expand-transition>
          <v-container v-if="addNewCursor === 1">
            <v-expand-transition>
              <v-form ref="detailsOfTheApplicantForm">
                <p class="display-1">Details of the applicant</p>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5">
                    <p>First name</p>
                    <v-text-field
                      :rules="nameRules"
                      v-model="firstName"
                      placeholder="First name"
                      rounded
                      filled
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field" />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5"
                    class="ml-lg-5">
                      <p>Last name</p>
                      <v-text-field
                        :rules="nameRules"
                        v-model="lastName"
                        placeholder="Last name"
                        rounded
                        filled
                        background-color="rgba(255, 255, 255, 0.7)"
                        class="inples__field" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5">
                    <p>National id</p>
                    <v-text-field
                      :rules="nationalIdRules"
                      v-model="nationalId"
                      placeholder="National id"
                      rounded
                      filled
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field" />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5"
                    class="ml-lg-5">
                    <p>Country</p>
                    <v-text-field
                      :rules="countryRules"
                      v-model="country"
                      placeholder="Country"
                      rounded
                      filled
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5">
                    <p>Address</p>
                    <v-text-field
                      :rules="addressRules"
                      v-model="address"
                      placeholder="Address"
                      rounded
                      filled
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field" />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5"
                    class="ml-lg-5">
                    <p>Email</p>
                    <v-text-field
                      :rules="emailRules"
                      v-model="email"
                      placeholder="Email"
                      rounded
                      filled
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="text-capitalize inples__btn my-5"
                    variant="text"
                    @click="back(25)">
                    <v-row
                      justify="center"
                      align="center">
                      <v-col>
                        <v-icon style="background-color: rgba(255, 255, 255, 0.5); border-radius: 100%;" class="align-self-center">mdi-chevron-left</v-icon>
                      </v-col>
                      <v-col>
                        <span class="inples__label">Back</span>
                      </v-col>
                    </v-row>
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    width="284"
                    height="60"
                    color="#171776"
                    class="white--text text-capitalize inples__btn my-5"
                    variant="text"
                    @click="checkBrief(50)">Next step</v-btn>
                  <v-col cols="2" />
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
          <v-container v-if="addNewCursor === 2">
            <v-expand-transition>
              <v-form ref="theWorkForm">
                <p class="display-1">The work</p>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5">
                    <p>Title of the work</p>
                    <v-text-field
                      :rules="workTitleRules"
                      v-model="workTitle"
                      placeholder="Title of the work"
                      rounded
                      filled
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field" />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5"
                    class="ml-lg-5">
                      <p>Year of creation</p>
                      <v-text-field
                        :rules="[
                          (v) => Number.isInteger(Number(v)) || 'Year is required',
                          (v) => Number(v) > getCurrentYear - 100 || 'Year is required',
                          (v) => Number(v) <= getCurrentYear || 'Year is required',
                        ]"
                        v-model="year"
                        placeholder="Year of creation"
                        rounded
                        filled
                        background-color="rgba(255, 255, 255, 0.7)"
                        class="inples__field" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5">
                    <p>Has the work been published yet?</p>
                    <v-select
                      :rules="workPublishedRules"
                      v-model="workPublished"
                      :items="workPublishedItems"
                      placeholder="Select an option"
                      filled
                      shaped
                      rounded
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field"
                      @keydown.tab="switchCursor"  />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5"
                    class="ml-lg-5">
                      <p>Type of work</p>
                      <v-text-field
                        :rules="workTypeRules"
                        readonly
                        v-model="workType"
                        placeholder="Select an option"
                        rounded
                        filled
                        append-icon="mdi-plus"
                        background-color="rgba(255, 255, 255, 0.7)"
                        class="inples__field"
                        @click:append="isShowTypes = true"  />
                  </v-col>
                </v-row>
                <v-col cols="10">
                  <v-chip-group
                    v-if="isShowTypes"
                    column
                    variant="outlined"
                    outlined
                    selected-class="text-deep-purple-darken-4"
                    color="#171776">
                    <v-chip
                      text-color="#171776"
                      color="#171776"
                      variant="outlined"
                      outlined
                      v-for="(type, i) in workTypeItems"
                      :key="i"
                      @click="workType = type; isShowTypes = false">{{type}}</v-chip>
                  </v-chip-group>
                </v-col>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="10"
                    xl="10"
                    no-resize>
                    <p>Description of the work (if applicable)</p>
                    <v-textarea
                      v-model="workDesc"
                      no-resize
                      rounded
                      filled
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field" />
                  </v-col>
                  <v-col
                    sm="12"
                    md="12"
                    lg="5">
                  </v-col>
                </v-row>
                <v-row>
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="text-capitalize inples__btn my-5"
                    variant="text"
                    @click="back(38)">
                    <v-row justify="center" align="center">
                      <v-col>
                        <v-icon style="background-color: rgba(255, 255, 255, 0.5); border-radius: 100%;" class="align-self-center">mdi-chevron-left</v-icon>
                      </v-col>
                      <v-col>
                        <span class="inples__label">Back</span>
                      </v-col>
                    </v-row>  
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    width="284"
                    height="60"
                    color="#171776"
                    class="white--text text-capitalize inples__btn my-5"
                    variant="text"
                    @click="checkBrief(60)">Next step</v-btn>
                  <v-col cols="2" />
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
          <v-container v-if="addNewCursor === 3">
            <v-expand-transition>
              <v-form ref="aboutTheAuthorForm">
                <p class="display-1">About the author(s)</p>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5">
                    <p class="inples__label">Authored by</p>
                    <v-select
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field"
                      filled
                      shaped
                      rounded
                      v-model="author"
                      :items="authorItems"
                      placeholder="Authored by"
                      :rules="[
                        (v) => author.length || 'Author is required'
                      ]"
                      @keydown.tab="switchCursor" />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="5"
                    xl="5">
                  </v-col>
                </v-row>
                <v-container
                  v-if="author && isGroupAuthors"
                  class="ma-0 pa-0">
                  <div
                    v-for="(author, i) in authors"
                    :key="i"
                    class="ma-0 pa-0">
                    <v-divider
                      v-if="i > 0"
                      class="my-5" />
                    <v-row>
                      <p class="display-1">{{ `Author ${i + 1}` }}</p>
                      <v-spacer />
                      <v-btn
                        v-if="i > 0"
                        elevation="0"
                        class="text-capitalize"
                        color="transparent"
                        @click="deleteAuthor(i)">
                        <v-row justify="center" align="center">
                          <v-col>
                            <span>Delete author</span>
                          </v-col>
                          <v-col>
                            <v-img
                              class="text-center"
                              height="24"
                              contain
                              src="../assets/delete.svg" />
                          </v-col>
                        </v-row>  
                      </v-btn>
                      <v-col cols="2" />
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        sm="10"
                        md="10"
                        lg="5"
                        xl="10">
                        <p class="inples__label">Full name</p>
                        <v-text-field
                          :rules="nameRules"
                          v-model="authors[i].fullName"
                          placeholder="Full name"
                          rounded
                          filled
                          background-color="rgba(255, 255, 255, 0.7)"
                          class="inples__field" />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="10"
                        md="10"
                        lg="5"
                        xl="5"
                        class="ml-lg-5">
                          <p class="inples__label">Date of birth</p>
                          <v-text-field
                            readonly
                            :rules="bornRules"
                            v-model="authors[i].born"
                            placeholder="Date of birth"
                            rounded
                            filled
                            background-color="rgba(255, 255, 255, 0.7)"
                            class="inples__field">
                            <template #append>
                              <v-menu
                                content-class="elevation-0"
                                class="elevation-0"
                                :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on">mdi-calendar-month</v-icon>
                                </template>
                                <v-date-picker
                                  v-model="authors[i].born"
                                  class="my-2"
                                  elevation="0" />
                              </v-menu>
                            </template>
                          </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        sm="10"
                        md="10"
                        lg="5"
                        xl="5">
                        <p class="inples__label">Nationality</p>
                        <v-autocomplete
                          filled
                          shaped
                          rounded
                          background-color="rgba(255, 255, 255, 0.7)"
                          v-model="authors[i].nationality"
                          :items="getNationalities()"
                          placeholder="Nationality"
                          :rules="nationalityRules"
                          class="inples__field"
                          @keydown.tab="switchCursor"
                          @click="switchCursor" />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="10"
                        md="10"
                        lg="5"
                        xl="5"
                        class="ml-lg-5">
                          <p class="inples__label">Residential address</p>
                          <v-text-field
                            :rules="addressRules"
                            v-model="authors[i].address"
                            placeholder="Residential address"
                            rounded
                            filled
                            background-color="rgba(255, 255, 255, 0.7)"
                            class="inples__field" />
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        sm="10"
                        md="10"
                        lg="5"
                        xl="5">
                        <p class="inples__label">Phone</p>
                        <v-text-field
                          :rules="phoneRules"
                          v-model="authors[i].phone"
                          placeholder="Phone"
                          rounded
                          filled
                          background-color="rgba(255, 255, 255, 0.7)"
                          class="inples__field" />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="10"
                        md="10"
                        lg="5"
                        xl="5"
                        class="ml-lg-5">
                          <p class="inples__label">Email</p>
                          <v-text-field
                            :rules="emailRules"
                            v-model="authors[i].email"
                            placeholder="Email"
                            rounded
                            filled
                            background-color="rgba(255, 255, 255, 0.7)"
                            class="inples__field" />
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        sm="10"
                        md="10"
                        lg="5"
                        xl="5">
                        <p class="inples__label">Creative pseudonym</p>
                        <v-text-field
                          :rules="pseudonymRules"
                          v-model="authors[i].pseudonym"
                          placeholder="Creative pseudonym (to be filled in if only one author)"
                          rounded
                          filled
                          background-color="rgba(255, 255, 255, 0.7)"
                          class="inples__field" />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="10"
                        md="10"
                        lg="5"
                        xl="5"
                        class="ml-lg-5">
                          <p class="inples__label">Publish only with a creative pseudonym</p>
                          <v-select
                            :rules="pseudonymPublishRules"
                            placeholder="Select an option"
                            filled
                            shaped
                            rounded
                            background-color="rgba(255, 255, 255, 0.7)"
                            class="inples__field"
                            v-model="authors[i].pseudonymPublish"
                            :items="pseudonymPublishItems"
                            @keydown.tab="switchCursor" />
                      </v-col>
                    </v-row>
                  </div>
                  <v-container
                    class="ma-0 pa-0">
                    <v-row class=" mx-0">
                      <v-btn
                        border="1"
                        elevation="0"
                        width="160"
                        height="60"
                        color="#171776"
                        class="text-capitalize inples__btn my-5 inples__outlined-btn mx-0"
                        variant="outlined"
                        outlined
                        @click="addAuthor">
                        <v-row
                          justify="center"
                          align="center"
                          class="mx-0">
                          <v-col>
                            <span class="inples__label">Add author</span>
                          </v-col>
                          <v-col>
                            <v-icon color="#171776">mdi-plus</v-icon>  
                          </v-col>
                        </v-row>  
                      </v-btn>
                    </v-row>
                    <p>Distribution of copyright between co-authors</p>
                    <v-row>
                      <v-col
                        v-for="(author, i) in authors"
                        :key="i"
                        cols="12"
                        sm="12"
                        md="12"
                        lg="5">
                        <p class="inples__label">{{`Author ${i + 1}/${author.fullName}`}}</p>
                        <v-text-field
                          :rules="splitRules"
                          v-model="authors[i].split"
                          placeholder="Enter value (%)"
                          rounded
                          filled
                          background-color="rgba(255, 255, 255, 0.7)"
                          class="inples__field" />
                      </v-col>
                    </v-row>
                    <v-row v-if="authors.length">
                      <v-spacer />
                      <v-btn
                        border="1"
                        elevation="0"
                        width="160"
                        height="60"
                        outlined
                        color="#171776"
                        class="text-capitalize inples__btn my-5 inples__outlined-btn"
                        variant="text"
                        @click="evenSplit">
                        <v-row justify="center" align="center">
                          <v-col>
                            <span class="inples__label">Even split</span>
                          </v-col>
                          <v-col>
                            <v-icon color="#171776">mdi-plus</v-icon>  
                          </v-col>
                        </v-row>
                      </v-btn>
                      <v-col cols="2" />
                    </v-row>
                  </v-container>
                </v-container>
                <v-container
                  v-else-if="author"
                  class="ma-0 pa-0">
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      sm="10"
                      md="10"
                      lg="5"
                      xl="5">
                      <p class="inples__label">Full name</p>
                      <v-text-field
                        :rules="nameRules"
                        v-model="fullName"
                        placeholder="Full name"
                        rounded
                        filled
                        background-color="rgba(255, 255, 255, 0.7)"
                        class="inples__field" />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="10"
                      md="10"
                      lg="5"
                      xl="5"
                      class="ml-lg-5">
                        <p class="inples__label">Date of birth</p>
                        <v-text-field
                          readonly
                          :rules="bornRules"
                          v-model="born"
                          placeholder="Date of birth"
                          rounded
                          filled
                          background-color="rgba(255, 255, 255, 0.7)"
                          class="inples__field">
                          <template #append>
                            <v-menu
                              content-class="elevation-0"
                              class="elevation-0"
                              :close-on-content-click="false">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on">mdi-calendar-month</v-icon>
                              </template>
                              <v-date-picker
                                v-model="born"
                                class="my-2"
                                elevation="0" />
                            </v-menu>
                          </template>
                        </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      sm="10"
                      md="10"
                      lg="5"
                      xl="5">
                      <p class="inples__label">Nationality</p>
                      <v-autocomplete
                        filled
                        shaped
                        rounded
                        background-color="rgba(255, 255, 255, 0.7)"
                        v-model="nationality"
                        :items="getNationalities()"
                        placeholder="Nationality"
                        :rules="nationalityRules"
                        class="inples__field"
                        @keydown.tab="switchCursor"
                        @click="switchCursor" />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="10"
                      md="10"
                      lg="5"
                      xl="5"
                      class="ml-lg-5">
                        <p class="inples__label">Residential address</p>
                        <v-text-field
                          :rules="addressRules"
                          v-model="residentialAddress"
                          placeholder="Residential address"
                          rounded
                          filled
                          background-color="rgba(255, 255, 255, 0.7)"
                          class="inples__field" />
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      sm="10"
                      md="10"
                      lg="5"
                      xl="5">
                      <p class="inples__label">Phone</p>
                      <v-text-field
                        :rules="phoneRules"
                        v-model="phone"
                        placeholder="Phone"
                        rounded
                        filled
                        background-color="rgba(255, 255, 255, 0.7)"
                        class="inples__field" />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="10"
                      md="10"
                      lg="5"
                      xl="5"
                      class="ml-lg-5">
                        <p class="inples__label">Email</p>
                        <v-text-field
                          :rules="emailRules"
                          v-model="authorEmail"
                          placeholder="Email"
                          rounded
                          filled
                          background-color="rgba(255, 255, 255, 0.7)"
                          class="inples__field" />
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      sm="10"
                      md="10"
                      lg="5"
                      xl="5">
                      <p class="inples__label">Creative pseudonym</p>
                      <v-text-field
                        :rules="pseudonymRules"
                        v-model="pseudonym"
                        placeholder="Creative pseudonym (to be filled in if only one author)"
                        rounded
                        filled
                        background-color="rgba(255, 255, 255, 0.7)"
                        class="inples__field" />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="10"
                      md="10"
                      lg="5"
                      xl="5"
                      class="ml-lg-5">
                        <p class="inples__label">Publish only with a creative pseudonym</p>
                        <v-select
                          :rules="pseudonymPublishRules"
                          placeholder="Select an option"
                          filled
                          shaped
                          rounded
                          background-color="rgba(255, 255, 255, 0.7)"
                          class="inples__field"
                          v-model="pseudonymPublish"
                          :items="pseudonymPublishItems"
                          @keydown.tab="switchCursor" />
                    </v-col>
                  </v-row>
                </v-container>
                <v-row
                  v-if="author"
                  no-gutters>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5">
                    <p class="inples__label">Juristic person full name</p>
                    <v-text-field
                      :rules="holderRules"
                      v-model="holder"
                      placeholder="Juristic person full name"
                      rounded
                      filled
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field" />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                    lg="5"
                    xl="5"
                    class="ml-lg-5">
                      <p class="inples__label">Attitude of the applicant/owner towards the author</p>
                      <v-text-field
                        v-model="attitude"
                        placeholder="Attitude of the applicant/owner towards the author"
                        rounded
                        filled
                        background-color="rgba(255, 255, 255, 0.7)"
                        class="inples__field" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="text-capitalize inples__btn my-5"
                    variant="text"
                    @click="back(50)">
                    <v-row
                      justify="center"
                      align="center">
                      <v-col>
                        <v-icon style="background-color: rgba(255, 255, 255, 0.5); border-radius: 100%;" class="align-self-center">mdi-chevron-left</v-icon>
                      </v-col>
                      <v-col>
                        <span class="inples__label">Back</span>
                      </v-col>
                    </v-row>  
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    v-if="author"
                    width="284"
                    height="60"
                    color="#171776"
                    class="white--text text-capitalize inples__btn my-5"
                    variant="text"
                    :disabled="!isHaveAuthors"
                    @click="checkBrief(100)">Next step</v-btn>
                  <v-col cols="2" />
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
          <v-container v-if="addNewCursor === 4">
            <v-expand-transition>
              <v-form ref="termsOfUseAndDistributionForm">
                <p class="text-h5 font-weight-medium">Terms of use and distribution</p>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="3"
                    xl="5">
                    <p class="inples__label">Permitted use for third parties</p>
                    <v-select
                      placeholder="Select an option"
                      filled
                      shaped
                      rounded
                      background-color="rgba(255, 255, 255, 0.7)"
                      class="inples__field"
                      v-model="permitted"
                      :items="permitDisplay"
                      @keydown.tab="switchCursor" />
                  </v-col>
                  <v-row v-if="permitted !== 'Permission is granted to use the work free of charge under the conditions described in the licence:'">
                    <v-spacer />
                    <v-col
                      cols="5"
                      sm="5"
                      md="5"
                      lg="4"
                      xl="4">
                      <v-checkbox
                        color="#171776"
                        v-model="termsOfUse">
                        <template v-slot:label>
                          <p class="my-0 py-0">I have read and accept<br />the <a
                                                                                target="_blank"
                                                                                href="https://www.inples.io/terms-conditions/"
                                                                                @click="openLink('https://www.inples.io/terms-conditions/')">Terms & Conditions</a></p>
                        </template>  
                      </v-checkbox>
                    </v-col>
                    <v-col
                      cols="5"
                      sm="5"
                      md="5"
                      lg="3"
                      xl="3">
                      <v-checkbox
                        color="#171776"
                        v-model="proofOfOriginality">
                        <template v-slot:label>
                          <p class="my-0 py-0">Proof of<br />originality</p>
                        </template>  
                      </v-checkbox>
                    </v-col>
                    <v-col cols="2" />
                  </v-row>
                  <v-col
                    v-if="permitted === 'Permission is granted to use the work free of charge under the conditions described in the licence:'"
                    cols="12"
                    sm="12"
                    md="12"
                    lg="5"
                    class="ml-lg-5">
                      <p class="inples__label">Do you want to be credited for your work?</p>
                      <v-select
                        placeholder="Select an option"
                        filled
                        shaped
                        rounded
                        background-color="rgba(255, 255, 255, 0.7)"
                        class="inples__field"
                        v-model="credited"
                        :items="creditedItems"
                        :rules="creditedRules"
                        @keydown.tab="switchCursor" />
                  </v-col>
                </v-row>
                <v-row
                  v-if="permitted === 'Permission is granted to use the work free of charge under the conditions described in the licence:'"
                  no-gutters>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="5">
                      <p class="inples__label">Do you allow adaptations of your work to be published?</p>
                      <v-select
                        placeholder="Select an option"
                        filled
                        shaped
                        rounded
                        background-color="rgba(255, 255, 255, 0.7)"
                        class="inples__field"
                        v-model="adaptationsPublished"
                        :items="adaptationsPublishedItems"
                        @keydown.tab="switchCursor" />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="5"
                    class="ml-lg-5">
                      <p class="inples__label">Do you allow commercial use of your work?</p>
                      <v-select
                        placeholder="Select an option"
                        filled
                        shaped
                        rounded
                        background-color="rgba(255, 255, 255, 0.7)"
                        class="inples__field"
                        v-model="commercial"
                        :items="commercialItems"
                        @keydown.tab="switchCursor" />
                  </v-col>
                </v-row>
                <v-row
                  v-if="permitted === 'Permission is granted to use the work free of charge under the conditions described in the licence:'"
                  no-gutters>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="5">
                    <v-card
                      elevation="0"
                      class="inples__card my-2 pa-3">
                      <v-card-title>
                        <p class="ma-0 pa-0 text-subtitle-1">{{licenses.length ? licenses[0] : ''}}</p>
                        <v-spacer />
                        <v-btn 
                          icon
                          @click="toggleLicenses">
                          <v-icon>{{ isShowLicenses ? 'mdi-close' : 'mdi-chevron-right' }}</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text
                        v-if="isShowLicenses"
                        class="px-8">
                        <v-row
                          v-for="(licenseItem, i) in licenseItems"
                          :key="i">
                          <p
                            class="inples__label clickable"
                            @click="toggleLicense(licenseItem)">{{licenseItem}}</p>
                          <v-spacer />
                          <div
                            class="pa-2 d-flex justify-center align-center"
                            :style="`border-radius: 100%; background-color: ${licenses.includes(licenseItem) ? '#171776' : 'transparent'}; width: 30px; height: 30px;`">
                            <v-img
                              width="16"
                              height="16"
                              :src="licenses.includes(licenseItem) ? require('../assets/attached.svg') : require('../assets/attach.svg')"
                              contain
                              class="clickable"
                              @click="openLicense(licenseLinks[i])" />
                          </div>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-spacer />
                  <v-col
                    sm="5"
                    md="5"
                    lg="2">
                    <v-checkbox
                      color="#171776"
                      v-model="termsOfUse">
                      <template v-slot:label>
                        <p class="my-0 py-0">I have read and accept<br />the <a
                                                                                target="_blank"
                                                                                href="https://www.inples.io/terms-conditions/"
                                                                                @click="openLink('https://www.inples.io/terms-conditions/')">Terms & Conditions</a></p>
                      </template>  
                    </v-checkbox>
                  </v-col>
                  <v-col
                    sm="5"
                    md="5"
                    lg="2">
                      <v-checkbox
                        color="#171776"
                        v-model="proofOfOriginality">
                        <template v-slot:label>
                          <p class="my-0 py-0">Proof of<br />originality</p>
                        </template>  
                      </v-checkbox>
                  </v-col>
                  <v-col cols="2" />
                </v-row>
                <v-col cols="10">
                  <v-divider class="my-5" />
                </v-col>
                <p class="text-h5 font-weight-medium">Congrats, you are 1 step away from defending your work!</p>
                <v-row>
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="text-capitalize inples__btn my-5"
                    variant="text"
                    @click="back(60)">
                    <v-row justify="center" align="center">
                      <v-col>
                        <v-icon style="background-color: rgba(255, 255, 255, 0.5); border-radius: 100%;" class="align-self-center">mdi-chevron-left</v-icon>
                      </v-col>
                      <v-col>
                        <span class="inples__label">Back</span>
                      </v-col>
                    </v-row>  
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    width="284"
                    height="60"
                    color="#171776"
                    class="white--text text-capitalize inples__btn my-5"
                    variant="text"
                    :disabled="!isCreateTimestampEnabled"
                    @click="createTimestamp()">
                    <v-row
                      class="px-2"
                      justify="space-between"
                      align="center">
                        <span class="white--text">Create timestamp</span>
                        <v-icon color="#fff">mdi-chevron-right</v-icon>
                    </v-row>
                  </v-btn>
                  <v-col cols="2" />
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-container>
        <v-dialog
          v-model="dialog"
          class="inples__card"
          :close-on-back="false"
          persistent>
          <v-card class="pa-5">
              <v-card-text>
              <p class="inples__label">Your copyright work data has been sent to the blockchain for recording. Once the recording is done, we will send a message and certificate of registration of the copyright work by email.</p>
              </v-card-text>
              <v-card-actions>
              <v-spacer />
              <v-btn
                  color="#171776"
                  class="inples__btn white--text"
                  @click="goToApp">Go to the app</v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </PersonalAreaMiddleware>
</template>

<script>
import Vue, { ref } from 'vue'
import axios from 'axios'
import { API_DOMAIN, INPLES_FILE_UUID_KEY } from '../utils/consts'
import PersonalAreaMiddleware from '@/components/middlewares/PersonalAreaMiddleware.vue'
import moment from 'moment'

let form = null
var licenseTimeout = []

export default Vue.extend({
  name: 'RigPageView',
  setup () {
    const scrollOffset = ref(0)
    const name = ref('')
    const nationalId = ref('')
    const nationality = ref('')
    const address = ref('')
    const phone = ref('')
    const email = ref('')
    const workTitle = ref('')
    const workDesc = ref('')
    const year = ref('')
    const workType = ref('')
    const workPublished = ref('')
    const author = ref('')
    const authorDetails = ref('')
    const pseudonym = ref('')
    const pseudonymPublish = ref('')
    const distribution = ref('100')
    const holder = ref('')
    const attitude = ref('')
    const permitted = ref('')
    const credited = ref('')
    const adaptationsPublished = ref('')
    const commercial = ref('')
    const license = ref('')
    const licenses = ref([])
    const licensesDetails = ref('')
    const licensingDatesAndConditions = ref('')
    const proofOfOriginality = ref(false)
    const termsOfUse = ref(false)
    const authorName = ref('')
    const authorship = ref('')
    const licenseItems = ref([])
    const licenseLinks = ref([
        'https://www.gnu.org/licenses/gpl-3.0.txt',
        'https://www.gnu.org/licenses/lgpl-3.0.txt',
        'https://www.gnu.org/licenses/fdl-1.3.txt',
        'https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32017D0863&from=en',
        'https://www.gnu.org/licenses/gpl-3.0.txt',
      ])
      
    const licensesItems = ref([])
    const permittedItems = ref([])
    const creditedItems = ref([])
    const adaptationsPublishedItems = ref([])
    const commercialItems = ref([])
    const authorItems = ref([])
    const workTypeItems = ref([])
    const workPublishedItems = ref([])
    const pseudonymPublishItems = ref([
      'Yes',
      'No'
    ])
    const attitudeItems = ref([])
    const licensesInUse = ref([])
    const nameRules = ref([
      (v) => !!v || 'Name is required'
    ])
    const nationalIdRules = ref([
      (v) => !!v || 'National Id is required'
    ])
    const nationalityRules = ref([
      (v) => !!v || 'Nationality is required'
    ])
    const addressRules = ref([
      (v) => !!v || 'Address is required'
    ])
    const phoneRules = ref([
      (v) => !!v || 'Phone is required',
      (v) => Number.isInteger(Number(v))  || 'Phone is required'
    ])
    const emailRules = ref([
      (v) => !!v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) || 'Email is required'
    ])
    const workTitleRules = ref([
      (v) => !!v || 'Work title is required'
    ])
    const workDescRules = ref([
      (v) => true || 'Work description is required'
    ])
    const yearRules = ref([
      (v) => !!v  || 'Year is required',
      (v) => Number.isInteger(Number(v))  || 'Year is required',
    ])
    const workTypeRules = ref([
      (v) => !!v || 'Work type is required'
    ])
    const workPublishedRules = ref([
      (v) => true || 'Work published is required'
    ])
    const authorDetailsRules = ref([
      (v) => !!v || 'Author details is required'
    ])
    const pseudonymRules = ref([
      (v) => true || 'Pseudonym is required'
    ])
    const pseudonymPublishRules = ref([
      (v) => !!v || 'Pseudonym publish is required'
    ])
    const distributionRules = ref([
      (v) => !!v || 'Distribution is required'
    ])
    const holderRules = ref([
      (v) => true || 'Holder is required'
    ])
    const attitudeRules = ref([
      (v) => !!v || 'Attitude is required'
    ])
    const permittedRules = ref([
      (v) => !!v || 'Permitted is required'
    ])
    const creditedRules = ref([
      (v) => !!v || 'Credited is required'
    ])
    const adaptationsPublishedRules = ref([
      (v) => !!v || 'Adaptations published is required'
    ])
    const commercialRules = ref([
      (v) => !!v || 'Commercial is required'
    ])
    const licenseRules = ref([
      (v) => !!v || 'License is required'
    ])
    const licensesRules = ref([
      (v) => !!v || 'Licenses is required'
    ])
    const licensesDetailsRules = ref([
      (v) => !!v || 'Licenses details is required'
    ])
    const licensingDatesAndConditionsRules = ref([
      (v) => !!v || 'Licensing dates and conditions is required'
    ])
    const proofOfOriginalityRules = ref([
      (v) => true || 'Proof of originality is required'
    ])
    const authorNameRules = ref([
      (v) => true || 'Author name is required'
    ])
    const authorshipRules = ref([
      (v) => true || 'Authorship is required'
    ])
    const termsOfUseRules = ref([
      (v) => !!v || 'Terms of use is required'
    ])
    const nationalities = ref(`Afghan
Albanian
Algerian
American
Andorran
Angolan
Anguillan
Citizen of Antigua and Barbuda
Argentine
Armenian
Australian
Austrian
Azerbaijani
Bahamian
Bahraini
Bangladeshi
Barbadian
Belarusian
Belgian
Belizean
Beninese
Bermudian
Bhutanese
Bolivian
Citizen of Bosnia and Herzegovina
Botswanan
Brazilian
British
British Virgin Islander
Bruneian
Bulgarian
Burkinan
Burmese
Burundian
Cambodian
Cameroonian
Canadian
Cape Verdean
Cayman Islander
Central African
Chadian
Chilean
Chinese
Colombian
Comoran
Congolese (Congo)
Congolese (DRC)
Cook Islander
Costa Rican
Croatian
Cuban
Cymraes
Cymro
Cypriot
Czech
Danish
Djiboutian
Dominican
Citizen of the Dominican Republic
Dutch
East Timorese
Ecuadorean
Egyptian
Emirati
English
Equatorial Guinean
Eritrean
Estonian
Ethiopian
Faroese
Fijian
Filipino
Finnish
French
Gabonese
Gambian
Georgian
German
Ghanaian
Gibraltarian
Greek
Greenlandic
Grenadian
Guamanian
Guatemalan
Citizen of Guinea-Bissau
Guinean
Guyanese
Haitian
Honduran
Hong Konger
Hungarian
Icelandic
Indian
Indonesian
Iranian
Iraqi
Irish
Israeli
Italian
Ivorian
Jamaican
Japanese
Jordanian
Kazakh
Kenyan
Kittitian
Citizen of Kiribati
Kosovan
Kuwaiti
Kyrgyz
Lao
Latvian
Lebanese
Liberian
Libyan
Liechtenstein citizen
Lithuanian
Luxembourger
Macanese
Macedonian
Malagasy
Malawian
Malaysian
Maldivian
Malian
Maltese
Marshallese
Martiniquais
Mauritanian
Mauritian
Mexican
Micronesian
Moldovan
Monegasque
Mongolian
Montenegrin
Montserratian
Moroccan
Mosotho
Mozambican
Namibian
Nauruan
Nepalese
New Zealander
Nicaraguan
Nigerian
Nigerien
Niuean
North Korean
Northern Irish
Norwegian
Omani
Pakistani
Palauan
Palestinian
Panamanian
Papua New Guinean
Paraguayan
Peruvian
Pitcairn Islander
Polish
Portuguese
Prydeinig
Puerto Rican
Qatari
Romanian
Russian
Rwandan
Salvadorean
Sammarinese
Samoan
Sao Tomean
Saudi Arabian
Scottish
Senegalese
Serbian
Citizen of Seychelles
Sierra Leonean
Singaporean
Slovak
Slovenian
Solomon Islander
Somali
South African
South Korean
South Sudanese
Spanish
Sri Lankan
St Helenian
St Lucian
Stateless
Sudanese
Surinamese
Swazi
Swedish
Swiss
Syrian
Taiwanese
Tajik
Tanzanian
Thai
Togolese
Tongan
Trinidadian
Tristanian
Tunisian
Turkish
Turkmen
Turks and Caicos Islander
Tuvaluan
Ugandan
Ukrainian
Uruguayan
Uzbek
Vatican citizen
Citizen of Vanuatu
Venezuelan
Vietnamese
Vincentian
Wallisian
Welsh
Yemeni
Zambian
Zimbabwean`
  .split('\n'))
    const authors = ref([])
    const cursor = ref(0)
    const progress = ref(30)
    const isFormValid = ref(30)
    const permitDisplay = ref([])
    const permitValues = ref([])
    const licenseIssuedDisplay = ref([])
    const licenseIssuedValues = ref([])
    const commercialDisplay = ref([])
    const commercialValues = ref([])
    const adaptationsDisplay = ref([])
    const adaptationsValues = ref([])
    const creditedDisplay = ref([])
    const creditedValues = ref([])
    const typeDisplay = ref([])
    const typeValues = ref([])
    const attitudeDisplay = ref([])
    const attitudeValues = ref([])
    const authoredByDisplay = ref([])
    const authoredByValues = ref([])
    const publishedDisplay = ref([])
    const publishedValues = ref([])
    const licenseValues = ref([])
    const licenseDisplay = ref([])
    const dialog = ref(false)
    const token = ref('')
    const addNewCursor = ref(1)
    const addNewProgress = ref(38)
    const isShowTypes = ref(false)
    const country = ref('')
    const countryRules = ref([
      (v) => !!v || 'Country is required'
    ])
    const born = ref('')
    const bornRules = ref([
      (v) => !!v || 'Date of birth is required'
    ])
    const isShowLicenses = ref(true)
    return {
      scrollOffset,
      name,
      nationalId,
      nationality,
      address,
      phone,
      email,
      workTitle,
      workDesc,
      year,
      workType,
      workPublished,
      author,
      authorDetails,
      pseudonym,
      pseudonymPublish,
      distribution,
      holder,
      attitude,
      permitted,
      credited,
      adaptationsPublished,
      commercial,
      license,
      licenses,
      licensesDetails,
      licensingDatesAndConditions,
      proofOfOriginality,
      termsOfUse,
      nameRules,
      nationalIdRules,
      nationalityRules,
      addressRules,
      phoneRules,
      emailRules,
      workTitleRules,
      workDescRules,
      yearRules,
      workTypeRules,
      workPublishedRules,
      authorDetailsRules,
      pseudonymRules,
      pseudonymPublishRules,
      distributionRules,
      holderRules,
      attitudeRules,
      permittedRules,
      creditedRules,
      adaptationsPublishedRules,
      commercialRules,
      licenseRules,
      licensesRules,
      licensesDetailsRules,
      licensingDatesAndConditionsRules,
      proofOfOriginalityRules,
      authorNameRules,
      authorshipRules,
      termsOfUseRules,
      nationalities,
      authors,
      licenseItems,
      licenseLinks,
      licensesItems,
      permittedItems,
      creditedItems,
      adaptationsPublishedItems,
      commercialItems,
      authorItems,
      workTypeItems,
      workPublishedItems,
      pseudonymPublishItems,
      attitudeItems,
      licensesInUse,
      authorName,
      authorship,
      cursor,
      progress,
      isFormValid,
      permitDisplay,
      permitValues,
      licenseIssuedDisplay,
      licenseIssuedValues,
      commercialDisplay,
      commercialValues,
      adaptationsDisplay,
      adaptationsValues,
      creditedDisplay,
      creditedValues,
      typeDisplay,
      typeValues,
      attitudeDisplay,
      attitudeValues,
      authoredByDisplay,
      authoredByValues,
      publishedDisplay,
      publishedValues,
      licenseValues,
      licenseDisplay,
      dialog,
      token,
      addNewCursor,
      addNewProgress,
      isShowTypes,
      country,
      countryRules,
      born,
      bornRules,
      isShowLicenses
    }
  },
  computed: {
    getCurrentYear () {
      return moment().format('YYYY')
    },
    isCreateTimestampEnabled () {
      const isFirstStep = this.termsOfUse && this.proofOfOriginality && this.permitted.length && this.permitted !== 'Permission is granted to use the work free of charge under the conditions described in the licence:'
      const isSecondStep = this.termsOfUse && this.proofOfOriginality && this.permitted.length && this.permitted === 'Permission is granted to use the work free of charge under the conditions described in the licence:' && this.credited.length && this.adaptationsPublished.length && this.commercial.length
      return isFirstStep || isSecondStep
    },
    isHideProgress () {
      const result = this.scrollOffset < 150
      return result
    },
    isDisableNext () {
      const isProgressNotCompleted = this.progress < 100
      const isFormNotValid = !this.isFormValid
      const isNotAcceptTerms = !this.termsOfUse
      const result = isProgressNotCompleted || isFormNotValid || isNotAcceptTerms
      return result
    },
    isHaveAuthors () {
      return this.authors.length > 0 || !this.isGroupAuthors
    },
    isGroupAuthors () {
      const groupDisplay = this.authorItems[1]
      const result = this.author === groupDisplay
      return result
    }
  },
  watch: {
    cursor (val) {
      this.progress += 14
    },
    name (val) {
      this.autoCompleteDetails()
      this.switchCursor()
    },
    nationalId (val) {
      // this.autoCompleteDetails()
      this.switchCursor()
    },
    address (val) {
      this.autoCompleteDetails()
      this.switchCursor()
    },
    email (val) {
      this.autoCompleteDetails()
      this.switchCursor()
    },
    workTitle (val) {
      this.switchCursor()
    },
    workDesc (val) {
      this.switchCursor()
    },
    year (val) {
      this.switchCursor()
    },
    workType (val) {
      this.switchCursor()
    },
    workPublished (val) {
      this.switchCursor()
    },
    author (val) {
      this.switchCursor()
    },
    distribution (val) {
      this.switchCursor()
    },
    pseudonym (val) {
      this.switchCursor()
    },
    permitted (val) {
      this.switchCursor()
    },
    licenses (val) {
      this.switchCursor()
    },
    nationality (val) {
      this.switchCursor()
    }
  },
  mounted () {
    form = this.$refs.form
    document.addEventListener('scroll', () => {
      this.scrollOffset = window.scrollY
    })
    axios({
      method: 'get',
      url: `${API_DOMAIN}/api/rids/rid-choices/`,
      headers: {
        'Authorization': `Bearer ${this.$store.state.token}`
      }
    })
      .then((response) => {
        // handle success
        console.log(response)
        const { data } = response
        const types = data.type
        const license = data.license
        const commercial = data.commercial
        const credited = data.credited
        const permit = data.permit
        const attitude = data.attitude
        const isPublished = data.is_published
        const authoredBy = data.authored_by
        const licenseIssued = data.license_issued
        const adaptations = data.adaptations
        const authoredByValues = authoredBy.map(e => e.value)
        const authoredByDisplay = authoredBy.map(e => e.display)
        this.authorItems = authoredByDisplay
        this.authoredByValues = authoredByValues
        this.authoredByDisplay = authoredByDisplay
        const licenseValues = license.map(e => e.value)
        const licenseDisplay = license.map(e => e.display)
        this.licenseItems = licenseDisplay
        this.licenseValues = licenseValues
        this.licenseDisplay = licenseDisplay
        const attitudeValues = attitude.map(e => e.value)
        const attitudeDisplay = attitude.map(e => e.display)
        this.attitudeItems = attitudeDisplay
        this.attitudeValues = attitudeValues
        this.attitudeDisplay = attitudeDisplay
        this.commercialDisplay = attitudeDisplay
        this.commercialValues = attitudeValues
        const commercialValues = commercial.map(e => e.value)
        const commercialDisplay = commercial.map(e => e.display)
        this.commercialItems = commercialDisplay
        this.commercialDisplay = commercialDisplay
        this.commercialValues = commercialValues
        const adaptationsValues = adaptations.map(e => e.value)
        const adaptationsDisplay = adaptations.map(e => e.display)
        this.adaptationsPublishedItems = adaptationsDisplay
        this.adaptationsDisplay = adaptationsDisplay
        this.adaptationsValues = adaptationsValues
        const creditedValues = credited.map(e => e.value)
        const creditedDisplay = credited.map(e => e.display)
        this.creditedItems = creditedDisplay
        this.creditedDisplay = creditedDisplay
        this.creditedValues = creditedValues
        const isPublishedValues = isPublished.map(e => e.value)
        const isPublishedDisplay = isPublished.map(e => e.display)
        this.workPublishedItems = isPublishedDisplay
        this.publishedDisplay = isPublishedDisplay
        this.publishedValues = isPublishedValues
        const permitValues = permit.map(e => e.value)
        const permitDisplay = permit.map(e => e.display)
        this.permittedItems = permit
        this.permitDisplay = permitDisplay
        this.permitValues = permitValues
        const licenseIssuedValues = licenseIssued.map(e => e.value)
        const licenseIssuedDisplay = licenseIssued.map(e => e.display)
        this.licensesItems = licenseIssuedDisplay
        this.licenseIssuedDisplay = licenseIssuedDisplay
        this.licenseIssuedValues = licenseIssuedValues
        const typesValues = types.map(e => e.value)
        const typesDisplay = types.map(e => e.display)
        this.workTypeItems = typesDisplay
        this.typeDisplay = typesDisplay
        this.typeValues = typesValues
        
        const user = this.$store.state.user
        this.firstName = user.first_name
        this.lastName = user.last_name
        this.nationalId = user.doc_id
        this.country = user.country
        this.address = user.address
        this.email = user.email
        this.pseudonym = user.pseudonym

      })
      .catch((response) => {
        // handle error
        console.log(response)
      })
  },
  methods: {
    openLicense (link) {
      window.open(link)
    },
    toggleLicense (val) {
      this.licenses = []
      if (this.licenses.includes(val)) {
        this.licenses = this.licenses.filter(license => license !== val)
      } else {
        this.licenses.push(val)
      }
      licenseTimeout.map(val => clearTimeout(licenseTimeout))
      licenseTimeout = []
      licenseTimeout.push(setTimeout(() => this.closeLicenses(), 1000))
    },
    closeLicenses () {
      if (this.licenses.length) {
        this.isShowLicenses = false
      }
    },
    toggleLicenses () {
      if (this.licenses.length) {
        this.isShowLicenses = !this.isShowLicenses
      }
    },
    createTimestamp () {
      const json = {}
      json.name = this.fullNameVal
      json.national_id = this.nationalIdVal
      json.nationality = this.nationalityVal
      json.address = this.addressVal
      json.phone = this.phoneVal
      json.email = this.emailVal
      json.title = this.workTitleVal
      if (this.workDescVal.length) {
        json.description = this.workDescVal
      }
      json.year = this.yearVal
      if (this.workTypeVal.length) {
        const index = this.typeDisplay.indexOf(this.workTypeVal)
        const type = this.typeValues[index]
        json.type = type
      }
      if (this.workPublishedVal.length) {
        const index = this.publishedDisplay.indexOf(this.workPublishedVal)
        const isPublished = this.publishedValues[index]
        json.is_published = isPublished
      }
      if (this.authorVal.length) {
        const index = this.authoredByDisplay.indexOf(this.authorVal)
        const authoredBy = this.authoredByValues[index]
        json.authored_by = authoredBy
      }
      if (!this.isGroupAuthors) {
        if (this.fullNameVal.length || this.nationalIdVal.length || this.addressVal.length || this.emailVal.length)
          this.authorDetails = `${this.fullNameVal}`
        if (this.nationalIdVal.length)
          this.authorDetails += ` ${this.nationalIdVal}`
        if (this.addressVal.length)
          this.authorDetails += ` ${this.addressVal}`
        if (this.phoneVal.length)
          this.authorDetails += ` ${this.phoneVal}`
        if (this.emailVal.length)
          this.authorDetails += ` ${this.emailVal}`
        if (!this.fullNameVal.length && !this.nationalIdVal.length && !this.addressVal.length && !this.emailVal.length) {
          this.authorDetails = ''
        }
      }
      json.author_details = this.authorDetails
      json.pseudonym = this.pseudonymVal
      if (this.pseudonymPublishVal.length) {
        json.publish_by_pseudonym = this.pseudonymPublishVal
      }
      json.distribution = this.distribution
      json.right_holder = this.holderVal
      if (this.attitudeVal.length) {
        const index = this.attitudeDisplay.indexOf(this.attitudeVal)
        const attitude = this.attitudeValues[index]
        json.attitude = attitude
      }
      if (this.permitted.length) {
        const index = this.permitDisplay.indexOf(this.permitted)
        const permitted = this.permitValues[index]
        json.permit = permitted
      }
      if (this.credited.length) {
        const index = this.creditedDisplay.indexOf(this.credited)
        const credited = this.creditedValues[index]
        json.credited = credited
      }
      if (this.adaptationsPublished.length) {
        const index = this.adaptationsDisplay.indexOf(this.adaptationsPublished)
        const adaptations = this.adaptationsValues[index]
        json.adaptations = adaptations
      }
      if (this.commercial.length) {
        const index = this.commercialDisplay.indexOf(this.commercial)
        const commercial = this.commercialValues[index]
        json.commercial = commercial
      }
      if (this.licenses.length) {
        const index = this.licenseDisplay.indexOf(this.licenses[0])
        const license = this.licenseValues[index]
        json.license = license
      }
      if (this.proofOfOriginality.length) {
        json.proof = this.proofOfOriginality
      }
      json.file_token = this.$route.query.file
      axios({
        method: 'post',
        url: `${API_DOMAIN}/api/rids/`,
        data: json,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.$store.state.token}`
        }
      })
        .then((response) => {
          // handle success
          console.log(response)
          // this.goToApp()
          this.dialog = true
        })
        .catch((error) => {
          // handle error
          const response = error.response
          console.log(response)
          const data = response.data
          const keys = Object.keys(data)
          const key = keys[0]
          const dataItem = data[key]
          let msg = dataItem[0]
          if (!msg) {
            msg = 'Error'
          }
          this.notificationContent = msg
          this.hasShowNotification = true
        })
    },
    checkBrief(val) {
      let form = this.$refs.termsOfUseAndDistributionForm
      if (val === 50) {
        form = this.$refs.detailsOfTheApplicantForm
        this.emailVal = this.email
        this.addressVal = this.address
        this.nationalIdVal = this.nationalId
        this.yearVal = this.year
        this.countryVal = this.country
        this.firstNameVal = this.firstName
        this.lastNameVal = this.lastName
      } else if (val === 60) {
        form = this.$refs.theWorkForm
        this.workTitleVal = this.workTitle
        this.workTypeVal = this.workType
        this.workPublishedVal = this.workPublished
        this.workDescVal = this.workDesc
        this.yearVal = this.year
      } else if (val === 100) {
        form = this.$refs.aboutTheAuthorForm
        this.authorVal = this.author
        this.pseudonymPublishVal = this.pseudonymPublish
        this.attitudeVal = this.attitude
        this.holderVal = this.holder
        this.pseudonymVal = this.pseudonym
        this.nationalityVal = this.nationality
        if (this.authors.length) {
          this.distribution = this.authors.join(',')
        }
        this.fullNameVal = this.fullName
        this.phoneVal = this.phone
        this.bornVal = this.born
        this.residentialAddressVal = this.residentialAddress
        this.authorEmailVal = this.authorEmail
      }
      const isValid = form.validate()
      if (isValid) {
        this.addNewCursor++
        this.addNewProgress = val
        form.reset()
      }
    },
    back (val) {
      if (val === 25) {
        this.$router.go(-1)
      } else {
        this.addNewCursor--
        this.addNewProgress = val
      }
      if (val === 38) {
        this.email = this.emailVal
        this.address = this.addressVal
        this.nationalId = this.nationalIdVal
        this.year = this.yearVal
        this.country = this.countryVal
        this.firstName = this.firstNameVal
        this.lastName = this.lastNameVal
      } else if (val === 50) {
        this.workTitle = this.workTitleVal
        this.workType = this.workTypeVal
        this.workPublished = this.workPublishedVal
        this.workDesc = this.workDescVal
        this.year = this.yearVal
      } else if (val === 60) {
        this.author = this.authorVal
        this.pseudonymPublish = this.pseudonymPublishVal
        this.attitude = this.attitudeVal
        this.holder = this.holderVal
        this.pseudonym = this.pseudonymVal
        this.nationality = this.nationalityVal
        this.fullName = this.fullNameVal
        this.phone = this.phoneVal
        this.born = this.bornVal
        this.residentialAddress = this.residentialAddressVal
        this.authorEmail = this.authorEmailVal
      }
    },
    goToApp () {
      this.$router.push({name: 'home'})
    },
    next () {
      const isValid = form.validate()
      this.isFormValid = isValid
      if (!isValid) {
        return
      }
      const json = {
      }
      json.name = this.name
      json.national_id = this.nationalId
      json.nationality = this.nationality
      json.address = this.address
      json.phone = this.phone
      json.email = this.email
      json.title = this.workTitle
      if (this.workDesc.length) {
        json.description = this.workDesc
      }
      json.year = this.year
      if (this.workType.length) {
        const index = this.typeDisplay.indexOf(this.workType)
        const type = this.typeValues[index]
        json.type = type
      }
      if (this.workPublished.length) {
        const index = this.publishedDisplay.indexOf(this.workPublished)
        const isPublished = this.publishedValues[index]
        json.is_published = isPublished
      }
      if (this.author.length) {
        const index = this.authoredByDisplay.indexOf(this.author)
        const authoredBy = this.authoredByValues[index]
        json.authored_by = authoredBy
      }
      json.author_details = this.authorDetails
      json.pseudonym = this.pseudonym
      if (this.pseudonymPublish.length) {
        json.publish_by_pseudonym = this.pseudonymPublish
      }
      json.distribution = this.distribution
      json.right_holder = this.holder
      if (this.attitude.length) {
        const index = this.attitudeDisplay.indexOf(this.attitude)
        const attitude = this.attitudeValues[index]
        json.attitude = attitude
      }
      if (this.permitted.length) {
        const index = this.permitDisplay.indexOf(this.permitted)
        const permitted = this.permitValues[index]
        json.permit = permitted
      }
      if (this.credited.length) {
        const index = this.creditedDisplay.indexOf(this.credited)
        const credited = this.creditedValues[index]
        json.credited = credited
      }
      if (this.adaptationsPublished.length) {
        const index = this.adaptationsDisplay.indexOf(this.adaptationsPublished)
        const adaptations = this.adaptationsValues[index]
        json.adaptations = adaptations
      }
      if (this.commercial.length) {
        const index = this.commercialDisplay.indexOf(this.commercial)
        const commercial = this.commercialValues[index]
        json.commercial = commercial
      }
      if (this.license.length) {
        const index = this.licenseDisplay.indexOf(this.license)
        const license = this.licenseValues[index]
        json.license = license
      }
      if (this.licenses.length) {
        const index = this.licenseIssuedDisplay.indexOf(this.licenses)
        const license = this.licenseIssuedValues[index]
        json.license_issued = license
      }
      json.details = this.licensesDetails
      json.conditions = this.licensingDatesAndConditions
      if (this.proofOfOriginality.length) {
        json.proof = this.proofOfOriginality
      }
      json.file_token = this.$route.query.file
      axios({
          method: 'post',
          url: `${API_DOMAIN}/api/rids/`,
          data: json,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        })
          .then((response) => {
            // handle success
            console.log(response)
            this.cursor = 0
            this.name = ''
            this.nationalId = ''
            this.nationality = ''
            this.address = ''
            this.phone = ''
            this.email = ''
            this.title = ''
            this.workDesc = ''
            this.year = ''
            this.workType = ''
            this.workPublished = ''
            this.authoredBy = ''
            this.authorDetails = ''
            this.pseudonym = ''
            this.pseudonymPublish = ''
            this.distribution = ''
            this.holder = ''
            this.attitude = ''
            this.permitted = ''
            this.credited = ''
            this.adaptations = ''
            this.commercial = ''
            this.licenses = ''
            this.license = ''
            this.licensesDetails = ''
            this.licensingDatesAndConditions = ''
            this.proofOfOriginality = false
            this.termsOfUse = false
            this.progress = 30
            this.dialog = true
          })
          .catch((response) => {
            // handle error
            console.log(response)
          })
    },
    switchCursor () {
      if (this.name.length && this.nationalId.length && this.nationality.length && this.address.length && this.phone.length && this.email.length && this.cursor < 1)
        this.cursor = 1
      if (this.workTitle.length && this.year > 0 && this.workType.length && this.workPublished.length && this.cursor < 2)
        this.cursor = 2
      if (this.author.length && this.distribution.length && this.cursor < 3)
        this.cursor = 3
      if (this.permitted.length && this.cursor < 4)
        this.cursor = 4
      if (this.licenses.length && this.cursor < 5)
        this.cursor = 5
    },
    deleteLicense (val) {
      this.license = ''
    },
    autoCompleteDetails () {
      if (!this.isGroupAuthors) {
        if (this.name.length || this.nationalId.length || this.address.length || this.email.length)
          this.authorDetails = `${this.name}`
        if (this.nationalId.length)
          this.authorDetails += ` ${this.nationalId}`
        if (this.address.length)
          this.authorDetails += ` ${this.address}`
        if (this.phone.length)
          this.authorDetails += ` ${this.phone}`
        if (this.email.length)
          this.authorDetails += ` ${this.email}`
        if (!this.name.length && !this.nationalId.length && !this.address.length && !this.email.length) {
          this.authorDetails = ''
        }
      }
    },
    addAuthor () {
      if (this.authorName.length && this.authorship.length) {
        this.authors.push({
          name: this.authorName,
          percentage: this.authorship
        })
        this.authorName = ''
        this.authorship = ''
        this.check() 
      }
    },
    deleteAuthor (idx) {
      this.authors = this.authors.filter((author, index) => idx !== index)
    },
    getNationalities () {
      const filtered = this.nationalities.filter((nationality) => nationality.includes(this.nationality))
      return filtered
    },
    check () {
      const isValid = form.validate()
      this.isFormValid = isValid
      if (isValid) {
        return
      }
    }
  },
  components: {
    PersonalAreaMiddleware
  }
})
</script>
<style lang="scss" scoped>
  .inples {
    &__field {
      &__mobile {
        width: 100%;
      }
      &__desktop {
        width: 75%;
      }
    }
    &__card, &__btn {
      border-radius: 14px !important;
    }
    &__label {
      color: #171776;
    }
  }
  .clickable {
    cursor: pointer;
  }
</style>