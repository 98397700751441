<template>
  <PersonalAreaMiddleware>
    <v-snackbar
      v-model="hasShowNotification"
      :timeout="5000"
      position="absolute"
      location="top"
      top
      width="100vh"
      min-width="100vh"
      height="65px"
      class="inples__snackbar">{{notificationContent}}</v-snackbar>
    <v-card style="height: 100% !important; min-height: 100% !important;">
      <v-layout
        :style="{ backgroundImage: `url(${require('@/assets/background.png')})`, backgroundSize: 'cover', height: '100%', minHeight: '100%', '-webkit-filter': $vuetify.theme.dark ? 'saturate(0)' : 'none' }"
        class="ma-0">
        <v-navigation-drawer
          :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 312 : 'auto'"
          :border="0"
          class="pa-5 d-flex align-center"
          permanent
          app
          :mini-variant="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'"
          :elevation="0"
          style="height: 100% !important; display: flex; justify-content: center; background-color: rgba(255, 255, 255, 0.5); border: 0px solid transparent !important; outline: 0px !important;"
          ref="drawer">
          <template v-slot:append>
            <v-list-item
              :class="{'justify-space-between': true, 'clickable': true, 'align-self-start': true}"
              @click="logout">
              <v-row
                v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'"
                align="center"
                align-content="center"
                justify-sm="center"
                :class="{'align-self-center': true}">
                  <v-img
                    src="../assets/logout.svg"
                    contain
                    max-width="24" />
                  <v-col>
                    <p class="align-self-center ml-3 my-0 inples__label">Logout</p>
                  </v-col>
                  <p class="version">{{version.length ? `v. ${version}` : ''}}</p>
              </v-row>
              <v-col
                v-else
                align="center"
                justify-sm="center">
                  <v-img
                    src="../assets/logout.svg"
                    contain
                    max-width="24" />
              </v-col>
            </v-list-item>
          </template>
          <v-list nav>
            <v-list-item
              v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'"
              :class="{'justify-space-between': true, 'clickable': true}"
              @click="select('Personal data')">
              <v-row
                v-if="($vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm')"
                justify="center">
                <v-list-item-icon
                  class="ma-0">
                  <v-btn
                    icon
                    @click="back">
                    <v-icon color="#171776">mdi-chevron-left</v-icon>
                  </v-btn>
                </v-list-item-icon>
              </v-row>
            </v-list-item>
            <v-row
              v-else
              justify="space-between"
              class="clickable"
              @click="back">
              <v-col cols="1">
                <v-icon
                  style="background-color: rgba(255, 255, 255, 0.5); border-radius: 100%;"
                  class="align-self-center"
                  color="#171776">mdi-chevron-left</v-icon>
              </v-col>
              <v-col>
                <p class="align-self-center ml-3 inples__label">Back</p>
              </v-col>
            </v-row>
            <v-list-item
              :class="{'inples__settings__selected-item': isSelected('Personal data'), 'justify-space-between': true, 'clickable': true}"
              @click="select('Personal data')">
              <v-row
                v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'"
                justify="center">
                <v-list-item-icon
                  class="ma-0">
                  <v-icon color="#171776">mdi-account</v-icon>
                </v-list-item-icon>
              </v-row>
              <v-row
                v-else
                style="display: flex; align-items: center; justify-content: space-between;">
                  <v-col>
                      <p class="inples__label align-self-center ml-3 my-0">Personal data</p>
                  </v-col>
                  <v-icon
                    class="mx-5 align-self-center"
                    color="#171776">mdi-chevron-right</v-icon>
              </v-row>
            </v-list-item>
            <v-list-item :class="{'inples__settings__selected-item': isSelected('Password change'), 'justify-space-between': true, 'clickable': true}"
              @click="select('Password change')">
              <v-row
                v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'"
                justify="center">
                <v-list-item-icon
                  class="ma-0">
                  <v-icon color="#171776">mdi-lock</v-icon>
                </v-list-item-icon>
              </v-row>
              <v-row v-else>
                  <v-col>
                      <p class="inples__label align-self-center ml-3 my-0">Password change</p>
                  </v-col>
                  <v-icon
                    class="mx-5 align-self-center"
                    color="#171776">mdi-chevron-right</v-icon>
              </v-row>
            </v-list-item>
            <v-list-item :class="{'inples__settings__selected-item': isSelected('Notifications'), 'justify-space-between': true, 'clickable': true}"
              @click="select('Notifications')">
              <v-row
                v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'"
                justify="center">
                <v-list-item-icon
                  class="ma-0">
                  <v-icon color="#171776">mdi-bell</v-icon>
                </v-list-item-icon>
              </v-row>
              <v-row v-else>
                  <v-col>
                      <p class="inples__label align-self-center ml-3 my-0">Notifications</p>
                  </v-col>
                  <v-icon
                    class="mx-5 align-self-center"
                    color="#171776">mdi-chevron-right</v-icon>
              </v-row>
            </v-list-item>
            <v-list-item :class="{'inples__settings__selected-item': isSelected('Plans'), 'justify-space-between': true, 'clickable': true}"
              @click="select('Plans')">
              <v-row
                v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'"
                justify="center">
                <v-list-item-icon
                  class="ma-0">
                  <v-icon color="#171776">mdi-credit-card-outline</v-icon>
                </v-list-item-icon>
              </v-row>
              <v-row v-else>
                  <v-col>
                      <p class="inples__label align-self-center ml-3 my-0">Plans</p>
                  </v-col>
                  <v-icon
                    class="mx-5 align-self-center"
                    color="#171776">mdi-chevron-right</v-icon>
              </v-row>
            </v-list-item>
            <v-list-item
              v-if="false"
              :class="{'inples__settings__selected-item': isSelected('Payment details'), 'justify-space-between': true, 'clickable': true}"
              @click="select('Payment details')">
              <v-row
                v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'"
                justify="center">
                <v-list-item-icon
                  class="ma-0">
                  <v-icon color="#171776">mdi-credit-card-outline</v-icon>
                </v-list-item-icon>
              </v-row>
              <v-row v-else>
                  <v-col>
                      <p class="inples__label align-self-center ml-3 my-0">Payment details</p>
                  </v-col>
                  <v-icon
                    class="mx-5 align-self-center"
                    color="#171776">mdi-chevron-right</v-icon>
              </v-row>
            </v-list-item>
            <v-list-item
              v-if="false"
              :class="{'inples__settings__selected-item': isSelected('Payment methods'), 'justify-space-between': true, 'clickable': true}"
              @click="select('Payment methods')">
              <v-row
                v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'"
                justify="center">
                <v-list-item-icon
                  class="ma-0">
                  <v-icon color="#171776">mdi-credit-card-outline</v-icon>
                </v-list-item-icon>
              </v-row>
              <v-row v-else>
                  <v-col>
                      <p class="inples__label align-self-center ml-3 my-0">Payment methods</p>
                  </v-col>
                  <v-icon
                    class="mx-5 align-self-center"
                    color="#171776">mdi-chevron-right</v-icon>
              </v-row>
            </v-list-item>
            <v-list-item
              :class="{'inples__settings__selected-item': isSelected('Purchases'), 'justify-space-between': true, 'clickable': true}"
              @click="select('Purchases')">
              <v-row
                v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'"
                justify="center">
                <v-list-item-icon
                  class="ma-0">
                  <v-icon color="#171776">mdi-credit-card-outline</v-icon>
                </v-list-item-icon>
              </v-row>
              <v-row v-else>
                  <v-col>
                      <p class="inples__label align-self-center ml-3 my-0">Purchases</p>
                  </v-col>
                  <v-icon
                    class="mx-5 align-self-center"
                    color="#171776">mdi-chevron-right</v-icon>
              </v-row>
            </v-list-item>
            <v-list-item
              v-if="false"
              :class="{'inples__settings__selected-item': isSelected('Registration profiles'), 'justify-space-between': true, 'clickable': true}"
              @click="select('Registration profiles')">
              <v-row
                v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'"
                justify="center">
                <v-list-item-icon
                  class="ma-0">
                  <v-icon color="#171776">mdi-account-group</v-icon>
                </v-list-item-icon>
              </v-row>
              <v-row v-else>
                  <v-col>
                      <p class="inples__label align-self-center ml-3 my-0">Registration profiles</p>
                  </v-col>
                  <v-icon
                    class="mx-5 align-self-center"
                    color="#171776">mdi-chevron-right</v-icon>
              </v-row>
            </v-list-item>
            <v-list-item
              v-if="paymentDataLink.length"
              :class="{'inples__settings__selected-item': isSelected('Registration profiles'), 'justify-space-between': true, 'clickable': true}"
              @click="openLink(paymentDataLink)">
              <v-row
                v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'"
                justify="center">
                <v-list-item-icon
                  class="ma-0">
                  <v-icon color="#171776">mdi-account-group</v-icon>
                </v-list-item-icon>
              </v-row>
              <v-row v-else>
                  <v-col>
                      <p class="inples__label align-self-center ml-3 my-0">Payment data</p>
                  </v-col>
                  <v-icon
                    class="mx-5 align-self-center"
                    color="#171776">mdi-chevron-right</v-icon>
              </v-row>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
        <v-col
          v-if="$vuetify.breakpoint.name !== 'xs' && this.$vuetify.breakpoint.name !== 'sm'"
          cols="1" />
        <v-col
          v-if="isSelected('Personal data')"
          @click="hideDrawer">
          <input
            class="d-none"
            type="file"
            ref="inputRef"
            @change="saveUserPhoto" />
          <v-toolbar
            color="transparent"
            flat>
            <v-toolbar-title class="font-weight-medium text-h5">
              Edit profile
            </v-toolbar-title>
          </v-toolbar>
          <v-container class="bg-surface-variant">
            <v-row
              no-gutters
              align="center">
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="4"
                xl="4">
                <p>User ID</p>
                <v-text-field
                  v-model="userId"
                  placeholder="userId"
                  disabled
                  rounded
                  filled
                  background-color="rgba(255, 255, 255, 0.7)"
                  class="inples__field" />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="4"
                xl="4"
                class="ma-0 pa-0 mx-lg-5">
                <v-row
                  align="center"
                  justify="end"
                  justify-sm="end"
                  justify-md="end"
                  justify-lg="end"
                  justify-xl="end"
                  class="ma-0 pa-0">
                  <v-avatar
                    style="align-self: center; border: 1px solid white;"
                    :class="{ 'd-flex': true, 'justify-center': true, 'ma-3': true, 'text-center': true }"
                    size="100"
                    width="100"
                    height="101"
                    max-width="100"
                    max-height="101"
                    @mouseover="isHover = true"
                    @mouseleave="isHover = false"
                    @click="$refs.inputRef.click()">
                    <v-img
                      :src="isHavePhoto ? photo : require('@/assets/default_avatar.png')"
                      :class="{'text-center': true , 'avatar-hover': isHover}"
                      width="105"
                      height="105">
                      <div
                        class="overlay">
                        <v-img
                          max-width="24"
                          src="@/assets/photo.svg"
                          contain />
                      </div>
                    </v-img>
                  </v-avatar>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="4"
                xl="4">
                <p>First name</p>
                <v-text-field
                  v-model="firstName"
                  placeholder="First name"
                  rounded
                  filled
                  class="inples__field"
                  :background-color="focuses[0] ? 'white' : 'rgba(255, 255, 255, 0.7)'"
                  @focus="setFocus(0, true)"
                  @blur="setFocus(0, false)" />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="4"
                xl="4"
                class="ml-lg-5">
                  <p>Last name</p>
                  <v-text-field
                    v-model="lastName"
                    placeholder="Last name"
                    rounded
                    filled
                    class="inples__field"
                    :background-color="focuses[1] ? 'white' : 'rgba(255, 255, 255, 0.7)'"
                    @focus="setFocus(1, true)"
                    @blur="setFocus(1, false)" />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="4"
                xl="4">
                <p>Nickname</p>
                <v-text-field
                  v-model="pseudonym"
                  placeholder="Nickname"
                  rounded
                  filled
                  class="inples__field"
                  :background-color="focuses[2] ? 'white' : 'rgba(255, 255, 255, 0.7)'"
                  @focus="setFocus(2, true)"
                  @blur="setFocus(2, false)" />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="4"
                xl="4"
                class="ml-lg-5">
                  <p>Email</p>
                  <v-text-field
                    v-model="email"
                    placeholder="Email"
                    rounded
                    filled
                    class="inples__field"
                    :background-color="focuses[3] ? 'white' : 'rgba(255, 255, 255, 0.7)'"
                    @focus="setFocus(3, true)"
                    @blur="setFocus(3, false)" />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="4"
                xl="4">
                <p>Phone</p>
                <v-text-field
                  v-model="phone"
                  placeholder="Phone"
                  rounded
                  filled
                  class="inples__field"
                  :background-color="focuses[4] ? 'white' : 'rgba(255, 255, 255, 0.7)'"
                  @focus="setFocus(4, true)"
                  @blur="setFocus(4, false)" />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="4"
                xl="4"
                class="ml-lg-5">
                  <p>Country</p>
                  <v-text-field
                    v-model="country"
                    placeholder="Country"
                    rounded
                    filled
                    class="inples__field"
                    :background-color="focuses[5] ? 'white' : 'rgba(255, 255, 255, 0.7)'"
                    @focus="setFocus(5, true)"
                    @blur="setFocus(5, false)" />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="4"
                xl="4">
                <p>Address</p>
                <v-text-field
                  v-model="address"
                  placeholder="Address"
                  rounded
                  filled
                  class="inples__field"
                  :background-color="focuses[6] ? 'white' : 'rgba(255, 255, 255, 0.7)'"
                  @focus="setFocus(6, true)"
                  @blur="setFocus(6, false)" />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="4"
                xl="4"
                class="ml-lg-5">
                  <p>City/town</p>
                  <v-text-field
                    v-model="city"
                    placeholder="City/town"
                    rounded
                    filled
                    class="inples__field"
                    :background-color="focuses[7] ? 'white' : 'rgba(255, 255, 255, 0.7)'"
                    @focus="setFocus(7, true)"
                    @blur="setFocus(7, false)" />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="4"
                xl="4">
                <p>Zip code</p>
                <v-text-field
                  v-model="zip"
                  placeholder="Zip code"
                  rounded
                  filled
                  class="inples__field"
                  :background-color="focuses[8] ? 'white' : 'rgba(255, 255, 255, 0.7)'"
                  @focus="setFocus(8, true)"
                  @blur="setFocus(8, false)" />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="4"
                xl="4"
                class="ml-lg-5">
                  <p>Id document number</p>
                  <v-text-field
                    v-model="docId"
                    placeholder="Id document number"
                    rounded
                    filled
                    class="inples__field"
                    :background-color="focuses[9] ? 'white' : 'rgba(255, 255, 255, 0.7)'"
                    @focus="setFocus(9, true)"
                    @blur="setFocus(9, false)" />
              </v-col>
            </v-row>
          </v-container>
          <v-footer color="transparent">
            <v-col cols="4">
              <span
                v-if="false"
                class="mx-lg-5 inples__label clickable"
                @click="deleteAccount">
                Delete my account
              </span>
              <span
                v-if="false"
                class="ml-3 mx-lg-5 inples__label clickable">
                Download personal data
              </span>
            </v-col>
            <v-col cols="4">
              <v-row>
                <v-spacer />
                <v-btn
                  width="160"
                  height="60"
                  variant="text"
                  color="#171776"
                  class="text-capitalize inples__btn my-5 white--text"
                  @click="saveUserData">Save changes</v-btn>
              </v-row>
            </v-col>
          </v-footer>
        </v-col>
        <v-col
          v-else-if="isSelected('Password change')"
          style="max-width: 100%;"
          cols="12"
          lg="4"
          xl="4"
          @click="hideDrawer">
          <v-toolbar
            color="transparent"
            flat>
            <v-toolbar-title class="font-weight-medium text-h5">
              Password change
            </v-toolbar-title>
          </v-toolbar>
          <v-container class="bg-surface-variant">
            <v-col
              sm="12"
              md="12"
              lg="12"
              xl="12">
              <p>Current password</p>
              <v-text-field
                placeholder="Current password"
                rounded
                filled
                class="inples__field"
                type="password"
                :background-color="focuses[0] ? 'white' : 'rgba(255, 255, 255, 0.7)'"
                @focus="setFocus(0, true)"
                @blur="setFocus(0, false)" />
            </v-col>
            <v-col
              sm="12"
              md="12"
              lg="12"
              xl="12">
              <p>New password</p>
              <v-text-field
                placeholder="New password"
                rounded
                filled
                class="inples__field"
                :type="show1 ? 'text' : 'password'"
                :background-color="focuses[1] ? 'white' : 'rgba(255, 255, 255, 0.7)'"
                @focus="setFocus(1, true)"
                @blur="setFocus(1, false)">
                <template #append>
                  <v-img
                    class="clickable"
                    contain
                    :src="require(!show1 ? '@/assets/pass_eye_hidden.svg' : '@/assets/pass_eye.svg')"
                    @click="show1 = !show1" />
                </template>
              </v-text-field>
            </v-col>
            <v-col
              sm="12"
              md="12"
              lg="12"
              xl="12">
              <p>Confirm new password</p>
              <v-text-field
                placeholder="Confirm new password"
                rounded
                filled
                class="inples__field"
                :type="show2 ? 'text' : 'password'"
                :background-color="focuses[2] ? 'white' : 'rgba(255, 255, 255, 0.7)'"
                @focus="setFocus(2, true)"
                @blur="setFocus(2, false)">
                <template #append>
                  <v-img
                    class="clickable"
                    contain
                    :src="require(!show2 ? '@/assets/pass_eye_hidden.svg' : '@/assets/pass_eye.svg')"
                    @click="show2 = !show2" />
                </template>
              </v-text-field>
            </v-col>
          </v-container>
          <v-footer color="transparent">
            <v-spacer />
            <v-btn
              width="160"
              height="60"
              variant="text"
              color="#171776"
              class="text-capitalize inples__btn my-5 white--text">Save changes</v-btn>
          </v-footer>
        </v-col>
        <v-col
          v-else-if="isSelected('Notifications')"
          @click="hideDrawer">
          <v-toolbar
            color="transparent"
            flat>
            <v-toolbar-title class="font-weight-medium text-h5">
              Notifications
            </v-toolbar-title>
          </v-toolbar>
          <v-container class="bg-surface-variant">
            <v-col
              sm="12"
              md="12"
              lg="3">
              <v-checkbox
                color="#171776"
                class="my-0 py-0"
                v-model="newsletter"
                @click="updateNewsletter">
                <template v-slot:label>
                  <p class="text-h6 my-0 py-0">Newsletter</p>
                </template>  
              </v-checkbox>
              <v-checkbox
                color="#171776"
                class="my-0 py-0"
                v-model="registrationCerts"
                @click="updateRegistrationCerts">
                <template v-slot:label>
                  <p class="text-h6 my-0 py-0">Registration certificates</p>
                </template>
              </v-checkbox>
              <v-checkbox
                color="#171776"
                class="my-0 py-0"
                v-model="systemNotification"
                @click="updateSystemNotification">
                <template v-slot:label>
                  <p class="text-h6 my-0 py-0">System notification</p>
                </template>
              </v-checkbox>
            </v-col>
          </v-container>
        </v-col>
        <v-col 
          v-else-if="isSelected('Plans')"
          @click="hideDrawer">
          <v-col class="mx-0">
            <v-toolbar
              color="transparent"
              flat>
              <v-toolbar-title class="font-weight-medium text-h5">
                <p>Plans</p>
              </v-toolbar-title>
              <v-spacer />
              <v-col
                cols="12"
                sm="12"
                md="9"
                lg="9"
                xl="9"
                class="mx-0">
                <v-btn-toggle
                  class="overflow-hidden"
                  borderless
                  :ripple="false"
                  style="width: 100%; background: rgba(255, 255, 255, 0.4);"
                  width="100%"
                  v-model="toggle"
                  :divided="false">
                  <v-btn
                    :class="{'white--text': toggle === 0, 'inples__btn': true, 'overflow-hidden': true}"
                    :color="toggle === 0 ?
                      '#171776'
                    :
                      'transparent'"
                    width="20%">Monthly</v-btn>
                  <v-btn
                    :class="{'white--text': toggle === 1, 'inples__btn': true, 'overflow-hidden': true}"
                    :color="toggle === 1 ?
                      '#171776'
                    :
                      'transparent'"
                    width="20%">Annually</v-btn>
                  <v-btn
                    :class="{'white--text': toggle === 2, 'inples__btn': true, 'overflow-hidden': true}"
                    :color="toggle === 2 ?
                      '#171776'
                    :
                      'transparent'"
                    width="20%">2 years</v-btn>
                  <v-btn
                    :class="{'white--text': toggle === 3, 'inples__btn': true, 'overflow-hidden': true}"
                    :color="toggle === 3 ?
                      '#171776'
                    :
                      'transparent'"
                    width="20%">3 years</v-btn>
                  <v-btn
                    :class="{'white--text': toggle === 4, 'inples__btn': true, 'overflow-hidden': true}"
                    :color="toggle === 4 ?
                      '#171776'
                    :
                      'transparent'"
                    width="20%">5 years</v-btn>
                </v-btn-toggle>
              </v-col>
            </v-toolbar>
          </v-col>
          <v-container
            class="bg-surface-variant"
            style="min-width: 100%;">
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="4"
                xl="4">
                <p
                  v-if="plan === 'Basic'"
                  class="text-h5 text-center">Your current plan</p>
                <v-card
                  class="mx-2 inples__card py-10 px-5"
                  elevation="0">
                  <v-card-title class="text-uppercase">basic</v-card-title>
                  <v-card-subtitle class="text-h5 font-weight-black black--text my-1">Free</v-card-subtitle>
                  <v-card-text class="px-0">
                    <v-list>
                      <v-list-item-group>
                        <v-list-item
                          disabled
                          class="px-0">
                          <v-list-item-icon>
                            <v-icon>mdi-circle-small</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Registration of rights<br />to works<br />up to 1 objects</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                      <v-list-item-group>
                        <v-list-item
                          disabled
                          class="px-0">
                          <v-list-item-icon>
                            <v-icon>mdi-circle-small</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Digital certificate<br />of rights registration</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                      <v-list-item-group>
                        <v-list-item
                          disabled
                          class="px-0">
                          <v-list-item-icon>
                            <v-icon>mdi-circle-small</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>1Gb of secure<br />storage of protected<br />materials</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="4"
                xl="4">
                <p
                  v-if="plan === 'Professional'"
                  class="text-h5 text-center">Your current plan</p>
                <v-card
                  :color="plan !== 'Professional' ? 'white' : 'rgba(255, 255, 255, 0.5)'"
                  :height="plan !== 'Professional' ? '100%' : 'auto'"
                  class="mx-2 inples__card py-10 px-5 d-flex flex-column"
                  elevation="0"
                  :style="{justifyContent: plan !== 'Professional' ? 'space-between' : 'flex-start'}">
                  <v-card-title class="d-block">
                    <p class="text-uppercase">Professional</p>
                    <p class="text-h5 font-weight-black black--text my-1">{{
                      toggle === 0 ?
                        '7,5'
                      : toggle === 1 ?
                        '81'
                      : toggle === 2 ?
                        '153'
                      : toggle === 3 ?
                        '189'
                      : toggle === 4 ?
                        '255'
                      :
                        '7,5'
                    }}$ / {{
                      toggle === 0 ?
                        'month'
                      : toggle === 1 ?
                        'year'
                      : toggle === 2 ?
                        '2 years'
                      : toggle === 3 ?
                        '3 years'
                      : toggle === 4 ?
                        '5 years'
                      :
                        'month'
                      }}</p>
                    <v-list color="transparent">
                      <v-list-item-group>
                        <v-list-item
                          disabled
                          class="px-0">
                          <v-list-item-icon>
                            <v-icon>mdi-circle-small</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Registration of rights to works<br />up to 100 objects</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                      <v-list-item-group>
                        <v-list-item
                          disabled
                          class="px-0">
                          <v-list-item-icon>
                            <v-icon>mdi-circle-small</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Digital certificate<br />of rights registration</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                      <v-list-item-group>
                        <v-list-item
                          disabled
                          class="px-0">
                          <v-list-item-icon>
                            <v-icon>mdi-circle-small</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>10Gb of secure storage<br />of protected materials</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                      <v-list-item-group>
                        <v-list-item
                          disabled
                          class="px-0">
                          <v-list-item-icon>
                            <v-icon>mdi-circle-small</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Registration of rights transfers<br />up to 5 per month</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                      <v-list-item-group>
                        <v-list-item
                          disabled
                          class="px-0">
                          <v-list-item-icon>
                            <v-icon>mdi-circle-small</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Customization of visibility/<br />confidentiality of registration<br />data </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                      <v-list-item-group>
                        <v-list-item
                          disabled
                          class="px-0">
                          <v-list-item-icon>
                            <v-icon>mdi-circle-small</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Multi-authoring</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card-title>
                  <v-card-actions v-if="plan !== 'Professional'">
                    <v-btn
                      bottom
                      height="60"
                      width="100%"
                      color="#171776"
                      class="white--text inples__btn"
                      :disabled="toggle === null"
                      @click="upgrade('Professional')">Upgrade</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="4"
                xl="4">
                <p
                  v-if="plan === 'Business'"
                  class="text-h5 text-center">Your current plan</p>
                <v-card
                  :color="plan !== 'Business' ? 'white' : 'rgba(255, 255, 255, 0.5)'"
                  class="mx-2 inples__card py-10 px-5"
                  elevation="0">
                  <v-card-title class="d-block">
                    <p class="text-uppercase">business</p>
                    <p class="text-h5 font-weight-black black--text my-1">{{
                      toggle === 0 ?
                        '31'
                      : toggle === 1 ?
                        '330'
                      : toggle === 2 ?
                        '624'
                      : toggle === 3 ?
                        '801'
                      : toggle === 4 ?
                        '1223'
                      :
                        '31'
                    }}$ / {{
                      toggle === 0 ?
                        'month'
                      : toggle === 1 ?
                        'year'
                      : toggle === 2 ?
                        '2 years'
                      : toggle === 3 ?
                        '3 years'
                      : toggle === 4 ?
                        '5 years'
                      :
                        'month'
                      }}</p>
                    <v-list color="transparent">
                      <v-list-item-group>
                        <v-list-item
                          disabled
                          class="px-0">
                          <v-list-item-icon>
                            <v-icon>mdi-circle-small</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Registration of rights to works<br />up to 300 objects</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                      <v-list-item-group>
                        <v-list-item
                          disabled
                          class="px-0">
                          <v-list-item-icon>
                            <v-icon>mdi-circle-small</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Digital certificate<br />of rights registration</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                      <v-list-item-group>
                        <v-list-item
                          disabled
                          class="px-0">
                          <v-list-item-icon>
                            <v-icon>mdi-circle-small</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>20Gb of secure storage<br />of protected materials</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                      <v-list-item-group>
                        <v-list-item
                          disabled
                          class="px-0">
                          <v-list-item-icon>
                            <v-icon>mdi-circle-small</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Registration of rights transfers<br />up to 50 per month</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                      <v-list-item-group>
                        <v-list-item
                          disabled
                          class="px-0">
                          <v-list-item-icon>
                            <v-icon>mdi-circle-small</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Customization of visibility/<br />confidentiality of registration<br />data</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                      <v-list-item-group>
                        <v-list-item
                          disabled
                          class="px-0">
                          <v-list-item-icon>
                            <v-icon>mdi-circle-small</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Multi-authoring</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                      <v-list-item-group>
                        <v-list-item
                          disabled
                          class="px-0">
                          <v-list-item-icon>
                            <v-icon>mdi-circle-small</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Multi-user work</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                      <v-list-item-group>
                        <v-list-item
                          disabled
                          class="px-0">
                          <v-list-item-icon>
                            <v-icon>mdi-circle-small</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Register of acquired, related and transferred rights</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                      <v-list-item-group>
                        <v-list-item
                          disabled
                          class="px-0">
                          <v-list-item-icon>
                            <v-icon>mdi-circle-small</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Own domain for work</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card-title>
                  <v-card-actions v-if="plan !== 'Business'">
                    <v-btn
                      height="60"
                      width="100%"
                      color="#171776"
                      class="white--text inples__btn"
                      :disabled="toggle === null"
                      @click="upgrade('Business')">Upgrade</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col
          v-else-if="isSelected('Payment details')"
          @click="hideDrawer">
          <v-toolbar
            color="transparent"
            flat>
            <v-toolbar-title class="font-weight-medium text-h5">
              Payment details
            </v-toolbar-title>
          </v-toolbar>
          <v-container class="bg-surface-variant">
            <v-row no-gutters>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="3">
                <p>Full name</p>
                <v-text-field
                  placeholder="Full name"
                  rounded
                  filled
                  class="inples__field"
                  :background-color="focuses[0] ? 'white' : 'rgba(255, 255, 255, 0.7)'"
                  @focus="setFocus(0, true)"
                  @blur="setFocus(0, false)" />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="3"
                class="ml-lg-5">
                  <p>Account type</p>
                  <v-text-field
                    placeholder="Account type"
                    rounded
                    filled
                    class="inples__field"
                    :background-color="focuses[1] ? 'white' : 'rgba(255, 255, 255, 0.7)'"
                    @focus="setFocus(1, true)"
                    @blur="setFocus(1, false)" />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="3">
                <p>Country</p>
                <v-text-field
                  placeholder="Country"
                  rounded
                  filled
                  class="inples__field"
                  :background-color="focuses[2] ? 'white' : 'rgba(255, 255, 255, 0.7)'"
                  @focus="setFocus(2, true)"
                  @blur="setFocus(2, false)" />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="3"
                class="ml-lg-5">
                  <p>Address</p>
                  <v-text-field
                    placeholder="Address"
                    rounded
                    filled
                    class="inples__field"
                    :background-color="focuses[3] ? 'white' : 'rgba(255, 255, 255, 0.7)'"
                    @focus="setFocus(3, true)"
                    @blur="setFocus(3, false)" />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="3">
                <p>City/town</p>
                <v-text-field
                  placeholder="City/town"
                  rounded
                  filled
                  class="inples__field"
                  :background-color="focuses[4] ? 'white' : 'rgba(255, 255, 255, 0.7)'"
                  @focus="setFocus(4, true)"
                  @blur="setFocus(4, false)" />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="3"
                class="ml-lg-5">
                  <p>Zipcode</p>
                  <v-text-field
                    placeholder="Zipcode"
                    rounded
                    filled
                    class="inples__field"
                    :background-color="focuses[5] ? 'white' : 'rgba(255, 255, 255, 0.7)'"
                    @focus="setFocus(5, true)"
                    @blur="setFocus(5, false)" />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="3">
                <p>Tax information</p>
                <v-text-field
                  placeholder="Tax information"
                  rounded
                  filled
                  class="inples__field"
                  :background-color="focuses[6] ? 'white' : 'rgba(255, 255, 255, 0.7)'"
                  @focus="setFocus(6, true)"
                  @blur="setFocus(6, false)" />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="3"
                class="ml-lg-5">
                <v-row
                  align="end"
                  justify="end">
                  <v-btn
                    width="160"
                    height="60"
                    variant="text"
                    color="#171776"
                    class="text-capitalize inples__btn my-5 white--text">Save changes</v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col
          v-else-if="isSelected('Payment methods')"
          @click="hideDrawer">
          <v-toolbar
            color="transparent"
            flat>
            <v-toolbar-title class="font-weight-medium text-h5">
              Payment methods
            </v-toolbar-title>
          </v-toolbar>
          <v-container class="bg-surface-variant">
            <v-col
              class="mx-0 my-2 px-0"
              sm="12"
              md="12"
              lg="12">
              <v-toolbar
                color="transparent"
                height="20"
                elevation="0"
                class="inples__settings__payment-method">
                <v-col
                  class="overflow-hidden mx-0 px-0"
                  cols="3">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">Card</v-btn>
                </v-col>
                <v-col
                  class="overflow-hidden mx-0 px-0"
                  cols="3">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">Expiry date</v-btn>
                </v-col>
              </v-toolbar>
            </v-col>
            <v-col class="mx-0 my-5 px-0"
              sm="12"
              md="12"
              lg="12">
              <v-toolbar
                :color="isHovering[0] ? 'white' : '#FFFFFF44'"
                height="80"
                elevation="0"
                class="overflow-hidden inples__settings__payment-method"
                @mouseover="setHover(0)"
                @mouseout="setHout(0)">
                <v-col
                  class="overflow-hidden mx-0 px-0"
                  cols="3">
                  <v-toolbar-title class="font-weight-bold inples__table__item">Mastercard **** 8314</v-toolbar-title>
                </v-col>
                <v-btn
                  elevation="0"
                  color="transparent"
                  class="overflow-hidden font-weight-bold inples__table__item">03.02.2028</v-btn>
                <v-spacer />
                <v-btn
                  icon
                  class="overflow-hidden">
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </v-toolbar>
            </v-col>
            <v-col
              class="mx-0 my-5 px-0"
              sm="12"
              md="12"
              lg="12">
              <v-toolbar
                :color="isHovering[1] ? 'white' : '#FFFFFF44'"
                height="80"
                elevation="0"
                class="overflow-hidden inples__settings__payment-method"
                @mouseover="setHover(1)"
                @mouseout="setHout(1)">
                <v-col
                  class="overflow-hidden mx-0 px-0"
                  cols="3">
                  <v-toolbar-title class="font-weight-bold">Visa **** 8314</v-toolbar-title>
                </v-col>
                <v-btn
                  elevation="0"
                  color="transparent"
                  class="overflow-hidden font-weight-bold inples__table__item">06.12.2030</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  class="overflow-hidden">
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </v-toolbar>
            </v-col>
            <v-btn
              color="#171776"
              width="252"
              height="60"
              variant="text"
              class="text-capitalize inples__btn my-5 white--text"
              style="text-transform: unset;">Add payment method</v-btn>
          </v-container>
        </v-col>
        <v-col
          v-else-if="isSelected('Purchases')"
          @click="hideDrawer">
          <v-toolbar
            color="transparent"
            flat>
            <v-toolbar-title class="font-weight-medium text-h5">
              Purchases
            </v-toolbar-title>
          </v-toolbar>
          <v-container v-if="payments.length">
            <v-col
              class="mx-0 my-2 px-0"
              sm="12"
              md="12"
              lg="12">
              <v-toolbar
                color="transparent"
                height="20"
                elevation="0"
                class="inples__settings__payment-method">
                <v-col
                  class="mx-0 px-0 overflow-hidden"
                  cols="2">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">Date</v-btn>
                </v-col>
                <v-col
                  class="mx-0 px-0 overflow-hidden"
                  cols="2">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">Description</v-btn>
                </v-col>
                <v-col
                  class="mx-0 px-0 overflow-hidden"
                  cols="2">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">Amount (USD)</v-btn>
                </v-col>
                <v-col
                  class="mx-0 px-0 overflow-hidden"
                  cols="2">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">Status</v-btn>
                </v-col>
                <v-col
                  class="mx-0 px-0 overflow-hidden"
                  cols="2">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">Link</v-btn>
                </v-col>
              </v-toolbar>
            </v-col>
            <v-col
              v-for="(payment, i) in payments"
              :key="i"
              class="mx-0 my-5 px-0"
              sm="12"
              md="12"
              lg="12">
              <v-toolbar
                height="80"
                elevation="0"
                class="inples__settings__payment-method"
                :color="isHovering[i] ? 'white' : '#FFFFFF44'"
                @mouseover="setHover(i)"
                @mouseout="setHout(i)">
                <v-col
                  class="mx-0 px-0 overflow-hidden"
                  cols="2">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">{{getPurchaseDate(payment)}}</v-btn>
                </v-col>
                <v-col
                  class="mx-0 px-0 overflow-hidden"
                  cols="2">
                  <v-btn elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">{{payment.description}}</v-btn>
                </v-col>
                <v-col
                  class="mx-0 px-0"
                  cols="2 overflow-hidden">
                  <v-btn elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">{{payment.amount}}{{payment.currency|getCurrencySymbol}}</v-btn>
                </v-col>
                <v-col
                  class="mx-0 px-0 overflow-hidden"
                  cols="2">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">Paid</v-btn>
                </v-col>
                <v-col
                  class="mx-0 px-0 overflow-hidden"
                  cols="2">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item"
                    @click="openLink(payment.invoice_url)">{{payment.invoice_url}}</v-btn>
                </v-col>
              </v-toolbar>
            </v-col>
          </v-container>
          <v-container v-else>
            <p class="inples__label ma-5 text-center">Purchases is empty</p>
          </v-container>
        </v-col>
        <v-col
          v-else-if="isSelected('Registration profiles')"
          @click="hideDrawer">
          <v-toolbar
            color="transparent"
            flat>
            <v-toolbar-title class="font-weight-medium text-h5">
              Registration profiles
            </v-toolbar-title>
          </v-toolbar>
          <v-container class="bg-surface-variant">
            <v-col
              class="mx-0 my-2 px-0"
              sm="12"
              md="12"
              lg="12">
              <v-toolbar color="transparent"
                height="20"
                elevation="0"
                class="inples__settings__payment-method">
                <v-col
                  class="mx-0 px-0 overflow-hidden"
                  cols="2">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">Name</v-btn>
                </v-col>
                <v-col
                  class="mx-0 px-0 overflow-hidden"
                  cols="2">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">Work type</v-btn>
                </v-col>
                <v-col
                  class="mx-0 px-0 overflow-hidden"
                  cols="2">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">Authors</v-btn>
                </v-col>
                <v-col
                  class="mx-0 px-0 overflow-hidden"
                  cols="2">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">Licence type</v-btn>
                </v-col>
              </v-toolbar>
            </v-col>
            <v-col
              class="mx-0 my-5 px-0"
              sm="12"
              md="12"
              lg="12">
              <v-toolbar
                height="80"
                elevation="0"
                class="inples__settings__payment-method"
                :color="isHovering[0] ? 'white' : '#FFFFFF44'"
                @mouseover="setHover(0)"
                @mouseout="setHout(0)">
                <v-col
                  class="mx-0 px-0 overflow-hidden"
                  cols="2">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">Andre</v-btn>
                </v-col>
                <v-col
                  class="mx-0 px-0 overflow-hidden"
                  cols="2">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">Branding</v-btn>
                </v-col>
                <v-col
                  class="mx-0 px-0 overflow-hidden"
                  cols="2">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">Lebedev</v-btn>
                </v-col>
                <v-col
                  class="mx-0 px-0 overflow-hidden"
                  cols="2">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    class="font-weight-bold inples__table__item">PCC</v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-btn icon>
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </v-toolbar>
            </v-col>
            <v-btn
              color="#171776"
              width="252"
              height="60"
              variant="text"
              class="text-capitalize inples__btn my-5 white--text"
              style="text-transform: unset;">New registration profile</v-btn>
          </v-container>
        </v-col>
      </v-layout>
    </v-card>
  </PersonalAreaMiddleware>
</template>

<script>
  import Vue, { ref } from 'vue'
  import { mapState, mapMutations } from 'vuex'
  import PersonalAreaMiddleware from '@/components/middlewares/PersonalAreaMiddleware.vue'
  import axios from 'axios'
  import { API_DOMAIN, INPLES_AUTH_TOKENS_KEY } from '../utils/consts'
  import moment from 'moment'

  export default Vue.extend({
    name: 'SettingsPageView',
    setup () {
      const selectedItem = ref('Personal data')
      const notifications = ref('Newsletter')
      const newsletter = ref(false)
      const registrationCerts = ref(false)
      const toggle = ref(0)
      const plan = ref('Basic')
      const isHover = ref(false)
      const isHavePhoto = ref(false)
      const userId = ref('')
      const firstName = ref('')
      const lastName = ref('')
      const email = ref('')
      const pseudonym = ref('')
      const phone = ref('')
      const country = ref('')
      const address = ref('')
      const city = ref('')
      const zip = ref('')
      const docId = ref('')
      const hasShowNotification = ref(false)
      const notificationContent = ref('')
      const rail = ref(false)
      const show1 = ref(false)
      const show2 = ref(false)
      const isHovering = ref([])
      const focuses = ref([])
      const payments = ref([])
      const paymentDataLink = ref('')
      const version = ref('')
      const photo = ref('@/assets/default_avatar.png')
      const systemNotification = ref(false)
      return {
        selectedItem,
        notifications,
        newsletter,
        registrationCerts,
        toggle,
        plan,
        isHover,
        isHavePhoto,
        userId,
        firstName,
        lastName,
        email,
        pseudonym,
        phone,
        country,
        address,
        city,
        zip,
        docId,
        hasShowNotification,
        notificationContent,
        rail,
        show1,
        show2,
        isHovering,
        focuses,
        payments,
        paymentDataLink,
        photo,
        systemNotification,
        version,
      }
    },
    computed: {
      ...mapState([
        'token',
        'user'
      ])
    },
    mounted () {
      axios({
        method: 'get',
        url: `${API_DOMAIN}/api/stats/`,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      })
        .then((response) => {
          console.log(response)
          const data = response.data
          const limits = data.limits
          this.plan = limits.name
          axios({
            method: 'get',
            url: `${API_DOMAIN}/api/payments/`,
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
            .then((response) => {
              console.log(response)
              const data = response.data
              const payments = data
              this.payments = payments
              axios({
                method: 'get',
                url: `${API_DOMAIN}/api/version/1/`,
                headers: {
                  'Authorization': `Bearer ${this.token}`
                }
              })
                .then((response) => {
                  console.log(response)
                  const data = response.data
                  this.version = data.version
                  this.$forceUpdate()
                  axios({
                    method: 'get',
                    url: `${API_DOMAIN}/api/mailsettings/`,
                    headers: {
                      'Authorization': `Bearer ${this.token}`
                    }
                  })
                    .then((response) => {
                      console.log(response)
                      const data = response.data
                      const promoEmails = data.promotional_emails
                      const certs = data.certificates
                      const systemNotifications = data.system_notifications
                      this.newsletter = promoEmails
                      this.registrationCerts = certs
                      this.systemNotification = systemNotifications
                      axios({
                        method: 'get',
                        url: `${API_DOMAIN}/api/payments/get-stripe-portal-link/`,
                        headers: {
                          'Authorization': `Bearer ${this.token}`
                        }
                      })
                        .then((response) => {
                          console.log(response)
                          const data = response.data
                          const status = data.status
                          if (status !== 'Error') {
                            this.paymentDataLink = data.link
                          }
                        })
                        .catch((response) => {
                          console.log(response)
                        })
                    })
                    .catch((response) => {
                      console.log(response)
                    })
                })
                .catch((response) => {
                  console.log(response)
                })
            })
            .catch((response) => {
              console.log(response)
            })
        })
        .catch((response) => {
          console.log(response)
        })
      this.userId = this.user.id
      this.firstName = this.user.first_name
      this.lastName = this.user.last_name
      this.email = this.user.email
      this.pseudonym = this.user.pseudonym
      this.phone = this.user.phone
      this.country = this.user.country
      this.address = this.user.address
      this.city = this.user.city
      this.zip = this.user.zip
      this.docId = this.user.doc_id
      this.rail = this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm'
      const query = this.$route.query
      const action = query.action
      if (action) {
        if (action === 'plans') {
          this.select('Plans')
        }
      }
      this.isHavePhoto = !!this.user.profile_photo
      this.photo = `${API_DOMAIN}${this.user.profile_photo}`
    },
    filters: {
      getCurrencySymbol (val) {
        let result = ''
        if (val === 'usd') {
          result = '$'
        }
        return result
      }
    },
    methods: {
      ...mapMutations([
        'SET_USER'
      ]),
      saveUserPhoto (e) {
        var link = URL.createObjectURL(e.target.files[0])
        var url = e.target.files[0]
        var bodyFormData = new FormData()
        bodyFormData.append('profile_photo', url)
        axios({
          url: `${API_DOMAIN}/api/users/${this.userId}/`,
          method: 'PATCH',
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`
          },
          data: bodyFormData
        })
          .then((response) => {
            console.log(response)
            this.isHavePhoto = true
            this.photo = link
          })
          .catch((response) => {
            console.log(response)
          })
      },
      updateNewsletter() {
        axios({
          method: 'patch',
          url: `${API_DOMAIN}/api/mailsettings/1/`,
          headers: {
            'Authorization': `Bearer ${this.token}`
          },
          data: {
            promotional_emails: this.newsletter
          }
        })
          .then((response) => {
            console.log(response)
          })
          .catch((response) => {
            console.log(response)
          })
      },
      updateRegistrationCerts() {
        axios({
          method: 'patch',
          url: `${API_DOMAIN}/api/mailsettings/1/`,
          headers: {
            'Authorization': `Bearer ${this.token}`
          },
          data: {
            certificates: this.registrationCerts
          }
        })
          .then((response) => {
            console.log(response)
          })
          .catch((response) => {
            console.log(response)
          })
      },
      updateSystemNotification() {
        axios({
          method: 'patch',
          url: `${API_DOMAIN}/api/mailsettings/1/`,
          headers: {
            'Authorization': `Bearer ${this.token}`
          },
          data: {
            system_notifications: this.systemNotification
          }
        })
          .then((response) => {
            console.log(response)
          })
          .catch((response) => {
            console.log(response)
          })
      },
      getPurchaseDate (purchase) {
        let date = ''
        const createdAt = purchase.created_at
        if (createdAt) {
          date = `${moment(createdAt).format('DD.MM.YYYY hh:mm a')} UTM`
        }
        return date
      },
      openLink (url) {
        window.open(url)
      },
      setFocus (i, val) {
        this.focuses[i] = val
        this.$forceUpdate()
      },
      setHover (i) {
        this.isHovering[i] = true
        this.$forceUpdate()
      },
      setHout (i) {
        this.isHovering[i] = false
        this.$forceUpdate()
      },
      hideDrawer () {
        if ((this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm')) {
          this.rail = true
        }
      },
      logout () {
        localStorage.removeItem(INPLES_AUTH_TOKENS_KEY)
        this.$router.push({name: 'login'})
      },
      deleteAccount () {
        const id = this.user.id
        axios({
          url: `${API_DOMAIN}/api/users/${id}/`,
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
          .then((response) => {
            console.log(response)
            this.logout()
          })
          .catch((response) => {
            console.log(response)
          })
      },
      saveUserData () {
        const data = {
          'first_name': this.firstName,
          'last_name': this.lastName,
          'phone': this.phone,
          'country': this.country,
          'address': this.address,
          'city': this.city,
          'zip': this.zip,
          'doc_id': this.docId
        }
        axios({
          url: `${API_DOMAIN}/api/users/${this.userId}/`,
          method: 'PATCH',
          headers: {
            'Authorization': `Bearer ${this.token}`
          },
          data
        })
          .then((response) => {
            console.log(response)
            const data = response.data
            const user = data[0]
            this.SET_USER({ user })
            this.hasShowNotification = true
            this.notificationContent = 'User has been updated'
          })
          .catch((response) => {
            console.log(response)
          })
      },
      upgrade (plan) {
        this.plan = plan
        let link = null
        if (this.plan === 'Professional') {
          if (this.toggle === 0) {
            link = `https://buy.stripe.com/eVacO17QV5H18XS3cc?client_reference_id=${this.user.id}`
          } else if (this.toggle === 1) {
            link = `https://buy.stripe.com/dR66pD1sxfhB6PK28a?client_reference_id=${this.user.id}`
          } else if (this.toggle === 2) {
            link = `https://buy.stripe.com/cN2dS59Z38Tdca428b?client_reference_id=${this.user.id}`
          } else if (this.toggle === 3) {
            link = `https://buy.stripe.com/eVacO19Z31qL2zuaEI?client_reference_id=${this.user.id}`
          } else if (this.toggle === 4) {
            link = `https://buy.stripe.com/28og0d9Z3fhBde89AF?client_reference_id=${this.user.id}`
          }
        } else if (this.plan === 'Business') {
          if (this.toggle === 0) {
            link = `https://buy.stripe.com/dR64hv2wB7P95LGfYZ?client_reference_id=${this.user.id}`
          } else if (this.toggle === 1) {
            link = `https://buy.stripe.com/7sI9BP6MRc5pa1W6ou?client_reference_id=${this.user.id}`
          } else if (this.toggle === 2) {
            link = `https://buy.stripe.com/8wM5lz9Z34CXb606ov?client_reference_id=${this.user.id}`
          } else if (this.toggle === 3) {
            link = `https://buy.stripe.com/cN27tHc7b9XhfmgaEM?client_reference_id=${this.user.id}`
          } else if (this.toggle === 4) {
            link = `https://buy.stripe.com/cN2cO1c7bedxa1W7sB?client_reference_id=${this.user.id}`
          }
        }
        if (link) {
          window.open(link)
        }
      },
      isSelected (item) {
        const result = item === this.selectedItem
        return result
      },
      select (val) {
        this.selectedItem = val
      },
      back () {
        this.$router.push({name: 'home'})
      }
    },
    components: {
      PersonalAreaMiddleware
    }
  })
</script>

<style scoped>
  .inples__label {
    color: #171776;
  }
  .inples__settings__selected-item {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 14px;
  }
  .inples__settings__payment-method {
    border-radius: 14px;
  }
  .inples__settings__payment-method:hover {
    background-color: #FFFFFF44;
  }
  .inples__btn, .inples__card {
    border-radius: 14px;
  }
  .clickable {
    cursor: pointer;
  }
  .avatar-hover {
    position: relative;
    display: inline-block;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #17177688;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  }

  .avatar-hover:hover .overlay {
    opacity: 1;
  }
  .inples__table__item::before {
    display: none;
    pointer-events: none;
  }
  >>> .v-navigation-drawer__append {
    min-width: 100%;
  }
  .v-navigation-drawer >>> .v-navigation-drawer__border {
    display: none !important;
  }
  .version {
    color: #979797;
    transform: rotate(270deg);
  }
</style>