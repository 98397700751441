import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    user: null,
    timestamp: null,
    lastTab: null,
    lastScroll: 0,
  },
  getters: {
  },
  mutations: {
    SET_TOKEN(state, { token: val }) {
      state.token = val
    },
    SET_USER(state, { user: val }) {
      state.user = val
    },
    SET_TIMESTAMP(state, { timestamp: val }) {
      state.timestamp = val
    },
    SET_LAST_TAB(state, { tab: val }) {
      state.lastTab = val
    },
    SET_LAST_SCROLL(state, { scroll: val }) {
      state.lastScroll = val
    }
  },
  actions: {
    setToken(state, { token: val }) {
      state.commit('SET_TOKEN', { token: val })
    },
  },
  modules: {
  }
})
