<template>
  <div class="full-height">
    <slot />
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'PersonalAreaMiddleware',
  computed: {
    ...mapState([
      'token'
    ])
  },
  mounted () {
    if (!this.token) {
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
<style scoped>
    .full-height {
        height: 100%;
    }
</style>